<div class="container">
  <article>
    <mat-accordion cdkDropList class="glass-style" (cdkDropListDropped)="onDropTable($event)">
      <mat-expansion-panel
        *ngFor="let evaluator of copy; let i = index"
        #panel
        cdkDrag
        [expanded]="i === 0 && autoOpenFirstItem"
        class="cond-accordion-panel ordenable-item-base visible-placeholder new-condition-panel"
        [disabled]="isConditionalDisplayEvaluator(evaluator) && evaluator.action?.doNotDisplay">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="drag-handle" cdkDragHandle>drag_handle</mat-icon>
            <span class="text-content">
              {{ evaluator.name }}
              <span *ngIf="evaluator.action?.bpmAction" class="cond-type-container">
                <mat-icon class="cond-type-icon">{{ BPMActionIcons[evaluator.action.bpmAction] }}</mat-icon>
                {{ translations[evaluator.action.bpmAction].value }}
              </span>
            </span>
          </mat-panel-title>

          <mat-panel-description>
            <mat-slide-toggle *ngIf="isConditionalDisplayEvaluator(evaluator)" color="primary"
              [checked]="evaluator.action.doNotDisplay" (click)="$event.stopPropagation()"
              (change)="handleDoNotDisplayChangeFor(evaluator, panel)">
              Não mostrar
            </mat-slide-toggle>

            <div *ngIf="showMultipleForm(evaluator)" class="multiple-form-button-container">
              <mat-slide-toggle (click)="$event.stopPropagation()" class="dense"
                [(ngModel)]="evaluator.action.isMultipleFormConfig" (change)="isMultipleFormChange($event, evaluator)">
              </mat-slide-toggle>
              <button mat-button color="primary" class="button-w-icon" (click)="editMultipleForm(evaluator, $event)">
                <mat-icon>create</mat-icon>&nbsp;
                Múltipla ocorrência
              </button>
            </div>
          </mat-panel-description>

          <button class="remove-button" mat-button color="warn" (click)="removeEvaluator(evaluator, i)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <section>
            <ng-container *ngIf="!evaluator.action?.doNotDisplay">
              <h4 class="condition-editor-title">Configurações</h4>
              <app-conditions-editor *ngIf="handlersHash[evaluator.name].conditionsEditor"
                [handler]="handlersHash[evaluator.name].conditionsEditor">
              </app-conditions-editor>
            </ng-container>
          </section>

          <ng-container *ngIf="evaluator.action?.bpmAction">
            <ng-container [ngSwitch]="evaluator.action.bpmAction">
              <ng-container *appSwitchCases="[
                ETypeBPMActionModel.executeFunction,
                ETypeBPMActionModel.GerFollowUpFromCustomer,
                ETypeBPMActionModel.Notification,
                ETypeBPMActionModel.CloseTicket,
                ETypeBPMActionModel.Assign,
              ]">
                <app-crm-bpm [item]="evaluator.action" [hideTypeSelector]="true"></app-crm-bpm>
              </ng-container>
              <ng-container *ngSwitchCase="EBPMMarketingActionType.messageWithCampaing">
                <div class="exec-action-title">
                  <span class="icon-container success">
                    <mat-icon size="18">check</mat-icon>
                  </span>
                  <h4>Dispara ação</h4>
                </div>
                <div class="exec-action-container">
                  <app-campaign-action-picker [(ngModel)]="evaluator.action.idCampaignAction">
                  </app-campaign-action-picker>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="EBPMMarketingActionType.goCampaignWithBot">
                <app-ns-picker *ngIf="handlersHash[evaluator.name].botPicker"
                  [handler]="handlersHash[evaluator.name].botPicker">
                </app-ns-picker>
              </ng-container>
              <section class="bpm-validation-container" *ngSwitchCase="bpmActionEnum.validator">
                <div *ngIf="!validatorHideSuccessMsg(evaluator)" class="message-container">
                  <div class="exec-action-title">
                    <span class="icon-container success">
                      <mat-icon size="18">check</mat-icon>
                    </span>
                  </div>
                  <asset-adder *ngIf="handlersHash[evaluator.name].validationOnSuccess"
                    [handler]="handlersHash[evaluator.name].validationOnSuccess">
                  </asset-adder>
                </div>

                <div class="message-container">
                  <div class="exec-action-title">
                    <span class="icon-container warn">
                      <mat-icon size="18">close</mat-icon>
                    </span>
                    <div *ngIf="!validatorErrorRecurrency(evaluator)" class="recurrency-container">
                      <button matRipple class="error-validation-recurrency-button"
                        [class.active]="hasErrorValidationRecurrencyAction(evaluator)"
                        (click)="createErrorRecurrencyConfig(evaluator)">
                        <ng-container *ngIf="hasErrorValidationRecurrencyAction(evaluator); else noRecurrencyConfig">
                          Ação após <span class="recurrency-count">{{ recurrencyCount(evaluator) }}</span> recorrência{{
                          recurrencyCount(evaluator) > 1 ? 's' : '' }}
                          <mat-icon class="edit-icon mat-14">create</mat-icon>

                        </ng-container>
                        <ng-template #noRecurrencyConfig>
                          Configurar ação após recorrência
                          <mat-icon class="add-icon mat-18">add</mat-icon>
                        </ng-template>
                      </button>
                      <button *ngIf="hasErrorValidationRecurrencyAction(evaluator)" mat-icon-button class="remove-button"
                        (click)="removeErrorRecurrencyConfig(evaluator)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                  <asset-adder *ngIf="handlersHash[evaluator.name].validationOnFailed"
                    [handler]="handlersHash[evaluator.name].validationOnFailed">
                  </asset-adder>
                </div>
              </section>

              <section *ngSwitchCase="bpmActionEnum.action">

                <div class="exec-action-title">
                  <span class="icon-container">
                    <mat-icon size="18">check</mat-icon>
                  </span>
                  <h4>Ação do bot</h4>
                </div>
                <div class="exec-action-container">
                  <cm-bot-action-editor *ngIf="handlersHash[evaluator.name].action"
                    [handler]="handlersHash[evaluator.name].action"></cm-bot-action-editor>
                </div>
              </section>

              <section *ngSwitchCase="bpmActionEnum.solicitation">
                <div class="exec-action-title">
                  <span class="icon-container">
                    <mat-icon size="18">check</mat-icon>
                  </span>
                  <h4>Destinatário</h4>
                </div>
                <div class="exec-action-container solicitation">
                  <div class="destination">
                    <app-enum-picker [handler]="handlersHash[evaluator.name].solicitation.destHandlerPicker"></app-enum-picker>
                    <mat-checkbox color="primary" [(ngModel)]="evaluator.action.agentOwner">Priorizar Agent Owner</mat-checkbox>
                  </div>
                  <ng-container [ngSwitch]="evaluator.action.destType">
                    <div *appSwitchCases="[ESolicitationDestination.AllFromGroup, ESolicitationDestination.AllAdminFromGroup]">
                      <app-participant-selector *ngIf="handlersHash[evaluator.name].solicitation.groupPicker" [participantSelectorHandler]="handlersHash[evaluator.name].solicitation.groupPicker"
                      hideWindowBar="true"></app-participant-selector>
                    </div>
                    <div *ngSwitchCase="ESolicitationDestination.IslandRandom">
                      <app-ns-picker *ngIf="handlersHash[evaluator.name].solicitation.islandPicker" [handler]="handlersHash[evaluator.name].solicitation.islandPicker"></app-ns-picker>
                    </div>
                    <div *ngSwitchCase="ESolicitationDestination.ToAvatar">
                      <app-participant-selector *ngIf="handlersHash[evaluator.name].solicitation.avatarPicker" [participantSelectorHandler]="handlersHash[evaluator.name].solicitation.avatarPicker"
                      hideWindowBar="true"></app-participant-selector>
                    </div>
                  </ng-container>
                  <app-non-serializable-taggable
                    *ngIf="tagPickerHandler"
                    [tagPickerHandler]="tagPickerHandler"
                  ></app-non-serializable-taggable>
                </div>
              </section>

              <section *ngSwitchCase="bpmActionEnum.content">
                <h4 class="l-title">Conteúdo <sup>*</sup></h4>
                <asset-adder *ngIf="handlersHash[evaluator.name].content"
                  [handler]="handlersHash[evaluator.name].content"></asset-adder>
              </section>

              <section *appSwitchCases="[
                bpmActionEnum.anomization,
                bpmActionEnum.getTitularData,
                bpmActionEnum.optOut,
                bpmActionEnum.optIn
              ]" class="bpm-lgpd-container">
                <app-enum-picker *ngIf="handlersHash[evaluator.name].lgpdActionStyle"
                  [handler]="handlersHash[evaluator.name].lgpdActionStyle" style="margin-bottom: 16px;"></app-enum-picker>
                <app-connection-route-picker *ngIf="showEvaluatorLGPDRoutePicker(evaluator)"
                  [handler]="handlersHash[evaluator.name].lgpdRoutePicker"></app-connection-route-picker>

                <mat-form-field appearance="fill">
                  <mat-label>Policy Name</mat-label>
                  <input matInput [(ngModel)]="evaluator.action.policyName" />
                </mat-form-field>

                <h4>
                  Conteúdo
                </h4>
                <asset-adder *ngIf="handlersHash[evaluator.name].content" [handler]="handlersHash[evaluator.name].content"
                  [schemaVariables]="[]"></asset-adder>
              </section>
            </ng-container>
          </ng-container>
        </ng-template>

      </mat-expansion-panel>
    </mat-accordion>
  </article>

  <div class="aligned-actions">
    <div [visible-horizontal]="hasMultipleConditionsOfSameType()" style="margin-right: auto;">
      <app-alert type="info" dense light>A primeira condição que for satisfeita irá executar esta ação</app-alert>
    </div>
    <button mat-stroked-button color="primary" class="button-w-icon" (click)="addEvaluator()">
      <mat-icon>add</mat-icon>Adicionar ação
    </button>
  </div>
</div>

<ng-template #insertingForm>
  <mat-form-field appearance="fill" style="display: block;">
    <mat-label>Nome da ação</mat-label>
    <input matInput [(ngModel)]="newEvaluator.name" name="evaluatorName">
  </mat-form-field>

  <mat-form-field appearance="fill" style="display: block;" *ngIf="handler.evaluatorAllowedTypes.length">
    <mat-label>Tipo de evaluator</mat-label>

    <mat-select [(ngModel)]="newEvaluator.action" name="bpmAction">
      <mat-select-trigger>
        <mat-icon class="add-cond-select-type-icon _trigger" style="position: relative;top: 4px;">{{
          BPMActionIcons[newEvaluator.action] }}</mat-icon> {{ translations[newEvaluator.action].value }}
      </mat-select-trigger>

      <mat-option *ngFor="let evtype of handler.evaluatorAllowedTypes" [value]="evtype">
        <mat-icon class="add-cond-select-type-icon">{{ BPMActionIcons[evtype] }}</mat-icon> {{
        translations[evtype]?.value || evtype }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-dialog-actions align="end">
    <button mat-button (click)="closeEvaluatorInserting()">
      Cancelar
    </button>

    <button mat-flat-button color="primary" (click)="confirmEvaluatorInserting()">
      Adicionar
    </button>
  </mat-dialog-actions>
</ng-template>

import { I360Media } from "@colmeia/core/src/core-constants/bot";
import { mbToBytes, Sizes } from "@colmeia/core/src/rules/mm-functions";
import { GenericSharedService } from "@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service";
import { getColmeiaTemplateStatusConfig } from "@colmeia/core/src/shared-business-rules/social-media/template.constants";
import { empty, fakeAssertsGuard, getClock, groupBy, isInvalidArray, isValidArray, isValidRef, swap } from "@colmeia/core/src/tools/utility";
import { EDelivery360Action } from "../../comm-interfaces/interaction-interfaces";
import { DeliveryProvider } from "../../core-constants/types";
import { asConst, define } from "../../tools/type-utils";
import { $getDeepPropertyValue, $ValueOf, inMemoryMatch, ListDeepProperties, useClientPredicates, ValueOf } from "../../tools/utility-types";
import { EMetadataNames } from "../metadata/metadata-db";
import { hasHeaderAndFooterOnWhatsAppProvider, isTemplateSentByStatus } from "./social-media.functions";
import { EAdvancedTemplateOption, EColmeiaWATemplateApprovalStatus, EProviderPresentationID, IMetaIWhatsAppSocialMediaConnectionServer, ISocialMediaConnectionServer, IWhatsAppSocialMediaConnectionServer, MAX_WHATSAPP_BUTTON_TITLE_SIZE, MAX_WHATSAPP_BUTTONS_QUICK_REPLY, TColmeiaWhatsAppTemplateApprovalStatus, TDeliveryProvidersWithWhatsApp, TWhatsAppSocialMediaConnectionServer, TwilioBaseConnectionDetails } from "./social-media.model";
import { ColmeiaTemplateComponents, IGenericNSTemplateServer, IGenericTemplateParameter, INSWhatsAppTemplateServer, ITemplateProvider, IWhatsAppTemplateApproval, IWhatsAppTemplateParameter } from "./template-model";
import { TGroupedSocialProvidersByIdProvider } from "./template-req-resp";
import { WhatsApp } from "./whatsapp-interface";
import { getCloneName } from '@colmeia/core/src/tools/utility/functions/getCloneName';
import { EBotActionType } from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";
import { WhatsAppValidators } from "@colmeia/core/src/shared-business-rules/social-media/whatsapp-validators";
import { EFeatureContext, providersConfigDB } from './social-media.constants';
import { IdSMConnection } from "../non-serializable-id/non-serializable-id-interfaces";


export function groupTemplateConnectionProviders(connections: ISocialMediaConnectionServer[]) {
    const grouped: TGroupedSocialProvidersByIdProvider = groupBy(connections, ns => {
        return getIdProviderByConnection(ns);
    });

    return grouped;
}

export function isValidChannelProvider(templateNS: IGenericNSTemplateServer, ns: ISocialMediaConnectionServer | undefined): boolean {
    if (!ns) return false;

    const template = templateNS.parameter;
    const { channelType } = template;

    if (isEmailTemplate(template)) return true;

    if (isAuthTemplate(template as IWhatsAppTemplateParameter)) {
        const providersWithAuthTemplate = new Set<DeliveryProvider>(providersConfigDB
            .filter(item => item.feature === EFeatureContext.Template && item.content.authTemplate)
            .map(item => item.deliveryProvider)
        );
        if (!providersWithAuthTemplate.has(ns.provider)) return false;
    }

    const matchSMType = useClientPredicates<ISocialMediaConnectionServer>()($ => ({ [$.socialMediaType]: channelType }));

    if (!inMemoryMatch(ns, matchSMType)) return false;
    if (!ns.detail.shouldAllowChannelForMarketing) return false;
    if (!isWhatsAppTemplate(template)) return true;
    if (!hasHeaderOrFooter(template)) return true;

    const isAllowedHeaderAndFooterOnWhatsAppProvider = hasHeaderAndFooterOnWhatsAppProvider(ns.provider);

    if (!isAllowedHeaderAndFooterOnWhatsAppProvider) {
        return false;
    }

    if (ns.provider === DeliveryProvider.twilio) {
        return template.header?.format !== WhatsApp.Message.Template.Structure.Format.Text;
    }


    return true;
}

function hasHeader(template: IWhatsAppTemplateParameter): boolean {
    return Boolean(template.header?.content);
}
function hasFooter(template: IWhatsAppTemplateParameter): boolean {
    return Boolean(template.footer?.content);
}

function hasHeaderOrFooter(template: IWhatsAppTemplateParameter): boolean {
    return Boolean(template.header?.content || template.footer?.content);
}

export function isWhatsAppTemplateDocumentFormat(format: WhatsApp.Message.Template.Structure.Format | undefined): boolean {
    return !isWhatsAppTemplateTextFormat(format);
}

export function isWhatsAppTemplateTextFormat(format: WhatsApp.Message.Template.Structure.Format | undefined): boolean {
    return format === WhatsApp.Message.Template.Structure.Format.Text;
}

export function isTemplateHeaderText(component: ColmeiaTemplateComponents.Header.Basic): component is ColmeiaTemplateComponents.Header.Text {
    return isWhatsAppTemplateTextFormat(component.format);
}

type IdProviderPropertiesOf<T extends TWhatsAppSocialMediaConnectionServer = TWhatsAppSocialMediaConnectionServer> = T extends unknown ? NonNullable<ListDeepProperties<Pick<T, 'idNS' | 'detail'>>> : never;

type IdProviderProperties = IdProviderPropertiesOf;

type MapProviderToProperty = {
    [key in DeliveryProvider]: IdProviderProperties
};

const defaultProviderProperty = defineProviderProperty('idNS');

const mapProviderToProperty = asConst<MapProviderToProperty>()({
    [DeliveryProvider.twilio]: 'detail.key',
    [DeliveryProvider.whatsApp]: 'detail.whatsAppBusinessAccountId',

    [DeliveryProvider.infobip]: defaultProviderProperty,
    [DeliveryProvider.sendGrid]: defaultProviderProperty,
    [DeliveryProvider.colmeia]: defaultProviderProperty,
    [DeliveryProvider.telegram]: defaultProviderProperty,
    [DeliveryProvider.facebook]: defaultProviderProperty,
    [DeliveryProvider.instagram]: defaultProviderProperty,
    [DeliveryProvider.sinch]: defaultProviderProperty,
    [DeliveryProvider.wavy]: defaultProviderProperty,
    [DeliveryProvider.smarters]: defaultProviderProperty,
});

type AllProviders = $ValueOf<typeof mapProviderToProperty>


export function getProviderProperty(provider: DeliveryProvider): AllProviders {
    const property = mapProviderToProperty[provider];
    return property;
}

export function getIdProviderByConnection(ns: ISocialMediaConnectionServer): string {
    const property = getProviderProperty(ns.provider);
    const id: string = $getDeepPropertyValue(ns, property);
    return id ?? ns.idNS;
}

export function isTemplatePendingReview(status: TColmeiaWhatsAppTemplateApprovalStatus | undefined) {
    return !isTemplateSentByStatus(status) || getColmeiaTemplateStatusConfig(status).isPendingUpdate;
}

export function isWhatsAppTemplateNS(template: IGenericNSTemplateServer | undefined): template is INSWhatsAppTemplateServer {
    return template?.parameter?.channelType === EDelivery360Action.Delivery360WhatsApp;
}

export function isWhatsAppConnection(connection: ISocialMediaConnectionServer | undefined): connection is IWhatsAppSocialMediaConnectionServer {
    return connection?.socialMediaType === EDelivery360Action.Delivery360WhatsApp;
}
export function isWhatsAppTemplate(template: IGenericTemplateParameter): template is IWhatsAppTemplateParameter {
    return template.channelType === EDelivery360Action.Delivery360WhatsApp;
}

export function isEmailTemplate(template: IGenericTemplateParameter): template is IWhatsAppTemplateParameter {
    return template.channelType === EDelivery360Action.Delivery360Email;
}

export function getValidButtonTexts(template: IGenericTemplateParameter): string[] {
    if (isWhatsAppTemplate(template)) {
        return (<IWhatsAppTemplateParameter>template)?.advancedOption?.quickReplyOptions.map(item => item.buttonText) ?? [];
    }
    return [];
}

export function isTheSameProvider(connection: ISocialMediaConnectionServer, provider: ITemplateProvider) {
    const idProvider: string = getIdProviderByConnection(connection);
    return provider.idProvider === idProvider;
}
export function filterTemplatePicker(connection: ISocialMediaConnectionServer, ns: IGenericNSTemplateServer): boolean {
    return ns.parameter.providers?.some(provider => isTheSameProvider(connection, provider));
}

function defineProviderProperty<Property extends IdProviderProperties>(property: Property) {
    return property;
}

const mapMediaToLimit = getMapTypeToSize([
    {
        "types": [
            "audio/aac",
            "audio/mp4",
            "audio/mpeg",
            "audio/amr",
            "audio/ogg",
            "audio/ogg"
        ],
        "size": 16
    },
    {
        "types": [
            "text/plain",
            "application/pdf",
            "application/vnd.ms-powerpoint",
            "application/msword",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ],
        "size": 100
    },
    {
        "types": [
            "image/jpeg",
            "image/png"
        ],
        "size": 5
    },
    {
        "types": [
            "video/mp4",
            "video/3gp"
        ],
        "size": 16
    },
    {
        "types": [
            "image/webp"
        ],
        "size": 0.1,
    },
]);

export function validateTemplateMedia(media: I360Media) {
    const limit = mapMediaToLimit.get(media.mymeType);

    if ((limit !== undefined)) {
        if ((media.size >= mbToBytes(limit))) {
            GenericSharedService.throw({
                message: `Limite de tamanho do arquivo atingido ${limit}mb`,
                isWarning: true,
            });
        }
        return;
    }

    GenericSharedService.throw({
        message: `Arquivo [tipo: "${media.mymeType}"] não é suportado`,
        isWarning: true,
    });
}

function getMapTypeToSize(items: ({ types: string[]; size: Sizes.MB })[]) {
    const map = new Map<string, number>();

    for (const item of items) {
        item.types.forEach(type => {
            if (!map.has(type)) map.set(type, item.size);
        })
    }

    return map;
}

function getSuffix() {
    return Math.random().toString().slice(2).slice(0, 3);
}

export function changeNameOfTemplate(ns: IGenericNSTemplateServer, suffix = getSuffix()) {
    ns.parameter.providers = [];
    ns.parameter.name = `${ns.parameter.name}${suffix}`;
}

export function cloneTemplate(ns: IGenericNSTemplateServer) {
    ns.idNS = undefined;
    const suffix = getSuffix();
    ns.nName = getCloneName(ns.nName);
    ns.clockTick = getClock();
    ns.lastTouch = getClock();
    changeNameOfTemplate(ns, suffix);
}


export function isTextOnlyTemplate(template: IWhatsAppTemplateParameter) {
    if (isValidArray(template.advancedOption?.quickReplyOptions)) {
        return false;
    }

    if (template.header?.format) {
        return false;
    }

    if (template.footer) {
        return false;
    }

    return true;
}


interface GetTemplateAsMessageInfo {
    type?: `${WhatsApp.Message.Type}`;
    errorMessage?: string;
}

const callToActionTypes = new Set([
    EBotActionType.quickCallPhone,
    EBotActionType.quickVisitWebsite,
])



const providersWithInteractiveCTA = new Set<DeliveryProvider | undefined>([
    DeliveryProvider.whatsApp,
]);


export function getTemplateAsMessageInfo(template: IWhatsAppTemplateParameter, provider?: DeliveryProvider): GetTemplateAsMessageInfo {
    if (isAuthTemplate(template)) {
        return getErrorMessage`Não suportado para template do tipo autenticação`;
    }
    
    if (!template.compiledTemplateWithProperties) {
        return getErrorMessage`O template precisa de texto`;
    }

    const isHeaderPresent = hasHeader(template);
    const isFooterPresent = hasFooter(template);


    
    const isTwilio = (provider === DeliveryProvider.twilio);

    if (isInvalidArray(template.advancedOption?.quickReplyOptions)) {
        const isMediaHeader = (template.header?.format !== WhatsApp.Message.Template.Structure.Format.Text);
        if (
            (isHeaderPresent && !isFooterPresent && isMediaHeader)
        ) {
            const isImageHeader = template.header?.format === WhatsApp.Message.Template.Structure.Format.Image;

            if (isTwilio && !isImageHeader) {
                return getErrorMessage`Não suportado para header que não seja imagem`;
            }
            // 
            const whatsAppType = template.header?.format && hashHeaderToMessageType[template.header?.format];
            // 

            return {
                type: whatsAppType,
            }
        }

        if (isHeaderPresent || isFooterPresent) {
            return getErrorMessage`O template precisa de botões para ser enviado como mensagem`;
        }

        return {
            type: WhatsApp.Message.Type.Text,
        }
    }

    if (
        template.advancedOption?.quickReplyOptions.some(button => callToActionTypes.has(button.action.type))
        // && (!providersWithInteractiveCTA.has(provider) || !isCTAURLTemplate(template))
    ) {
        return getErrorMessage`Não funcional para botões do tipo: Chamada para ação`
    }

    if (template.advancedOption?.quickReplyOptions && (template.advancedOption?.quickReplyOptions.length > MAX_WHATSAPP_BUTTONS_QUICK_REPLY)) {
        return getErrorMessage`Quantidade máxima de botões: 3`;
    }


    if (
        template.advancedOption?.quickReplyOptions.some(
            item => WhatsAppValidators.Menu.Container.countChars(item.buttonText) > MAX_WHATSAPP_BUTTON_TITLE_SIZE
        )
    ) {
        return getErrorMessage`Os botões não devem ultrapassar 20 caracteres`;
    }

    if (provider === DeliveryProvider.twilio) {
        if (isHeaderPresent) {
            if (template.header?.format === WhatsApp.Message.Template.Structure.Format.Text) {
                return getErrorMessage`Não funcional para templates que contém: cabeçalho do tipo texto`;
            }
        }

        if (isHeaderPresent && isFooterPresent) {
            return getErrorMessage`Não funcional para templates que contém: cabeçalho e botões ao mesmo tempo`;
        }

        if (isFooterPresent) {
            return getErrorMessage`Não funcional para templates que contém: rodapé`;
        }
    }

    return {
        type: WhatsApp.Message.Type.Interactive,
    }


    function getErrorMessage(commands: TemplateStringsArray, ...inputs: string[]): GetTemplateAsMessageInfo {
        const errorMessage = String.raw(commands, ...inputs);
        return {
            errorMessage,
        }
    }
}







export function isTemplateAllowingFreeForm(
    template: IWhatsAppTemplateParameter,
    provider?: DeliveryProvider,
): boolean {
    return !getTemplateAsMessageInfo(template, provider).errorMessage;
}


const hashMessageTypeToHeader: { [key in WhatsApp.Message.Type]?: WhatsApp.Message.Template.Structure.Format } = {
    [WhatsApp.Message.Type.Image]: WhatsApp.Message.Template.Structure.Format.Image,
    [WhatsApp.Message.Type.Video]: WhatsApp.Message.Template.Structure.Format.Video,
    [WhatsApp.Message.Type.Document]: WhatsApp.Message.Template.Structure.Format.Document,
    [WhatsApp.Message.Type.Text]: WhatsApp.Message.Template.Structure.Format.Text,
};

const hashHeaderToMessageType = swap(hashMessageTypeToHeader);

export function isAuthTemplate(template: IWhatsAppTemplateParameter): boolean {
    return template.category === WhatsApp.Template.CategoryV15.Authentication;
}

export function isProviderEvaluatedWhatsapp(template: IGenericNSTemplateServer, provider: IMetaIWhatsAppSocialMediaConnectionServer): boolean{
    let currentProvider = template.parameter.providers.find(providerTemplate => providerTemplate.idProvider === provider.detail.whatsAppBusinessAccountId) as IWhatsAppTemplateApproval
    let wasEvaluated = !!currentProvider?.colmeiaApprovalStatus && currentProvider?.colmeiaApprovalStatus !== EColmeiaWATemplateApprovalStatus.Notsent    
    return wasEvaluated
}


export function getURLButtonFromTemplate(template: IWhatsAppTemplateParameter) {
    const url = template.advancedOption?.quickReplyOptions.find(item => item.action.type === EBotActionType.quickVisitWebsite);
    return url;
}

export function isCTAURLTemplate(template: IWhatsAppTemplateParameter) {
    const url = getURLButtonFromTemplate(template);
    const isHeaderPresent = hasHeader(template);
    const isMediaHeader = (template.header?.format !== WhatsApp.Message.Template.Structure.Format.Text);

    if (isHeaderPresent && isMediaHeader) {
        return false;
    }

    const isURL = isValidRef(url) && template.advancedOption?.quickReplyOptions.length === 1;
    return isURL;
}

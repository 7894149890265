import { colmeiaServiceRequestType } from "@colmeia/core/src/comm-interfaces/colmeia-services-req-res";
import { ELookupType } from "../../../request-interfaces/lookup-request";
import { EApiRequestCorporateSearch, EAttendanceIslandRequest, EAttendantFollowUpRequestType, EAttendentRequest, EBIRequest, EBPMMarketingRequest, EBillingRequest, EBotTransaction, ECRMServiceConfigRequest, ECampaignRequest, ECampaignView, EColmeiaAPIToken, EColmeiaHelpRequestTypes, EConfigurationSetRequest, EContactListRequest, EDeployService, EFacebookChannelIntegrationReqRes, EFileRequest, EJobProcess, EKBOperations, EKnowledgeBaseRequest, EMacroRequest, EPowerBIDashboard, EProdDeply, ERTFParamsRequest, ERealTimeFilter, ESearchRequestTypes, ESecurityApiRequestType, ESecurityControlRequestType, ESharedServicesRequestType, ESocialNetworkMessages, ESupervisorRequest, ETagService, EUserFunctions, EUserGroupProfile, EWABARequest, EWAFRequest, EWAFSchemaRequest, EWarehouseDataExtractor, EYouTaskRequest, apiRequestType, ECopilotRequest, EHouseKeepingRequests, EJournalingRequestTypes } from "../../../request-interfaces/message-types";
import { EIdMenus } from "../../../shared-business-rules/colmeia-tags/id-menus";
import { ENonSerializableObjectType } from "../../../shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { getCRMSaveConfigItemRequestConfig, getEmptyMenu } from "../functions/getEmptyMenu";
import { defineBasicRequestTypeConfig } from "./request-type-config.functions";
import { EPostgresTransactionType } from "@colmeia/core/src/persistency/postgres-shared.types";
import { ECRMTicketRuntimeRequests } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-runtime-req-res";
import { ECRMTicketSupervisionRequests } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-supervision-req-res";


export const basicRequestTypeConfig = defineBasicRequestTypeConfig({

    [apiRequestType.dashboardExtra.ai.prompt]: {
        idMenu: EIdMenus.OmnisensePrompts,
        isLookup: true,
    },
    [apiRequestType.dashboardExtra.ai.savePrompt]: {
        idMenu: EIdMenus.OmnisensePrompts,
        postgresTransType: EPostgresTransactionType.readWrite
    },

    [apiRequestType.dashboardExtra.ai.previewChunks]: {
        idMenu: EIdMenus.OmnisenseVectors,
        isLookup: true,
    },
    [apiRequestType.dashboardExtra.ai.vectors]: {
        idMenu: EIdMenus.OmnisenseVectors,
        isLookup: true,
    },
    [apiRequestType.dashboardExtra.ai.vector]: {
        idMenu: EIdMenus.OmnisenseVectors,
        isLookup: true,
    },

    [apiRequestType.dashboardExtra.ai.saveVectors]: {
        idMenu: EIdMenus.OmnisenseVectors,
    },

    [apiRequestType.dashboardExtra.ai.editChunk]: {
        idMenu: EIdMenus.OmnisenseVectors,
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [apiRequestType.dashboardExtra.ai.persistChunks]: {
        idMenu: EIdMenus.OmnisenseVectors,
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [apiRequestType.dashboardExtra.ai.generativeChat]: {
        idMenu: EIdMenus.OmnisenseVectors,
    },



    [EJobProcess.getPayloads]: {
        idMenu: EIdMenus.JobsRequestPayload,
        isLookup: true,
    },
    [apiRequestType.dashboardExtra.houseKeeping.exec]: { idMenu: EIdMenus.ColmeiaHouseKeeping, isLookup: true },
    [EHouseKeepingRequests.getLogStructure]: { idMenu: '', isLookup: true },
    [EHouseKeepingRequests.getPersistedConfigs]: { idMenu: '', isLookup: true },
    [apiRequestType.dashboardExtra.env.backupSnapshot]: { idMenu: EIdMenus.EnvBackup, isLookup: false },
    [apiRequestType.dashboardExtra.env.backupPackage]: { idMenu: EIdMenus.EnvBackup, isLookup: false },
    [apiRequestType.dashboardExtra.env.backupSnapshotCreate]: { idMenu: EIdMenus.EnvBackup, isLookup: false },
    [apiRequestType.dashboardExtra.env.backupSnapshotRestore]: { idMenu: EIdMenus.EnvBackup, isLookup: false },
    [apiRequestType.dashboardExtra.env.backupListItems]: { idMenu: EIdMenus.EnvBackup, isLookup: false },

    [apiRequestType.nonSerializable.genericNSSave]: { idMenu: '', isLookup: true },
    [EAttendentRequest.callcenterActiveCalls]: { idMenu: '', isLookup: true },
    [ESecurityApiRequestType.getAvatarsThatParticipateInAGroup]: { idMenu: '', isLookup: true },
    [EBillingRequest.productModuleSave]: { idMenu: '', isLookup: true },
    [EBillingRequest.productSave]: { idMenu: '', isLookup: true },
    [EBillingRequest.productModuleList]: { idMenu: '', isLookup: true },
    [EBillingRequest.productList]: { idMenu: '', isLookup: true },
    [EBillingRequest.billingContractSave]: { idMenu: '', isLookup: true },
    [EBillingRequest.billingContractList]: { idMenu: '', isLookup: true },
    [EBillingRequest.productSKUSave]: { idMenu: '', isLookup: true },
    [EBillingRequest.productSKUList]: { idMenu: '', isLookup: true },
    [EBillingRequest.corporateGroupList]: { idMenu: '', isLookup: true },
    [EBillingRequest.billingCloseSave]: { idMenu: '', isLookup: true },
    [EBillingRequest.saveBillingItemAfterEdited]: { idMenu: '', isLookup: true },
    [EBillingRequest.billingCloseList]: { idMenu: '', isLookup: true },
    [EBillingRequest.billingItemsList]: { idMenu: '', isLookup: true },
    [EBillingRequest.googleCostsCheck]: { idMenu: '', isLookup: true },
    [EBillingRequest.googleCostsFetch]: { idMenu: '', isLookup: true },
    [apiRequestType.navigation.runNavigation]: { idMenu: '', isLookup: true },
    [apiRequestType.lookup]: { idMenu: '', isLookup: true },
    [apiRequestType.search]: { idMenu: '', isLookup: true },
    [ESearchRequestTypes.fullTextSearch]: { idMenu: '', isLookup: true },
    [ESecurityControlRequestType.checkCurrentMenuRule]: { idMenu: '', isLookup: true },
    [EAttendentRequest.getAvatarStatus]: { idMenu: '', isLookup: true },
    [EAttendentRequest.stats]: { idMenu: '', isLookup: true },
    [EAttendentRequest.getMessageSentForActiveCall]: { idMenu: '', isLookup: true },
    [EAttendentRequest.attMetrics]: { idMenu: '', isLookup: true },
    [EKnowledgeBaseRequest.processUtterances]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
    },
    [EPowerBIDashboard.getAnalyticsReports]: { idMenu: '', isLookup: true },
    [EPowerBIDashboard.upsertAnalyticsReportInfo]: { idMenu: '', isLookup: true },
    [EApiRequestCorporateSearch.save]: {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [apiRequestType.analytics.htmlReports.contactDetail]: { idMenu: '', isLookup: true },
    [apiRequestType.analytics.htmlReports.renewStorageLink]: { idMenu: '', isLookup: true },
    [apiRequestType.analytics.htmlReports.allCustomerContactHeader]: { idMenu: '', isLookup: true },
    [ELookupType.searchInFile]: { idMenu: '', isLookup: true },
    [ELookupType.testDocTransformer]: { idMenu: '', isLookup: true },
    // [EPowerBIDashboard.upsertReportInfo]: { idMenu: '', isLookup: true },
    // [EPowerBIDashboard.deleteReportInfo]: { idMenu: '', isLookup: true },
    [ELookupType.getBotConversation]: { idMenu: '', isLookup: true },
    [ELookupType.getAllAvatarFromSN]: { idMenu: '', isLookup: true },
    [ELookupType.getAllGroupFromAvatar]: { idMenu: '', isLookup: true },
    [ELookupType.getContainerGroups]: { idMenu: '', isLookup: true },
    [ELookupType.getUsedFeedbackAndInteraction]: { idMenu: '', isLookup: true },
    [ELookupType.nonSerializableGeneric]: { idMenu: '', isLookup: true },
    [EConfigurationSetRequest.executeSearch]: { idMenu: '', isLookup: true },
    [EConfigurationSetRequest.getAllowedDB]: { idMenu: '', isLookup: true },
    [ESearchRequestTypes.searchRemoteEnv]: { idMenu: '', isLookup: true },
    [apiRequestType.dashboardExtra.conversationalCommerce.uploadCatalogToProvider]: { idMenu: '' },
    [apiRequestType.dashboardExtra.conversationalCommerce.getMetaEntity]: { idMenu: '' },
    [apiRequestType.dashboardExtra.conversationalCommerce.listSubmittedProducts]: { idMenu: '' },
    [apiRequestType.dashboardExtra.conversationalCommerce.upsertCatalogOnWaba]: { idMenu: '' },
    [apiRequestType.dashboardExtra.conversationalCommerce.listCatalogsOnWaba]: { idMenu: '' },
    [apiRequestType.dashboardExtra.conversationalCommerce.sendCatalog]: { idMenu: '' },
    [apiRequestType.dashboardExtra.conversationalCommerce.previewMetaCatalog]: { idMenu: '' },
    [ECampaignRequest.countMatches]: { idMenu: '' },
    [ECampaignRequest.listCampaignsByUser]: { idMenu: '' },
    [apiRequestType.dashboardExtra.conversationalCommerce.saveCatalog]: { idMenu: '' },
    [apiRequestType.dashboardExtra.conversationalCommerce.saveForm]: {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite,
    },
    [ESupervisorRequest.changeAgentStatus]: { idMenu: '' },
    [ESupervisorRequest.transferCustomerIsland]: { idMenu: '' },
    [ESupervisorRequest.transferCustomerToQueue]: { idMenu: '' },
    [ESupervisorRequest.sendMessagesToAttendants]: { idMenu: '' },

    [apiRequestType.dashboardExtra.marketing.getSocialMediaConnection]: { idMenu: '' },
    [apiRequestType.dashboardExtra.marketing.updateTemplateProviderApproval]: { idMenu: '' },
    [apiRequestType.dashboardExtra.crm.crmExecList]: { idMenu: '' },
    [apiRequestType.dashboardExtra.crm.crmExecListAgent]: { idMenu: '' },

    [apiRequestType.dashboardExtra.crm.crmExecListRoot]: { idMenu: '' },
    [apiRequestType.dashboardExtra.crm.getCRMStatusFromRootDefinition]: { idMenu: '' },
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemUrgencyLevel].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemUrgencyLevel].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.marketing.updateTemplateProviderApprovals]: { idMenu: '' },
    [apiRequestType.dashboardExtra.marketing.getTemplateProviders]: { idMenu: '' },
    [apiRequestType.dashboardExtra.ai.getExternalPrintableDependenciesOf]: { idMenu: '' },
    [apiRequestType.dashboardExtra.ai.isBPMNestedAIRootAlreadyExist]: { idMenu: '' },
    [apiRequestType.dashboardExtra.ai.isNLPFromBPM]: { idMenu: '' },
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemSupportLevel].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemSupportLevel].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderSupportLevel].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderSupportLevel].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.conversationalCommerce.integration]: { idMenu: '' },
    [apiRequestType.dashboardExtra.conversationalCommerce.form]: { idMenu: '' },
    [apiRequestType.dashboardExtra.socialNetwork.saveUserSettings]: { idMenu: '' },
    [EBPMMarketingRequest.mktBpmSave]: { idMenu: '' },
    [ECRMServiceConfigRequest.crmServiceRootSave]: { idMenu: '' },
    [ECRMServiceConfigRequest.crmUserEventSave]: { idMenu: '' },
    [ECRMServiceConfigRequest.crmProcessingNodeSave]: { idMenu: '' },
    [ECRMServiceConfigRequest.crmNotificationSave]: { idMenu: '' },
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderSupportLevel].list]: getEmptyMenu(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemSupportLevel].list]: getEmptyMenu(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel].list]: getEmptyMenu(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemUrgencyLevel].list]: getEmptyMenu(),
    [apiRequestType.dashboardExtra.attendance.quickDrawAllocation]: { idMenu: '' },
    [apiRequestType.dashboardExtra.attendance.editActiveCallContact]: { idMenu: '' },
    [apiRequestType.dashboardExtra.attendance.activeCallScheduleSelectedContacts]: { idMenu: '' },
    [apiRequestType.dashboardExtra.attendance.sendActionToMultipleContacts]: { idMenu: '' },
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderCloseState].list]: getEmptyMenu(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemCloseState].list]: getEmptyMenu(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderPhase].list]: getEmptyMenu(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemPhase].list]: getEmptyMenu(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderSeverity].list]: getEmptyMenu(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemSeverity].list]: getEmptyMenu(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderState].list]: getEmptyMenu(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemState].list]: getEmptyMenu(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderCloseState].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderCloseState].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemCloseState].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemCloseState].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderPhase].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderPhase].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemPhase].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemPhase].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderSeverity].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderSeverity].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemSeverity].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemSeverity].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderState].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmHeaderState].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemState].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmItemState].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmConfigPackage].save]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmConfigPackage].delete]: getCRMSaveConfigItemRequestConfig(),
    [apiRequestType.dashboardExtra.crm.config[ENonSerializableObjectType.crmConfigPackage].list]: getEmptyMenu(),

    [apiRequestType.dashboardExtra.socialNetwork.listConfig]: {
        idMenu: '',
    },
    [apiRequestType.dashboardExtra.socialNetwork.config]: {
        idMenu: '',
        logFeature: {
            featureGroup: 'Rede Social - Configurações',
            friendlyName: 'Rede Social - Configurações da rede social',
        }
    },


    [apiRequestType.dashboardExtra.socialNetwork.saveConfig]: {
        idMenu: '',
        logFeature: {
            featureGroup: 'Rede Social - Configurações',
            friendlyName: 'Rede Social - Salvar configurações da rede social'
        },
    },
    [apiRequestType.dashboardExtra.socialNetwork.listGroupsAccess]: {
        idMenu: '',
    },


    [apiRequestType.dashboardExtra.attendance.saveEvent]: {
        idMenu: EIdMenus.ProviderAttendanceevents,
        logFeature: {
            featureGroup: 'Customer care - Eventos de atendimento',
            friendlyName: 'Salvar eventos de atendimento'
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [apiRequestType.dashboardExtra.attendance.events]: {
        idMenu: EIdMenus.ProviderAttendanceevents,
        logFeature: {
            featureGroup: 'Customer care - Eventos de atendimento',
            friendlyName: 'Eventos de atendimento'
        },
    },
    [apiRequestType.dashboardExtra.socialNetwork.users]: {
        idMenu: EIdMenus.SocialNetworkUsers,
        logFeature: {
            featureGroup: 'Rede Social - Usuários',
            friendlyName: 'Rede Social - Usuários'
        },
    },
    [apiRequestType.dashboardExtra.socialNetwork.form]: {
        idMenu: EIdMenus.SocialNetworkForm,
        logFeature: {
            featureGroup: 'Rede Social - Metadados',
            friendlyName: 'Rede Social - Metadados'
        },
    },
    [apiRequestType.dashboardExtra.socialNetwork.saveForm]: {
        idMenu: EIdMenus.SocialNetworkForm,
        logFeature: {
            featureGroup: 'Rede Social - Metadados',
            friendlyName: 'Rede Social - Salvar metadados'
        },
        postgresTransType: EPostgresTransactionType.readWrite,
    },
    [apiRequestType.dashboardExtra.marketing.template]: {
        idMenu: EIdMenus.MarketingTemplates,
        //
        logFeature: {
            featureGroup: 'Campaing - Template',
            friendlyName: 'Templates'
        },
    },

    [apiRequestType.dashboardExtra.colmeia.downloadFile]: {
        idMenu: EIdMenus.ColmeiaTools,
    },

    [apiRequestType.dashboardExtra.colmeia.broker]: {
        idMenu: EIdMenus.ColmeiaBroker,
    },

    [apiRequestType.dashboardExtra.colmeia.deleteCoordinator]: {
        idMenu: EIdMenus.ColmeiaCoordinatorDeletion,
    },
    [apiRequestType.dashboardExtra.colmeia.getCoordinatorInfo]: {
        idMenu: EIdMenus.ColmeiaCoordinatorDeletion,
    },

    [apiRequestType.dashboardExtra.colmeia.resetServers]: {
        idMenu: EIdMenus.ColmeiaCoordinatorDeletion,
    },

    [apiRequestType.dashboardExtra.marketing.templates]: {
        idMenu: EIdMenus.MarketingTemplates,
        logFeature: {
            featureGroup: 'Campaing - Templates de canais',
            friendlyName: 'Campaing - Templates de canal'
        },
    },
    [apiRequestType.dashboardExtra.marketing.saveTemplate]: {
        idMenu: EIdMenus.MarketingTemplates,
        logFeature: {
            featureGroup: 'Campaing - Template',
            friendlyName: 'Campaing - Salvar template de canais'
        },
    },

    [apiRequestType.dashboardExtra.marketing.wabaQuality]: {
        idMenu: EIdMenus.MarketingWabaQuality,
        logFeature: {
            featureGroup: 'Campaing - waba quality',
            friendlyName: 'Campaing - Get waba quality'
        },
    },

    [apiRequestType.dashboardExtra.marketing.wabaTemplateQuality]: {
        idMenu: EIdMenus.MarketingWabaQuality,
        logFeature: {
            featureGroup: 'Campaing - waba quality',
            friendlyName: 'Campaing - waba template quality'
        },
    },

    [apiRequestType.dashboardExtra.marketing.updateTemplateUnpause]: {
        idMenu: EIdMenus.MarketingWabaQuality,
        logFeature: {
            featureGroup: 'Campaing - waba quality',
            friendlyName: 'Campaing - update template unpause'
        },
    },

    [apiRequestType.dashboardExtra.marketing.getScheduledCampaignByTemplateIdNS]: {
        idMenu: EIdMenus.MarketingWabaQuality,
        logFeature: {
            featureGroup: 'Campaing - waba quality',
            friendlyName: 'Campaing - get Scheduled Campaign By TemplateIdNS'
        },
    },


    [apiRequestType.dashboardExtra.marketing.cancelScheduledCampaignByTemplateIdNS]: {
        idMenu: EIdMenus.MarketingWabaQuality,
        logFeature: {
            featureGroup: 'Campaing - waba quality',
            friendlyName: 'Campaing - cancel Scheduled Campaign By runCampaignId'
        },
    },

    [apiRequestType.dashboardExtra.marketing.wabaNotificationsQuality]: {
        idMenu: EIdMenus.MarketingWabaQuality,
        logFeature: {
            featureGroup: 'Campaing - waba quality',
            friendlyName: 'Campaing - waba Notifications Quality'
        },
        isLookup: true
    },


    [ECampaignRequest.getTelegramInfo]: {
        idMenu: EIdMenus.CommunicationChannels,
    },

    [ECampaignRequest.saveSocialMedia]: {
        idMenu: EIdMenus.CommunicationChannels,
        logFeature: {
            featureGroup: 'Canais e Campaing - Canais e Ação de campanha',
            friendlyName: 'Alterar e criar canal'
        },
    },
    [ECampaignRequest.saveCampaing]: {
        idMenu: EIdMenus.MarketingCampaigns,
        logFeature: {
            featureGroup: 'Campaing - Ação de campanha',
            friendlyName: 'Campaing - Criar e atualizar ação de campanha'
        },
    },
    [ECampaignRequest.listCampaigns]: {
        idMenu: EIdMenus.MarketingSendCampaign,
    },
    [ECampaignRequest.listSingleByAction]: {
        idMenu: '',
        logFeature: {
            featureGroup: 'Campaing - Enviar Campanha',
            friendlyName: 'Campaing - Retorna ação de campanha do disparo'
        },
    },
    [ECampaignRequest.runCampaign]: {
        idMenu: EIdMenus.MarketingSendCampaign,
        logFeature: {
            featureGroup: 'Campaing - Enviar Campanha',
            friendlyName: 'Campaing - Disparar ação de campanha'
        },
    },
    [ECampaignRequest.removeScheduledCampaign]: {
        idMenu: EIdMenus.MarketingSendCampaign,
        logFeature: {
            featureGroup: 'Campaign - Remover disparos agendados',
            friendlyName: 'Campaign - Remover disparos agendados'
        },
    },
    [ECampaignRequest.rescheduleCampaign]: {
        idMenu: EIdMenus.MarketingSendCampaign,
        logFeature: {
            featureGroup: 'Campaign - Reagendar disparos',
            friendlyName: 'Campaign - Reagendar disparos'
        },
    },
    [ECampaignRequest.runCampaignForActiveCall]: {
        idMenu: '',
        logFeature: {
            featureGroup: 'Customer care - Chamadas ativas',
            friendlyName: 'Customer care - disparar campanha de chamada ativa'
        },
    },
    [ECampaignRequest.saveRunCampaignForActiveCall]: {
        idMenu: '',
        logFeature: {
            featureGroup: '',
            friendlyName: '',
        },
    },
    [apiRequestType.canonical.getGlobalCanonical]: {
        idMenu: '',
        logFeature: {
            featureGroup: 'Campaing - Ação de campanha',
            friendlyName: 'Retorna signficado global'
        },
    },
    [apiRequestType.analytics.extraConfigMetadata]: {
        idMenu: '',
        logFeature: {
            featureGroup: 'Dashboards',
            friendlyName: 'Informações adicionais do dashboard'
        },
    },
    [EPowerBIDashboard.getWorkspaceInfo]: {
        idMenu: '',
        logFeature: {
            featureGroup: 'Dashboards',
            friendlyName: 'Lista informações dos dashboards'
        },
    },
    [EPowerBIDashboard.setWorkspaceInfo]: {
        idMenu: '',
    },
    [EPowerBIDashboard.getConfigInfo]: {
        idMenu: '',
    },
    [EPowerBIDashboard.setConfigInfo]: {
        idMenu: '',
    },

    [EJobProcess.getAll]: {
        idMenu: EIdMenus.JobsEnvironmentDataMigration,
        logFeature: {
            featureGroup: 'Jobs',
            friendlyName: 'Jobs - Lista todos os jobs'
        },
    },
    [EJobProcess.rescheduleJob]: {
        idMenu: '',
        logFeature: {
            featureGroup: 'Jobs',
            friendlyName: 'Jobs - Reagendar jobs'
        },
    },
    [EJobProcess.delete]: {
        idMenu: '',
        logFeature: {
            featureGroup: 'Jobs',
            friendlyName: 'Jobs - Deletar job'
        },
    },
    [apiRequestType.userFunctions.createOrEdit]: {
        idMenu: '',
        logFeature: {
            featureGroup: 'Smartflow - Funções do usuário',
            friendlyName: 'Criar função do usuário',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [apiRequestType.userFunctions.compile]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Compilar função do usuário',
            featureGroup: 'Smartflow - Funções do usuário',
        }
    },
    [apiRequestType.userFunctions.run]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Executar função do usuário',
            featureGroup: 'Smartflow - Funções do usuário',
        }
    },
    [apiRequestType.userFunctions.getAll]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Lista de funções do usuário',
            featureGroup: 'Smartflow - Funções do usuário',
        },
    },
    [EUserFunctions.runRawRequest]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Executar teste da rota de conexão',
            featureGroup: 'Comunicações - Rota de conexão',
        },
    },

    [ERTFParamsRequest.saveBody]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Criar corpo(configurações) de RTF Parametrizável',
            featureGroup: 'SmartFlow - RTF Parametrizável',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [ERTFParamsRequest.saveHeader]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Criar cabeçalho de RTF Parametrizável',
            featureGroup: 'SmartFlow - RTF Parametrizável',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [ERTFParamsRequest.getAllHeader]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Listar cabeçalho de RTF parametrizável',
            featureGroup: 'SmartFlow - RTF Parametrizável',
        },
    },
    [ERTFParamsRequest.getAllBody]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Listar corpo(configurações) de RTF parametrizável',
            featureGroup: 'SmartFlow - RTF Parametrizável',
        },
    },

    [EConfigurationSetRequest.saveConfigSet]: {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [EConfigurationSetRequest.deleteConfigSet]: {
        idMenu: '',
    },

    [EBIRequest.getEmbbededUrl]: {
        idMenu: '',
        logFeature: {
            featureGroup: 'Dashboards',
            friendlyName: 'Informações adicionais do dashboard'
        },
    },
    // [EWidgetRequest.autoLogin]: {
    //     idMenu: '',
    // },
    [EFacebookChannelIntegrationReqRes.getCustomWebhookStatus]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Pegar status do Webhook da WABA',
            featureGroup: 'Gerenciador de WABA - Configurações',
        },
    },
    [EFacebookChannelIntegrationReqRes.registerWebhookURL]: {
        idMenu: '',
    },
    [EFacebookChannelIntegrationReqRes.unregisterWebhookURL]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Desvincular Webhook Customizado da WABA',
            featureGroup: 'Gerenciador de WABA - Configurações',
        },
    },
    [EFacebookChannelIntegrationReqRes.updateFacebookPageList]: {
        idMenu: '',
    },
    [EFacebookChannelIntegrationReqRes.getFacebookPageList]: {
        idMenu: '',
    },
    [EFacebookChannelIntegrationReqRes.subscribeAppToPage]: {
        idMenu: '',
    },
    [EFacebookChannelIntegrationReqRes.getColmeiaAppId]: {
        idMenu: '',
    },
    [EFacebookChannelIntegrationReqRes.getColmeiaAppLoginConfiguration]: {
        idMenu: '',
    },
    [EFacebookChannelIntegrationReqRes.exchangeCodeForTokenAndGetWhatsappInfo]: {
        idMenu: '',
    },
    [EFacebookChannelIntegrationReqRes.registerWebhookAndPhoneNumber]: {
        idMenu: '',
    },
    [EFacebookChannelIntegrationReqRes.migrateWhatsappChannelPhoneNumberFromAnotherBSPToColmeia]: {
        idMenu: '',
    },
    [EFacebookChannelIntegrationReqRes.verifyPhoneOwnershipAndRegister]: {
        idMenu: '',
    },
    [EFacebookChannelIntegrationReqRes.registerPhoneNumber]: {
        idMenu: '',
    },
    [EFacebookChannelIntegrationReqRes.checkIfPhoneIsRegistered]: {
        idMenu: '',
    },
    [EFacebookChannelIntegrationReqRes.getAllPhoneNumbersFromWABAID]: {
        idMenu: '',
    },
    [EUserGroupProfile.editAvatarUserGroupProfile]: {
        idMenu: '',
    },
    [EUserGroupProfile.editUserGroupProfile]: {
        idMenu: '',
    },
    [EUserGroupProfile.getOneGroupProfile]: {
        idMenu: ''
    },
    [EFacebookChannelIntegrationReqRes.disconnectMetaPage]: {
        idMenu: '',
    },
    [EColmeiaHelpRequestTypes.getAll]: {
        idMenu: '',
    },
    [EColmeiaHelpRequestTypes.upsertOne]: {
        idMenu: '',
    },
    [EWarehouseDataExtractor.processDataExtractor]: {
        idMenu: '',
    },
    [EAttendantFollowUpRequestType.joinAttendence]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Iniciar supervisão de atendimento',
            featureGroup: 'Customer care - Acompanhamento de atendimento',
        },
    },
    [EAttendantFollowUpRequestType.getAllAttendenceFromAgent]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Lista atendimentos atuais do atendente',
            featureGroup: 'Customer care - Acompanhamento de atendimento',
        },
    },
    [EAttendantFollowUpRequestType.getCaseFromCustomerBeingAttendent]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Lista atendimentos em aberto do cliente',
            featureGroup: 'Customer care - Acompanhamento de atendimento',
        },
    },

    [ESecurityControlRequestType.save]: {
        idMenu: EIdMenus.SocialNetworkAccess,

        logFeature: {
            friendlyName: 'Rede Social - Salvar regras de acesso',
            featureGroup: 'Rede Social - Regras de acesso',
        },
    },

    [apiRequestType.nonSerializable.getGeneric]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Retorna não serializáveis',
            featureGroup: 'nonSerializable',
        },
    },
    [apiRequestType.nonSerializable.getGenericEnv]: {
        idMenu: '',
        isLookup: true,
        logFeature: {
            friendlyName: 'Retorna não serializáveis em outros ambientes',
            featureGroup: 'nonSerializable',
        },
    },
    [apiRequestType.serializable.getListOnRemoteEnv]: {
        idMenu: '',
        isLookup: true,
        logFeature: {
            friendlyName: 'Retorna não serializable em outros ambientes',
            featureGroup: 'Serializable',
        },
    },
    [apiRequestType.nonSerializable.getUsedTags]: {
        idMenu: '',
    },
    [apiRequestType.nonSerializable.checkDependencies]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'checa dependências',
            featureGroup: 'nonSerializable',
        },
    },
    [apiRequestType.nonSerializable.deleteAndClearReferences]: {
        idMenu: '',
    },
    [apiRequestType.nonSerializable.delete]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'deleção de não serializável',
            featureGroup: 'nonSerializable',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [apiRequestType.nonSerializable.deleteBatch]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'deleção de não serializável em lote',
            featureGroup: 'nonSerializable',
        },
    },
    [apiRequestType.nonSerializable.checkDelete]: {
        idMenu: '',
    },
    [EKnowledgeBaseRequest.getPredictionFromTextToKb]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'lista intenções e seu score de identificação com frase digitada',
            featureGroup: 'Omnisense - IA encadeada',
        },
    },

    [EMacroRequest.saveMacro]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Criar e atualizar macro',
            featureGroup: 'Customer care - Recursos de atendente',
        },
        postgresTransType: EPostgresTransactionType.readWrite,
    },
    [EMacroRequest.listMacros]: {
        idMenu: '',

        logFeature: {
            friendlyName: '',
            featureGroup: '',
        },
    },
    [EMacroRequest.getMacros]: {
        idMenu: '',

        logFeature: {
            friendlyName: '',
            featureGroup: '',
        },
    },
    [EAttendanceIslandRequest.saveAttendants]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Alteração de ilhas atribuídas ao atendente',
            featureGroup: 'Customer care - Gereciamento de agentes',
        },
    },
    [EAttendentRequest.changeStatus]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Alteração de status do atendente',
            featureGroup: 'Customer care - Funções de atendente/Status',
        },
    },
    [EAttendanceIslandRequest.transferIsland]: {
        idMenu: '',
    },
    [EAttendentRequest.returnToQueue]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Devolver casos para a fila',
            featureGroup: 'Customer care - Funções de atendente/Status',
        },
    },
    [EAttendentRequest.finishAllAttendance]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Finalizar todos os atendimentos',
            featureGroup: 'Customer care - Funções de atendente/Status',
        },
    },
    [EAttendanceIslandRequest.getAttendants]: {
        idMenu: '',
    },
    [EAttendanceIslandRequest.saveNamedAccount]: {
        idMenu: '',
    },

    [EFileRequest.saveSchemma]: {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [EFileRequest.retrieveSchemma]: {
        idMenu: '',
    },
    [EFileRequest.processFile]: {
        idMenu: '',
        isLookup: true,

        logFeature: {
            friendlyName: 'Atualizar ou criar banco de dados',
            featureGroup: 'Banco de dados',
        },
    },
    [EFileRequest.deleteFile]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Deletar banco de dados',
            featureGroup: 'Banco de dados',
        },
    },
    [EFileRequest.saveGeneralAnswer]: {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite,
    },
    [EFileRequest.getAnswerForSerializable]: {
        idMenu: '',
    },
    [EFileRequest.getAnswerForConversation]: {
        idMenu: '',
    },
    [EFileRequest.getFilesMetadata]: {
        idMenu: '',
    },
    [EFileRequest.getSpecificSchemma]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Retorna metadado especificado',
            featureGroup: 'Smartflow - Gerador de conteúdo, Banco de dados, Ação de campanha',
        },
    },
    [EWABARequest.saveWABA]: {
        idMenu: '',
    },
    [EWABARequest.getCreditLineStatus]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Pegar status da linha de crédito',
            featureGroup: 'Gerenciador de WABA - Configurações',
        },
    },
    [EWABARequest.extendCreditLine]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Vincular linha de crédito',
            featureGroup: 'Gerenciador de WABA - Configurações',
        },
    },
    [EWABARequest.revokeCreditLine]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Revogar linha de crédito',
            featureGroup: 'Gerenciador de WABA - Configurações',
        },
    },
    [EFileRequest.checkIfFileIsBeingUsedInSomeDatabase]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Verifica se csv selecionado já está em uso em algum banco de dados',
            featureGroup: 'Banco de dados',
        },
    },
    [EFileRequest.downloadFileContent]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Baixar dados do banco de dados em csv',
            featureGroup: 'Banco de dados',
        },
    },

    [EFileRequest.deleteFileContent]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Deletar linha do banco de dados',
            featureGroup: 'Banco de dados',
        },
    },

    [EAttendantFollowUpRequestType.revokeSupervisor]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Encerrar supervisão',
            featureGroup: 'Customer care - Acompanhamento de atendimento',
        },
    },

    [EKBOperations.querySubscriptionResources]: {
        idMenu: '',
    },
    [EKBOperations.upsertPhraseList]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'atualizar lista de sinônimos',
            featureGroup: 'Omnisense - Knowledgebases',
        },
    },
    [EKBOperations.deletePhraseList]: {
        idMenu: '',
    },
    [EKBOperations.recommendationPhraseList]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'lista recomendações de sinônimos',
            featureGroup: 'Omnisense - Knowledgebases',
        },
    },
    [EKBOperations.upsertEntity]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'criar e atualizar entidades',
            featureGroup: 'Omnisense - Knowledgebases extraction',
        },
    },
    [EKBOperations.deleteEntity]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'deletar entidades',
            featureGroup: 'Omnisense - Knowledgebases extraction',
        },
    },
    [EKBOperations.upsertPattern]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'criação e atualização de entidade',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },
    [EKBOperations.deletePattern]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'deleção de entidade',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },
    [EKBOperations.updateEntityFeatures]: {
        idMenu: '',
    },

    [apiRequestType.dashboardExtra.indexedSearch.list]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Lista buscas corporativas',
            featureGroup: 'Smartflow - Busca corporativa',
        },
    },

    [EKnowledgeBaseRequest.saveKB]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        postgresTransType: EPostgresTransactionType.readWrite,
        logFeature: {
            friendlyName: 'Omnisense - criar base de conhecimento',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },

    [EKnowledgeBaseRequest.editKBCorporateEntity]: {
        idMenu: EIdMenus.OmnisenseKBCorporateEntity,

        logFeature: {
            friendlyName: 'Omnisense - editar entidade corporativa',
            featureGroup: 'Omnisense - knowledgebase',
        },
    },
    [EKnowledgeBaseRequest.listKBCorporateEntity]: {
        idMenu: EIdMenus.OmnisenseKBCorporateEntity,

        logFeature: {
            friendlyName: 'Omnisense - listar entidades corporativas',
            featureGroup: 'Omnisense - knowledgebase',
        },
    },

    [EKnowledgeBaseRequest.editKBName]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,

        logFeature: {
            friendlyName: 'editar nome da base de conhecimento',
            featureGroup: 'Omnisense - knowledgebase',
        },
    },
    [EKBOperations.deleteIntent]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,

        logFeature: {
            friendlyName: 'Omnisense - deletar intenção',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },
    [EKnowledgeBaseRequest.listKB]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,

        logFeature: {
            friendlyName: 'Retorna lista de bases de conhecimento',
            featureGroup: 'Smartflow - Gerador de conteúdo NLP',
        },
    },
    [EKnowledgeBaseRequest.listKbActivity]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'interações do cliente com a inteligencia artificial',
            featureGroup: 'Omnisense - retro alimentação de machine learning',
        },
    },
    [EKnowledgeBaseRequest.getNLPConfigAndIntents]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        logFeature: {
            friendlyName: 'lista intenção e texto semântico da intenção selecionada',
            featureGroup: 'Omnisense - retro alimentação de machine learning',
        },
    },
    [EKnowledgeBaseRequest.getInfo]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Omnisense - listar detalhes de base de conhecimento',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },
    [EKnowledgeBaseRequest.getCompleteKBTextSyntax]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'análise sintática completa do input',
            featureGroup: 'Omnisense - IA encadeada',
        },
    },
    [EKnowledgeBaseRequest.getSentenceTestAnalysis]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'testar base de conhecimento com input de teste',
            featureGroup: 'Omnisense - Knowledgebases',
        },
    },


    [apiRequestType.dashboardExtra.attendance.sendOpenChannelTemplate]: {
        idMenu: '',

        logFeature: {
            friendlyName: '',
            featureGroup: '',
        },
    },
    [apiRequestType.dashboardExtra.attendance.activeCallSendSelectedContacts]: {
        idMenu: '',

        logFeature: {
            friendlyName: '',
            featureGroup: '',
        },
    },
    [apiRequestType.dashboardExtra.attendance.createActiveCallContact]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Customer care - criar novo contato para chamada ativa',
            featureGroup: 'Customer care - Chamadas ativas',
        },
    },
    [apiRequestType.dashboardExtra.attendance.activeCallBatch]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Chamadas ativas',
            featureGroup: 'Customer care - Chamadas ativas',
        },
    },
    [apiRequestType.dashboardExtra.attendance.saveContactActiveCall]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Customer care - Criar contato para chamada ativa ',
            featureGroup: 'Customer care - Chamadas ativas',
        },
    },
    [apiRequestType.dashboardExtra.attendance.saveBatchActiveCall]: {
        idMenu: '',
    },
    [apiRequestType.dashboardExtra.attendance.getActiveCustomerStatus]: {
        idMenu: '',
    },
    [apiRequestType.dashboardExtra.attendance.getChannelOpenTime]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Customer care - tempo restante de canal aberto com cliente',
            featureGroup: 'Customer care - Chamadas ativas',
        },
    },
    [apiRequestType.dashboardExtra.attendance.activeCallSearchCustomer]: {
        idMenu: EIdMenus.ConversationHistory,

        logFeature: {
            friendlyName: 'Busca de contatos no histórico de conversação',
            featureGroup: 'Customer care - Histórico de conversação',
        },
    },
    [apiRequestType.dashboardExtra.attendance.getActiveCompactibleCampaignActions]: {
        idMenu: '',

        logFeature: {
            friendlyName: 'Customer care - retorna ações de campanha de agentes',
            featureGroup: 'Customer care - Chamadas ativas',
        },

    },
    [apiRequestType.dashboardExtra.attendance.saveForm]: {
        idMenu: EIdMenus.AttendanceForm,
        logFeature: {
            friendlyName: 'Customer care - Salvar Metadados',
            featureGroup: 'Customer care - Metadados',
        },
        postgresTransType: EPostgresTransactionType.readWrite,
    },
    [apiRequestType.dashboardExtra.marketing.saveForm]: {
        idMenu: EIdMenus.MarketingForm,
        logFeature: {
            friendlyName: 'Campaing - salvar metadado',
            featureGroup: 'Campaing - Metadados',
        },
        postgresTransType: EPostgresTransactionType.readWrite,
    },
    [apiRequestType.dashboardExtra.smartFlow.saveForm]: {
        idMenu: EIdMenus.OmnisenseForm,
        logFeature: {
            friendlyName: 'criar e alterar metadado',
            featureGroup: 'SmartFlow - Metadado',
        },
        postgresTransType: EPostgresTransactionType.readWrite,
    },

    [apiRequestType.dashboardExtra.communication.saveForm]: {
        idMenu: EIdMenus.CommunicationForm,
        logFeature: {
            friendlyName: 'Atualizar e criar metadado de comunicação',
            featureGroup: 'Comunicações - Metadados',
        },
        postgresTransType: EPostgresTransactionType.readWrite,
    },
    [apiRequestType.dashboardExtra.ai.saveBpm]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Atualizar fluxo do bot',
            featureGroup: 'Smartflow - Bots',
        },
        postgresTransType: EPostgresTransactionType.readWrite,
    },
    [apiRequestType.dashboardExtra.ai.deleteBpm]: {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite,
    },
    [apiRequestType.dashboardExtra.ai.getDependenciesFromHostedObject]: {
        idMenu: '',
    },
    [apiRequestType.dashboardExtra.ai.bpm]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Lista bots',
            featureGroup: 'Smartflow - Bots',
        },
    },
    [apiRequestType.dashboardExtra.ai.getAllElementsByRoot]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Retorna elementos do bots pelo root',
            featureGroup: 'Smartflow - Bots',
        },
    },
    [apiRequestType.dashboardExtra.ai.filterAlreadyUsedBpmRootElement]: {
        idMenu: '',
    },

    [EAttendentRequest.login]: {
        idMenu: EIdMenus.AttendanceStatus,
        logFeature: {
            friendlyName: 'Login/Logout de atendente',
            featureGroup: 'Customer care - Funções de atendente/Status',
        },
    },


    [EAttendentRequest.loginLogoutRealTime]: {
        idMenu: EIdMenus.ProviderAgents,
        logFeature: {
            friendlyName: 'Login/Logout de atendente',
            featureGroup: 'Customer care - Funções de atendente/Status',
        },
    },

    [EFileRequest.getContentFile]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Lista linhas(conteúdo) do banco de dados',
            featureGroup: 'Banco de dados',
        },
    },
    [EFileRequest.getContentErrorFile]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Lista linhas com erro(Erros no upload) do banco de dados',
            featureGroup: 'Banco de dados',
        },
    },

    [EAttendentRequest.saveAgentFile]: {
        idMenu: EIdMenus.AttendanceAgent,
        logFeature: {
            friendlyName: 'Salvar alterações de configuração do atendente',
            featureGroup: 'Customer care - Gereciamento de agentes',
        },
    },
    [EAttendanceIslandRequest.getAttendantsByAvatar]: {
        idMenu: EIdMenus.AttendanceAgent,
        logFeature: {
            friendlyName: 'Configurações do atendente',
            featureGroup: 'Customer care - Gereciamento de agentes',
        },
    },

    [EKnowledgeBaseRequest.editNLPConfig]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        logFeature: {
            friendlyName: 'Atualizar configurações de Processamento de linguagem natural',
            featureGroup: 'Omnisense - Base de conhecimento - NLP',
        },
    },
    [EKnowledgeBaseRequest.editKBAppConfig]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        logFeature: {
            friendlyName: 'Omnisense - Atualizar configurações de acentuação e pontuação da KB',
            featureGroup: 'Omnisense - Base de conhecimento - Sinais gráficos',
        },
    },

    [ETagService.save]: {
        idMenu: EIdMenus.OmnisenseTags,
        logFeature: {
            friendlyName: 'Listar tags',
            featureGroup: 'Smartflow - Tags',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [ETagService.list]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Criar e atualizar tags',
            featureGroup: 'Smartflow - Tags',
        },
    },

    [ESocialNetworkMessages.removeFromGroup]: {
        idMenu: EIdMenus.SocialNetworkUsers,
        logFeature: {
            friendlyName: 'Rede Social - Remover acesso ao grupo do usuário',
            featureGroup: 'Rede Social - Usuários',
        },
    },
    [ESocialNetworkMessages.removeFromThisToBellow]: {
        idMenu: EIdMenus.SocialNetworkUsers,
        logFeature: {
            friendlyName: 'Rede Social - Remover usuário da rede social',
            featureGroup: 'Rede Social - Usuários',
        },
    },

    [EDeployService.saveDepServ]: {
        idMenu: EIdMenus.ServicesServiceDeployed,
        logFeature: {
            friendlyName: 'Serviços - Salvar serviço implantado',
            featureGroup: 'Serviços',
        },
    },
    [EAttendanceIslandRequest.deleteIsland]: {
        idMenu: EIdMenus.AttendanceIsland,
    },
    [EAttendanceIslandRequest.deleteAttendants]: {
        idMenu: EIdMenus.AttendanceIsland,
        logFeature: {
            friendlyName: 'Remover ilha atribuída ao agente',
            featureGroup: 'Customer care - Gereciamento de agentes',
        },
    },


    [apiRequestType.nonSerializable.list]: {
        idMenu: '',
        isLookup: true,
        logFeature: {
            friendlyName: 'lista não serializáveis',
            featureGroup: 'nonSerializable',
        },
    },

    [apiRequestType.nonSerializable.listRemoteEnv]: {
        idMenu: '',
        isLookup: true,
        logFeature: {
            friendlyName: 'lista não serializáveis em outros ambientes',
            featureGroup: 'nonSerializable',
        },
    },
    [apiRequestType.dashboardExtra.attendanceCalendar.create]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Criar e atualizar calendário',
            featureGroup: 'Customer care - Calendario de atendimento',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [apiRequestType.dashboardExtra.attendance.form]: {
        idMenu: EIdMenus.AttendanceForm,
        logFeature: {
            friendlyName: 'Customer care - Metadados',
            featureGroup: 'Customer care - Metadados',
        },
    },


    [apiRequestType.dashboardExtra.attendance.provider]: {
        idMenu: EIdMenus.AttendanceIsland,
        logFeature: {
            friendlyName: 'Ilhas de atendimento',
            featureGroup: 'Customer care - Ilhas de atendimento',
        },
    },
    [apiRequestType.dashboardExtra.attendance.integration]: {
        idMenu: EIdMenus.AttendanceFile,
        logFeature: {
            friendlyName: 'Customer care - Bancos de dados',
            featureGroup: 'Customer care - Bancos de dados',
        },
    },
    [apiRequestType.callcenter.allAgents]: {
        idMenu: EIdMenus.ProviderAgents,
        isLookup: true,
        logFeature: {
            friendlyName: 'Informações gerais dos atendentes',
            featureGroup: 'Customer care - Métricas de atendimento em tempo real',
        },
    },

    [apiRequestType.dashboardExtra.communication.apis]: {
        idMenu: EIdMenus.CommunicationApi,
        logFeature: {
            friendlyName: 'Lista Tokens de API',
            featureGroup: 'Comunicações - API Downstream',
        },
    },

    [apiRequestType.dashboardExtra.attendance.attsuport]: {
        idMenu: EIdMenus.ProviderAttsuport,
        logFeature: {
            friendlyName: 'Recursos de atendente',
            featureGroup: 'Customer care - Recursos de atendentes',
        },
    },
    [apiRequestType.dashboardExtra.attendance.file]: {
        idMenu: EIdMenus.AttendanceAgent,
        logFeature: {
            friendlyName: 'Gerenciamento de agentes',
            featureGroup: 'Customer care - Gereciamento de agentes',
        },
    },
    [apiRequestType.dashboardExtra.attendance.deleteFile]: {
        idMenu: EIdMenus.AttendanceAgent,
        logFeature: {
            friendlyName: 'Deleção de atendente',
            featureGroup: 'Customer care - Gereciamento de agentes',
        },
    },
    [apiRequestType.dashboardExtra.attendance.agentStatus]: {
        idMenu: EIdMenus.AttendanceAgentStatus,
        logFeature: {
            friendlyName: 'Status de atendente/Status de presença',
            featureGroup: 'Customer care - Gereciamento de agentes',
        },
    },
    [apiRequestType.dashboardExtra.communication.form]: {
        idMenu: EIdMenus.CommunicationForm,
        logFeature: {
            friendlyName: 'Listar metadados de comunicação',
            featureGroup: 'Comunicações - Metadados',
        },
    },
    [apiRequestType.dashboardExtra.marketing.form]: {
        idMenu: EIdMenus.MarketingForm,
        logFeature: {
            friendlyName: 'Campaing - Metadados',
            featureGroup: 'Campaing - Metadados',
        },
    },
    [apiRequestType.dashboardExtra.smartFlow.form]: {
        idMenu: EIdMenus.OmnisenseForm,
        logFeature: {
            friendlyName: 'Lista metadados',
            featureGroup: 'SmartFlow - Metadado',
        },
    },


    [apiRequestType.menuSecurityRequest.getApplicationProcessedContract]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Rede Social - Retorna as permissões de acesso de todos os menus',
            featureGroup: 'Rede Social - Configurações',
        },
    },
    [EFileRequest.retrieveNSSchemma]: {
        idMenu: '',
    },

    [EAttendentRequest.getStatusChangeLogs]: {
        idMenu: '', // Not being used 'provider-history'
    },
    [EAttendanceIslandRequest.listIslands]: {
        idMenu: EIdMenus.AttendanceIsland,
    },
    [EAttendanceIslandRequest.saveIsland]: {
        idMenu: EIdMenus.AttendanceIsland,
        logFeature: {
            friendlyName: 'Salvar Ilhas de atendimento',
            featureGroup: 'Customer care - Ilhas de atendimento',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [EAttendanceIslandRequest.changeTemporaryQueuePrioritization]: {
        idMenu: EIdMenus.AttendanceIsland,
    },
    [EJournalingRequestTypes.getAllMigrationVersions]: {
        idMenu: '',
        isLookup: true,
        postgresTransType: EPostgresTransactionType.readOnly
    },
    [EJournalingRequestTypes.listAllNonSerializablesChangedInVersion]: {
        idMenu: '',
        isLookup: true,
        postgresTransType: EPostgresTransactionType.readOnly
    },
    [EJournalingRequestTypes.listAllNonSerializablesChangedWithoutVersion]: {
        idMenu: '',
        isLookup: true,
        postgresTransType: EPostgresTransactionType.readOnly
    },
    [EJournalingRequestTypes.updateAllJournalingToVersionID]: {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [EJournalingRequestTypes.getAllChangesFromNonSerializable]: {
        idMenu: '',
        isLookup: true,
        postgresTransType: EPostgresTransactionType.readOnly
    },
    [EJournalingRequestTypes.getObjectDeployDataRequest]: {
        idMenu: '',
        isLookup: true,
        postgresTransType: EPostgresTransactionType.readOnly
    },
    [EJournalingRequestTypes.upsertMigrationVersion]: {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [EJournalingRequestTypes.deleteNonSerializablesFromVersion]: {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [EJournalingRequestTypes.transferNonSerializablesToVersion]: {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [EYouTaskRequest.save]: {
        idMenu: '',
        // isLookup: true,
    },
    [EYouTaskRequest.getOne]: {
        idMenu: '',
        isLookup: true,
    },
    [EYouTaskRequest.saveBatch]: {
        idMenu: '',
        // isLookup: true,
    },
    [EYouTaskRequest.getAll]: {
        idMenu: '',
    },
    [ECopilotRequest.getAll]: { idMenu: '' },
    [EContactListRequest.saveList]: {
        idMenu: EIdMenus.ContactList,
        logFeature: {
            friendlyName: 'Salvar listas de contatos',
            featureGroup: 'Customer care - Lista de contatos',
        },
    },

    [EContactListRequest.saveListItem]: {
        idMenu: EIdMenus.ContactList,
        logFeature: {
            friendlyName: 'Salvar contato em lista',
            featureGroup: 'Customer care - Lista de contatos',
        },
    },

    [EContactListRequest.saveListItemListsBatch]: {
        idMenu: EIdMenus.ContactList,
        logFeature: {
            friendlyName: 'Salvar contato em várias listas',
            featureGroup: 'Customer care - Lista de contatos',
        },
    },

    [EAttendanceIslandRequest.savePresenceStatus]: {
        idMenu: EIdMenus.AttendanceAgentStatus,
        logFeature: {
            friendlyName: 'Salvar Status de atendente',
            featureGroup: 'Customer care - Gereciamento de agentes',
        },
    },
    [ECampaignRequest.listSocialMedias]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Serviços - Lista canais',
            featureGroup: 'Serviços',
        },
    },
    [apiRequestType.dashboardExtra.env.transformations]: {
        idMenu: EIdMenus.EnvTransformations,
        logFeature: {
            friendlyName: 'Listar transformações',
            featureGroup: 'Ambientes - Transformações',
        },
    },

    [ECampaignView.filter]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Extrair resultados',
            featureGroup: 'Campaing - Extrator de dados',
        },
    },
    [ECampaignView.getAnswers]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Retorna respostas dos metadados',
            featureGroup: 'Campaing - Extrator de dados',
        },
    },
    [ECampaignView.getRowAnswers]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Retorna respostas dos campos do metadado',
            featureGroup: 'campaing-extrator-de-dados',
        },
    },

    [ERealTimeFilter.confirmInteractions]: {
        idMenu: '',
    },
    [apiRequestType.serviceGroup.getService]: {
        idMenu: '',
    },
    [apiRequestType.faceCompany.save]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Rede Social - Salvar Face Company',
            featureGroup: 'Rede Social - Face Company',
        },
    },
    [EFileRequest.saveFeatureAnswer]: {
        idMenu: '',
    },
    [ESharedServicesRequestType.save]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Rede Social - Salvar serviços compartilhados',
            featureGroup: 'Rede Social - Serviços compartilhados',
        },
    },
    [apiRequestType.connections.routes.upsert]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Atualiza rota de conexão',
            featureGroup: 'Comunicações - Rota de conexão',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [apiRequestType.connections.routes.getOne]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Leitura rota de conexão',
            featureGroup: 'Comunicações - Rota de conexão',
        },
    },
    [apiRequestType.connections.getByIdDomain]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Retorna conexão por idDomain',
            featureGroup: 'Smartflow - Gerador de conteúdo',
        },
    },
    [EProdDeply.listPatchHeader]: {
        idMenu: '',
    },
    [EProdDeply.savePatchItem]: {
        idMenu: '',
    },
    [EProdDeply.savePatchHeader]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Criar patch',
            featureGroup: 'Ambientes - Patches',
        },
    },
    [EProdDeply.savePatchBatch]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Adiciona componente ao patch',
            featureGroup: 'Ambientes - Patches',
        },
        postgresTransType: EPostgresTransactionType.readOnly
    },
    [EProdDeply.promote]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Promover patch',
            featureGroup: 'Ambientes - Patches',
        },
    },
    [EProdDeply.listPatchItem]: {
        idMenu: '',
    },
    [EKnowledgeBaseRequest.getKBRelationShip]: {
        idMenu: EIdMenus.OmnisenseChainedBase,
        logFeature: {
            friendlyName: 'lista gerador NLP e base vinculadas à intenção selecionada',
            featureGroup: 'Omnisense - IA encadeada',
        },
    },
    [EKnowledgeBaseRequest.getKBUtteranceTestMigration]: {
        idMenu: EIdMenus.OmnisenseChainedBase,
        logFeature: {
            friendlyName: 'lista inputs (sentenças) do usuário relacionadas ao gerador NLP selecionado',
            featureGroup: 'Omnisense - IA encadeada',
        },
    },

    [EColmeiaAPIToken.create]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Criar Tokens de API',
            featureGroup: 'Comunicações - API Downstream',
        },
    },
    [EColmeiaAPIToken.update]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Atualizar Tokens de API',
            featureGroup: 'Comunicações - API Downstream',
        },
    },

    [apiRequestType.connections.create]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Criar provedor e conexões',
            featureGroup: 'Comunicações - Provedores',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [apiRequestType.connections.get]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Retorna informações da conexão/provedor',
            featureGroup: 'Comunicações - Conexões e Provedores',
        },
    },
    [apiRequestType.connections.testRouteConnection]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Customer care - retorna ações de campanha de agentes',
            featureGroup: 'Customer care - Chamadas ativas',
        },
    },
    [apiRequestType.connections.update]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Atualizar provedor/conexão',
            featureGroup: 'Comunicações - Conexões e Provedores',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [apiRequestType.connections.routes.getNameById]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Retorna rota de conexão por nome',
            featureGroup: 'Smartflow - Gerador de conteúdo',
        },
    },
    [apiRequestType.deployTransformer.upsert]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Criar e atualizar transformação',
            featureGroup: 'Ambientes - Transformações',
        },
    },
    [apiRequestType.deployTransformer.delete]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Deletar transformação',
            featureGroup: 'Ambientes - Transformações',
        },
    },

    [EProdDeply.getChanges]: {
        idMenu: EIdMenus.EnvChange,
        logFeature: {
            friendlyName: 'Lista diferenças/mudanças entre ambientes',
            featureGroup: 'Ambientes - Mudanças',
        },
    },

    [apiRequestType.dashboardExtra.ai.bots]: {
        idMenu: EIdMenus.AiBots,
    },
    [apiRequestType.dashboardExtra.ai.transaction]: {
        idMenu: EIdMenus.OmnisenseTransactions,
        logFeature: {
            friendlyName: 'lista de geradores de conteúdo do tipo NLP e Forms',
            featureGroup: 'Omnisense e SmartFlow- gerador de conteúdo',
        },
    },
    [apiRequestType.dashboardExtra.marketing.campaigns]: {
        idMenu: EIdMenus.MarketingCampaigns,
        logFeature: {
            friendlyName: 'Campanhas de Marketing',
            featureGroup: 'Campaing - Campanhas',
        },
    },
    [apiRequestType.dashboardExtra.marketing.send]: {
        idMenu: EIdMenus.MarketingSendCampaign,
        logFeature: {
            friendlyName: 'Campaing - Enviar Campanha',
            featureGroup: 'Campaing - Enviar Campanha',
        },
    },
    [apiRequestType.dashboardExtra.env.patches]: {
        idMenu: EIdMenus.EnvPatches,
        logFeature: {
            friendlyName: 'Lista de Patches',
            featureGroup: 'Ambientes',
        },

    },
    [apiRequestType.dashboardExtra.env.promote]: {
        idMenu: EIdMenus.EnvPromote,
        logFeature: {
            friendlyName: 'Patches promovidos',
            featureGroup: 'Ambientes',
        },
    },

    [apiRequestType.dashboardExtra.marketing.integration]: {
        idMenu: EIdMenus.MarketingIntegration,
        logFeature: {
            friendlyName: 'Campaing - Bancos de dados',
            featureGroup: 'Campaing - Bancos de dados',
        },
    },
    [apiRequestType.dashboardExtra.marketing.submitWhatsAppTemplate]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Template - Submete template de WhatsApp para aprovação',
            featureGroup: 'Campaing - Template',
        },
    },
    [apiRequestType.dashboardExtra.marketing.archiveWhatsAppTemplate]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Template - Arquivar template',
            featureGroup: 'Campaing - Template',
        },
    },
    [apiRequestType.dashboardExtra.service.integration]: {
        idMenu: EIdMenus.ServiceIntegration,
        logFeature: {
            friendlyName: 'Serviços - Lista bancos de dados de serviço',
            featureGroup: 'Serviços - Banco de dados',
        },
    },
    [apiRequestType.dashboardExtra.jobs.environmentDataMigration]: {
        idMenu: EIdMenus.JobsEnvironmentDataMigration,
    },
    [apiRequestType.dashboardExtra.smartFlow.integration]: {
        idMenu: EIdMenus.AiIntegration,
        logFeature: {
            friendlyName: 'Lista bancos de dados',
            featureGroup: 'Smartflow - Bancos de dados',
        },
    },
    [apiRequestType.connections.all]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Listar provedores',
            featureGroup: 'Comunicações - Provedores',
        },
    },

    [apiRequestType.connections.routes.all]: {
        idMenu: EIdMenus.CommunicationConnectionRoute,
        logFeature: {
            friendlyName: 'Lista rotas de conexão',
            featureGroup: 'Comunicações - Rota de conexão',
        },
    },
    [EKnowledgeBaseRequest.listKBInfo]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        logFeature: {
            friendlyName: 'Omnisense - lista bases de conhecimento',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },
    [EBotTransaction.getLocalCanonical]: {
        idMenu: EIdMenus.OmnisenseCanonicals,
        logFeature: {
            friendlyName: 'Lista significados',
            featureGroup: 'Smartflow - Significados',
        },
    },
    [EBotTransaction.getLocalCanonicalByGlobal]: {
        idMenu: '',
    },

    [EDeployService.listDepServs]: {
        idMenu: EIdMenus.ServicesServiceDeployed,
        logFeature: {
            friendlyName: 'Serviços - Lista serviços implantados',
            featureGroup: 'Serviços',
        },
    },

    [EProdDeply.listTagDependencies]: {
        idMenu: EIdMenus.EnvTagsdeployedmenuitem,
        logFeature: {
            friendlyName: 'Rastreamento de tags',
            featureGroup: 'Ambientes - Rastreamento de tags',
        },
    },
    [ESecurityControlRequestType.list]: {
        idMenu: EIdMenus.SocialNetworkAccess,
        logFeature: {
            friendlyName: 'Rede Social - Regras de acesso',
            featureGroup: 'Rede Social - Regras de acesso',
        },
    },
    [ESharedServicesRequestType.list]: {
        idMenu: EIdMenus.SocialNetworkShareServices,
        logFeature: {
            friendlyName: 'Rede Social - Serviços compartilhados',
            featureGroup: 'Rede Social - Serviços compartilhados',
        },
    },

    [apiRequestType.visualElement.insertInAllEnvironments]: {
        idMenu: EIdMenus.ColmeiaVisualElements,
    },


    [apiRequestType.visualElement.get]: {
        idMenu: EIdMenus.ColmeiaVisualElements,
    },


    [apiRequestType.visualElement.upsert]: {
        idMenu: EIdMenus.ColmeiaVisualElements,
    },

    [apiRequestType.visualElement.refreshCache]: {
        idMenu: EIdMenus.ColmeiaVisualElements,
        logFeature: {
            friendlyName: 'Atualizar cache',
            featureGroup: 'Colmeia - Elementos visuais',
        },
    },

    [apiRequestType.faceCompany.list]: {
        idMenu: EIdMenus.SocialNetworkFacecompany,
        logFeature: {
            friendlyName: 'Rede Social - Face Company',
            featureGroup: 'Rede Social - Face Company',
        },
    },
    [apiRequestType.callcenter.generalQueueInformation]: {
        idMenu: EIdMenus.ProviderAgents,
        isLookup: true,
        logFeature: {
            friendlyName: 'Informações gerais das ilhas de atendimento',
            featureGroup: 'Customer care - Métricas de atendimento em tempo real',
        },
    },
    [apiRequestType.callcenter.queuePendencies]: {
        idMenu: EIdMenus.ProviderAgents,
        logFeature: {
            friendlyName: 'Informações dos atendimentos na fila de uma ilha',
            featureGroup: 'Customer care - Métricas de atendimento em tempo real',
        },
    },
    [apiRequestType.callcenter.removeQueues]: {
        idMenu: EIdMenus.ProviderAgents,
        logFeature: {
            friendlyName: 'Remove clientes selecionados da fila',
            featureGroup: 'Customer care - Ferramentas',
        },
    },
    [apiRequestType.callcenter.finishAttendances]: {
        idMenu: EIdMenus.ProviderAgents,
        logFeature: {
            friendlyName: 'Finaliza atendimento de clientes selecionados',
            featureGroup: 'Customer care - Ferramentas',
        },
    },
    [apiRequestType.callcenter.assignedWorkQueueItem]: {
        idMenu: EIdMenus.ProviderAgents,
        logFeature: {
            friendlyName: 'Informações dos atendimentos de uma ilha',
            featureGroup: 'Customer care - Métricas de atendimento em tempo real',
        },
    },
    [apiRequestType.callcenter.allAgentCases]: {
        idMenu: EIdMenus.ProviderAgents,
        logFeature: {
            friendlyName: 'Informações dos atendimentos de um agente',
            featureGroup: 'Customer care - Métricas de atendimento em tempo real',
        },
    },


    [EBotTransaction.saveBot]: {
        idMenu: EIdMenus.AiBots,
        logFeature: {
            friendlyName: 'Atualizar elemento do bot',
            featureGroup: 'Smartflow - Bots',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [EBotTransaction.listBot]: {
        idMenu: EIdMenus.AiBots,
    },

    [EBotTransaction.saveLocalCanonical]: {
        idMenu: EIdMenus.OmnisenseCanonicals,
        logFeature: {
            friendlyName: 'Criar e atualizar significado',
            featureGroup: 'Smartflow - Significados',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },


    [EBotTransaction.saveTransaction]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Criar e alterar gerador de conteúdo',
            featureGroup: 'Smartflow - Gerador de conteúdo',
        },
        postgresTransType: EPostgresTransactionType.readWrite
    },
    [EBotTransaction.getTransactions]: {
        idMenu: '',
    },

    [EKBOperations.predict]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        logFeature: {
            friendlyName: 'Omnisense - predições',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },
    [EKBOperations.testPredictions]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        logFeature: {
            friendlyName: 'Omnisense - Teste de predições',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },
    [EKBOperations.train]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        logFeature: {
            friendlyName: 'treinar base de conhecimento',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },
    [EKBOperations.saveIntent]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        logFeature: {
            friendlyName: 'Omnisense - criar intenção',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },
    [EKnowledgeBaseRequest.getUtteranceExampleTestAnalysis]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'retorna análise da sentença',
            featureGroup: 'Omnisense - retro alimentação de machine learning',
        },
    },
    [EKBOperations.saveUtterance]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        logFeature: {
            friendlyName: 'Omnisense - salvar sentença',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },
    [EKBOperations.deleteUtterance]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        logFeature: {
            friendlyName: 'Omnisense - deleta sentença ',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },
    [EKBOperations.getUtterances]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        logFeature: {
            friendlyName: 'Omnisense - lista sentenças',
            featureGroup: 'Omnisense - Base de conhecimento',
        },
    },
    [EKBOperations.listPrebuiltEntities]: {
        idMenu: EIdMenus.OmnisenseKnowledgeBase,
        logFeature: {
            friendlyName: 'Omnisense - Listar entidades prédefinidas',
            featureGroup: 'Omnisense - Base de conhecimento - Extração',
        },
    },
    [apiRequestType.dashboardExtra.crm.crmFetchDomainItens]: {
        postgresTransType: EPostgresTransactionType.readOnly,
        idMenu: '',
        isLookup: true,
    },
    [apiRequestType.dashboardExtra.crm.saveItemsOrder]: {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite,
        logFeature: {
            friendlyName: 'Update order of crm config items',
            featureGroup: 'Update order of crm config items',
        },
    },
    [colmeiaServiceRequestType.embedded.finishConversation]: {
        idMenu: '',
        logFeature: {
            friendlyName: 'Webchat finalizar conversação',
            featureGroup: 'Webchat finalizar conversação',
        },
    },

    // Whatsapp Flow
    //
    [EWAFSchemaRequest.get]: {
        idMenu: EIdMenus.WAFSchemaGet,
        logFeature: {
            friendlyName: 'Get WAF',
            featureGroup: 'WAF',
        },
    },
    [EWAFSchemaRequest.upsert]: {
        idMenu: EIdMenus.WAFSchemaUpsert,
        logFeature: {
            friendlyName: 'Upsert WAF',
            featureGroup: 'WAF',
        },
    },
    [EWAFSchemaRequest.publish]: {
        idMenu: EIdMenus.WAFSchemaPublish,
        logFeature: {
            friendlyName: 'Publish WAF',
            featureGroup: 'WAF',
        },
    },
    [EWAFSchemaRequest.deprecate]: {
        idMenu: EIdMenus.WAFSchemaDeprecate,
        logFeature: {
            friendlyName: 'Deprecate WAF',
            featureGroup: 'WAF',
        },
    },
    [EWAFSchemaRequest.delete]: {
        idMenu: EIdMenus.WAFSchemaDelete,
        logFeature: {
            friendlyName: 'Delete WAF',
            featureGroup: 'WAF',
        },
    },
    [EWAFSchemaRequest.send]: {
        idMenu: EIdMenus.WAFSchemaMessageSend,
        logFeature: {
            friendlyName: 'Send WAF',
            featureGroup: 'WAF',
        },
    },
    [EWAFSchemaRequest.submit]: {
        idMenu: EIdMenus.WAFSchemaMessageSend,
        logFeature: {
            friendlyName: 'Submit WAF Schema',
            featureGroup: 'WAF',
        },
    },
    [EWAFRequest.get]: {
        idMenu: EIdMenus.WAFGet,
        logFeature: {
            friendlyName: 'Get WAF',
            featureGroup: 'WAF',
        },
    },
    [EWAFRequest.upsert]: {
        idMenu: EIdMenus.WAFUpsert,
        logFeature: {
            friendlyName: 'Upsert WAF',
            featureGroup: 'WAF',
        },
    },
    [EWAFRequest.listProviders]: {
        idMenu: EIdMenus.WAFListProviders,
        logFeature: {
            friendlyName: 'List Providers for WAF',
            featureGroup: 'WAF',
        },
    },

    [ECRMTicketRuntimeRequests.getAgentTickets]: {
        idMenu: '',
        isLookup: true,
        postgresTransType: EPostgresTransactionType.readOnly,
        logFeature: {
            friendlyName: 'Get all tickets for agent',
            featureGroup: 'CRM',
        },
    },

    [ECRMTicketRuntimeRequests.getAgentIslands]: {
        idMenu: '',
        isLookup: true,
        postgresTransType: EPostgresTransactionType.readOnly,
        logFeature: {
            friendlyName: 'Get all islands of agent',
            featureGroup: 'CRM',
        },
    },


    [ECRMTicketRuntimeRequests.getAvatarPackages]: {
        idMenu: '',
        isLookup: true,
        postgresTransType: EPostgresTransactionType.readOnly,
        logFeature: {
            friendlyName: 'Get all crm packages of agent',
            featureGroup: 'CRM',
        },
    },

    [ECRMTicketRuntimeRequests.insertTicketInteraction]: {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite,
        logFeature: {
            friendlyName: 'Insert ticket interaction',
            featureGroup: 'CRM',
        },
    },

    [ECRMTicketRuntimeRequests.getAllTicketInteractions]: {
        idMenu: '',
        isLookup: true,
        postgresTransType: EPostgresTransactionType.readOnly,
        logFeature: {
            friendlyName: 'Get ticket full data',
            featureGroup: 'CRM',
        },
    },

    [ECRMTicketRuntimeRequests.getAttendanceTickets]: {
        idMenu: '',
        isLookup: true,
        postgresTransType: EPostgresTransactionType.readOnly,
        logFeature: {
            friendlyName: 'Get all attendance tickets',
            featureGroup: 'CRM',
        },
    },

    [ECRMTicketRuntimeRequests.getTicketData]: {
        idMenu: '',
        isLookup: true,
        postgresTransType: EPostgresTransactionType.readOnly,
        logFeature: {
            friendlyName: 'Get data of ticket',
            featureGroup: 'CRM',
        },
    },

    [ECRMTicketRuntimeRequests.getPackages]: {
        idMenu: '',
        isLookup: true,
        postgresTransType: EPostgresTransactionType.readOnly,
        logFeature: {
            friendlyName: 'Get especified packages',
            featureGroup: 'CRM',
        },
    },

    [apiRequestType.getOldestRecentInteractionsInfo]: {
        idMenu: '',
    },


    [apiRequestType.dashboardExtra.crm.transformer]: {
        idMenu: EIdMenus.CRMTransformer,
        logFeature: {
            friendlyName: 'CRM - Transformador',
            featureGroup: 'CRM - Transformador',
        },
    },
    [apiRequestType.dashboardExtra.crm.saveTransformer]: {
        idMenu: EIdMenus.CRMTransformer,
        logFeature: {
            friendlyName: 'CRM - Transformador',
            featureGroup: 'CRM - Transformador',
        },
    },

    [apiRequestType.dashboardExtra.smartFlow.transformer]: {
        idMenu: EIdMenus.SmartFlowTransformer,
        logFeature: {
            friendlyName: 'SmartFlow - Transformador',
            featureGroup: 'SmartFlow - Transformador',
        },
    },
    [apiRequestType.dashboardExtra.smartFlow.saveTransformer]: {
        idMenu: EIdMenus.SmartFlowTransformer,
        logFeature: {
            friendlyName: 'SmartFlow - Transformador',
            featureGroup: 'SmartFlow - Transformador',
        },
    },
    [ECRMTicketSupervisionRequests.getAllocatedTickets]: {
        idMenu: EIdMenus.AnalyticsAttendance,
        logFeature: {
            friendlyName: 'CRM - Tickets - Supervisão',
            featureGroup: 'CRM - Tickets - Supervisão',
        },
    },
    [ECRMTicketSupervisionRequests.getQueuesStatus]: {
        idMenu: EIdMenus.AnalyticsAttendance,
        postgresTransType: EPostgresTransactionType.readOnly,
        logFeature: {
            friendlyName: 'CRM - Informações gerais das filas - Supervisão',
            featureGroup: 'CRM - Informações gerais das filas - Supervisão',
        },
    },
    [ECRMTicketSupervisionRequests.transferTicket]: {
        idMenu: EIdMenus.AnalyticsAttendance,
        postgresTransType: EPostgresTransactionType.readWrite,
        logFeature: {
            friendlyName: 'CRM - Transferir ticket - Supervisão',
            featureGroup: 'CRM - Transferir ticket - Supervisão',
        },
    },
});

import { asConst } from '../../../tools/type-utils';
import { TIBPMBasicConditionalEvaluatorArray } from '../../BPM/bpm-model';
import { IMetadataRegister } from '../../metadata/meta-engagement';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from '../../non-serializable-id/non-serializable-id-interfaces';
import { IdDep, IdNS } from '../../non-serializable-id/non-serializable-types';
import { ECloseState, ECRMCanonicalItemsTypes, ECRMDBType, EServiceManagementPhases, ESeverityLevel, ESupportLevel, ETicketState, EUrgencyLevel } from './crm-canonical-model';




export interface ICRMDatabaseManifest {
    idDatabaseManifest: string;
    idRTF: IdDep<ENonSerializableObjectType.realTimeFusion>;
}


export type TSRdatabaseConfig = { [dbType in ECRMDBType]?: ICRMDatabaseManifest }

export type TCRMConfigItemsNsTypes =
    ENonSerializableObjectType.crmItemCloseState |
    ENonSerializableObjectType.crmItemPhase |
    ENonSerializableObjectType.crmItemSeverity |
    ENonSerializableObjectType.crmItemState |
    ENonSerializableObjectType.crmItemSupportLevel |
    ENonSerializableObjectType.crmItemUrgencyLevel;

export type TCRMConfigHeaderNsTypes =
    ENonSerializableObjectType.crmHeaderCloseState |
    ENonSerializableObjectType.crmHeaderPhase |
    ENonSerializableObjectType.crmHeaderSeverity |
    ENonSerializableObjectType.crmHeaderState |
    ENonSerializableObjectType.crmHeaderSupportLevel |
    ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel;

export type TCRMConfigNSTypes = TCRMConfigItemsNsTypes | TCRMConfigHeaderNsTypes | ENonSerializableObjectType.crmConfigPackage;

export type TCRMConfigPackagesNsTypes = TCRMConfigHeaderNsTypes | ENonSerializableObjectType.crmConfigPackage;


export enum ECRMAgentItemUpdateControl {
    free = 'fr',
    useFormHeader = 'frmhdr',
    useForm = 'frm',
}

export interface ICRMAgentItemUpdateForm {
    register: IMetadataRegister;
    control: ECRMAgentItemUpdateControl;
}

export interface ICRMAgentStatusUpdateItem extends ICRMAgentItemUpdateForm {
    conditions: TIBPMBasicConditionalEvaluatorArray;
}

export type TCRMAgentStatusUpdateItems = { [key in IdNS<TCRMConfigItemServer>]: ICRMAgentStatusUpdateItem };

export interface ICRMAgentStatusUpdateConfig {
    form: ICRMAgentItemUpdateForm;
    items: TCRMAgentStatusUpdateItems;
}

export interface ICRMProjectConfigurationBase {
    projectKey: string;

}

export interface ICRMProjectDimensionsItemConfig {
    id: IdDep<TCRMConfigHeaderNsTypes>;
    updateConfig: ICRMAgentStatusUpdateConfig;
}

export interface ICRMProjectDimensionsConfigs_OLD {
    idSeverityConfig: IdDep<ENonSerializableObjectType.crmHeaderSeverity>;
    idSRPhasesConfig: IdDep<ENonSerializableObjectType.crmHeaderPhase>;
    idTicketStateConfig: IdDep<ENonSerializableObjectType.crmHeaderState>;
    idCloseStateConfig: IdDep<ENonSerializableObjectType.crmHeaderCloseState>;
    idSupportLevelConfig: IdDep<ENonSerializableObjectType.crmHeaderSupportLevel>;
    idUrgencyLevelConfig: IdDep<ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel>;
}
export interface ICRMProjectDimensionsConfigs {
    severity: ICRMProjectDimensionsItemConfig;
    phase: ICRMProjectDimensionsItemConfig;
    ticketState: ICRMProjectDimensionsItemConfig;
    closeState: ICRMProjectDimensionsItemConfig;
    supportLevel: ICRMProjectDimensionsItemConfig;
    urgencyLevel: ICRMProjectDimensionsItemConfig;
}

// export interface ICRMProjectDimensionsUpdateConfigs {
//     severityForm: ICRMAgentStatusUpdateConfig;
//     phasesForm: ICRMAgentStatusUpdateConfig;
//     ticketStateForm: ICRMAgentStatusUpdateConfig;
//     closeStateForm: ICRMAgentStatusUpdateConfig;
//     supportLevelForm: ICRMAgentStatusUpdateConfig;
//     urgencyLevelForm: ICRMAgentStatusUpdateConfig;
// }

export interface ICRMProjectConfiguration extends ICRMProjectConfigurationBase, ICRMProjectDimensionsConfigs {

}

export interface ICRMConfigItemBase {
    sortIndex: number;
}

export interface ICRMProjectClient extends INonSerializableHeader, ICRMProjectConfiguration {
    supportingDB: TSRdatabaseConfig;
}
export interface ICRMProjectServer extends ICRMProjectClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmConfigPackage;
}


export interface ICloseStateConfigHeaderClient extends INonSerializableHeader {

}
export interface ICloseStateConfigHeaderServer extends ICloseStateConfigHeaderClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmHeaderCloseState;
}
export interface ICloseStateConfigItemClient extends ICRMConfigItemBase, INonSerializableHeader {
    closeState: ECloseState;
}
export interface ICloseStateConfigItemServer extends ICloseStateConfigItemClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmItemCloseState;
}

//

export interface IPhaseConfigHeaderClient extends INonSerializableHeader { }

export interface IPhaseConfigHeaderServer extends IPhaseConfigHeaderClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmHeaderPhase;
}

export interface IPhaseConfigItemClient extends ICRMConfigItemBase, INonSerializableHeader {
    phase: EServiceManagementPhases;
}

export interface IPhaseConfigItemServer extends IPhaseConfigItemClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmItemPhase;
}

// 
export interface ISeverityConfigHeaderClient extends INonSerializableHeader { }

export interface ISeverityConfigHeaderServer extends ISeverityConfigHeaderClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmHeaderSeverity;
}

export interface ISeverityConfigItemClient extends ICRMConfigItemBase, INonSerializable {
    severity: ESeverityLevel;
}

export interface ISeverityConfigItemServer extends ISeverityConfigItemClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmItemSeverity;
}

//
export interface IStateConfigHeaderClient extends INonSerializableHeader {
}

export interface IStateConfigHeaderServer extends IStateConfigHeaderClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmHeaderState;
}

export interface IStateConfigItemClient extends ICRMConfigItemBase, INonSerializableHeader {
    state: ETicketState;
}

export interface IStateConfigItemServer extends IStateConfigItemClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmItemState;
}

export interface ISupportLevelHeaderClient extends INonSerializableHeader {
}

export interface ISupportLevelConfigHeaderServer extends IStateConfigHeaderClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmHeaderSupportLevel;
}

export interface ISupportLevelConfigItemClient extends ICRMConfigItemBase, INonSerializableHeader {
    support: ESupportLevel;
}
export interface ISupportLevelConfigItemServer extends ISupportLevelConfigItemClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmItemSupportLevel;
}


export interface ISupportUrgencyHeaderClient extends INonSerializableHeader {
}

export interface ISupportUrgencyHeaderServer extends IStateConfigHeaderClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel;
}

export interface ISupportUrgencyItemClient extends ICRMConfigItemBase, INonSerializableHeader {
    urgency: EUrgencyLevel;
}
export interface ISupportUrgencyConfigItemServer extends ISupportUrgencyItemClient, INonSerializable {
    nsType: ENonSerializableObjectType.crmItemUrgencyLevel;
}



export type TCRMConfigHeaderServer =
    ICloseStateConfigHeaderServer |
    IPhaseConfigHeaderServer |
    ISeverityConfigHeaderServer |
    IStateConfigHeaderServer |
    ISupportLevelConfigHeaderServer |
    ISupportUrgencyHeaderServer;

export type TCRMConfigItemServer =
    ICloseStateConfigItemServer |
    IPhaseConfigItemServer |
    ISeverityConfigItemServer |
    IStateConfigItemServer |
    ISupportLevelConfigItemServer |
    ISupportUrgencyConfigItemServer;

export type TCRMConfigServer = TCRMConfigHeaderServer | TCRMConfigItemServer;
export type TCRMAllConfigurationPackages = TCRMConfigHeaderServer | ICRMProjectServer;
export type TAllCRMItems = TCRMConfigHeaderServer | TCRMConfigItemServer | ICRMProjectServer;

export interface ICRMNotification {
    header: TCRMConfigHeaderServer;
    itens: Array<TCRMConfigItemServer>;
}

export type ICRMFullPackage = {
    [id in TCRMConfigItemsNsTypes]: ICRMNotification
}


export const mapCRMHeaderTypeToItem = asConst<{ [key in TCRMConfigHeaderNsTypes]: TCRMConfigItemsNsTypes }>()({
    [ENonSerializableObjectType.crmHeaderCloseState]: ENonSerializableObjectType.crmItemCloseState,
    [ENonSerializableObjectType.crmHeaderPhase]: ENonSerializableObjectType.crmItemPhase,
    [ENonSerializableObjectType.crmHeaderSeverity]: ENonSerializableObjectType.crmItemSeverity,
    [ENonSerializableObjectType.crmHeaderState]: ENonSerializableObjectType.crmItemState,
    [ENonSerializableObjectType.crmHeaderSupportLevel]: ENonSerializableObjectType.crmItemSupportLevel,
    [ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel]: ENonSerializableObjectType.crmItemUrgencyLevel,
})

/**
 * Essas interfaces são as representações dos pacotes e itens
 * salvos no postgre 
 */

export interface ICRMHeaderPackageBase {
    headerPackageId: string;
    name: string;
    type: ECRMCanonicalItemsTypes;
    items: Array<TCRMAllPackagesItems>;
}

export interface ICRMPackageItemBase {
    itemId: string;
    name: string;
    itemType: ECRMCanonicalItemsTypes;
    sortIndex: number;
}

export interface ICRMHeaderCloseState extends ICRMHeaderPackageBase {
    items: Array<ICRMCloseState>;
}


export interface ICRMCloseState extends ICRMPackageItemBase {
    closeState: ECloseState;
    itemType: ECRMCanonicalItemsTypes.CloseState;
}

export interface ICRMHeaderPhase extends ICRMHeaderPackageBase {
    items: Array<ICRMPhase>;
}

export interface ICRMPhase extends ICRMPackageItemBase {
    phase: EServiceManagementPhases;
    itemType: ECRMCanonicalItemsTypes.Phase;
}

export interface ICRMHeaderSeverity extends ICRMHeaderPackageBase {
    items: Array<ICRMSeverity>;
}


export interface ICRMSeverity extends ICRMPackageItemBase {
    severity: ESeverityLevel;
    itemType: ECRMCanonicalItemsTypes.Severity;
}

export interface ICRMHeaderState extends ICRMHeaderPackageBase {
    items: Array<ICRMState>;
}

export interface ICRMState extends ICRMPackageItemBase {
    ticketState: ETicketState;
    itemType: ECRMCanonicalItemsTypes.TicketState;
}

export interface ICRMHeaderSupportLevel extends ICRMHeaderPackageBase {
    items: Array<ICRMSupportLevel>;
}

export interface ICRMSupportLevel extends ICRMPackageItemBase {
    supportLevel: ESupportLevel;
    itemType: ECRMCanonicalItemsTypes.SupportLevel;
}

export interface ICRMHeaderUrgencyLevel extends ICRMHeaderPackageBase {
    items: Array<ICRMUrgencyLevel>;
}

export interface ICRMUrgencyLevel extends ICRMPackageItemBase {
    urgencyLevel: EUrgencyLevel;
    itemType: ECRMCanonicalItemsTypes.UrgencyLevel;
}

export interface ICRMPackagesMap {
    closeState?: ICRMHeaderCloseState;
    phase?: ICRMHeaderPhase;
    severity?: ICRMHeaderSeverity;
    ticketState?: ICRMHeaderState;
    supportLevel?: ICRMHeaderSupportLevel;
    urgencyLevel?: ICRMHeaderUrgencyLevel;
}

export type TCRMAllPackages = ICRMHeaderCloseState | ICRMHeaderPhase | ICRMHeaderSeverity | ICRMHeaderState | ICRMHeaderSupportLevel | ICRMHeaderUrgencyLevel;


export type TCRMAllPackagesItems = ICRMCloseState | ICRMPhase | ICRMSeverity | ICRMState | ICRMSupportLevel | ICRMUrgencyLevel;

export interface ICRMProject {
    packageId: string;
    projectKey: string;
    name: string;
    packages: ICRMPackagesMap;
    config: ICRMProjectConfiguration;
}

export type TICRMProjectArray = Array<ICRMProject>;
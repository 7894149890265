import { IAnalyticsBiReportItemServer } from '@colmeia/core/src/shared-business-rules/analytics-bi-report-item/analytics-bi-report-item.model';
import { IBillingClosingServer, IBillingItemServer } from '@colmeia/core/src/shared-business-rules/billing/billing-model';
import { ICRMProcessingNodeEventServer } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crms-model-bpm-def';
import { IGenerativePromptServer, IGenerativeVectorChunkServer, IGenerativeVectorServer } from '@colmeia/core/src/shared-business-rules/generative/generative-model';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { IBackupPackage, IBackupSnapshot } from '@colmeia/core/src/shared-business-rules/ns-backup/backup-model';
import { IUserApprovalProfileServer } from '@colmeia/core/src/shared-business-rules/user-approval-profile/user-approval-profile';
import { Define } from '@colmeia/core/src/tools/utility/types/entities/define';
import { IGeneralFormAnswerServer } from "../../general-form/general-form-answer";
import { IGeneralFileMetadata } from "../../request-interfaces/files-interfaces";
import { ValueOf } from "../../tools/utility-types";
import { IntersectPartialExplicit } from "../../tools/utility/types";
import { Custom } from "../../tools/utility/types/generic";
import { IBPMMarketingAcknowledgeServer, IBPMMarketingActionServer, IBPMMarketingEndServer, IBPMMarketingListenerServer, IBPMMarketingPauseServer, IBPMMarketingRootServer } from '../BPM/marketing/bpm-marketing.model';
import { INewAgentCallBatchServer, INewAgentCallContactServer } from "../active-1x1-call/active-1x1-model";
import { IIslandAttendantServer, IServiceIslandServer } from "../attendance-island/attendance-island";
import { ICustomEventsServer } from "../attendance-island/custom-events";
import { IAttendanceCalendarServer } from "../attendance-island/ns-sheduler";
import { IRealtimeFusionModelServer } from "../attendance-island/real-time-fusion-model";
import { ICCAgentFileServer, IMacroPackageServer, IPresenceStatusServer } from "../attendent-service-pack/attendente-service-pack";
import { IColmeiaBillingContractServer, IProductModuleServer, IProductSKUServer, IProductServer } from '../billing/billing-ns-model';
import { IRegisterNLPActivityServer } from "../bot/bot-improvement";
import { INextGenBotServer } from "../bot/bot-model";
import { ICampaignServer } from "../campaigns/campaign-type-model";
import { ICampaignExecutionServer } from "../campaigns/capaing-exec-interfaces";
import { IServerLocalCanonical } from "../canonical-model/local-canonical";
import { INSCatalogServer } from '../catalog/catalog.interfaces';
import { IColmeiaAPITokenConfigServer } from "../colmeia-apis/colmeia-api-model";
import { IServerColmeiaTag } from "../colmeia-tags/tags";
import { IConfigurationSetServer } from '../configuration-set/config-set-model';
import { IConnectionRouteServer, IConnectionServer } from "../connections/endpoint-model";
import { IContactListItemServer, IContactListServer } from '../contact-list/contact-list.model';
import { ICorporateSearchBodyServer, ICorporateSearchHeaderServer } from "../corporate-search/corporate-search-model";
import { ICloseStateConfigHeaderServer, ICloseStateConfigItemServer, IPhaseConfigHeaderServer, IPhaseConfigItemServer, ICRMProjectServer, ISeverityConfigHeaderServer, ISeverityConfigItemServer, IStateConfigHeaderServer, IStateConfigItemServer, ISupportLevelConfigHeaderServer, ISupportLevelConfigItemServer, ISupportUrgencyConfigItemServer, ISupportUrgencyHeaderServer } from "../crm/crm-services/crm-config-interfaces";
import { ICRMExecBasicInterfaceTyped } from '../crm/crm-services/crm-model-bpm-exec';
import { ICRMAgentEventServer, ICRMNotificationServer, ICRMRootServer } from "../crm/crm-services/crms-model-bpm-def";
import { IServiceDeployedServices } from "../deployed-services/deployed-services";
import { INSFaceCompanyServer } from "../face-company/ns-facecompany";
import { SchemaPropertyServer } from "../files/files";
import { IBasicElementServer } from "../graph/essential/graph-basic-element-interfaces";
import { IFeatureGroupServer } from "../group-features/group-featues-model";
import { IFlowInjectionBodyServer, IFlowInjectionHeaderServer } from "../injection-flow/injection-flow";
import { IJobServer } from '../jobs/jobs-model';
import { ITransactionServer } from "../knowledge-base/bot-transaction/bot-transaction";
import { IKnowledgeDBServer } from "../knowledge-base/kb-inferfaces";
import { INSKBCorporateEntityServer, IUtteranceServer } from "../knowledge-base/luis-core-interfaces";
import { IMetaBusinessPagesServer } from '../meta-business-pages/meta-business-pages.interfaces';
import { IDeployedItemProductionServer, IDeployedProductionServer, IPatchItemServer, IProdPatchModelServer } from "../production-deploy/prod-deploy-model";
import { IDeployTransformationServer } from "../production-deploy/transformation";
import { IAccessRoleServer } from "../security/security-control.model";
import { ISocialMediaConnectionServer } from "../social-media/social-media.model";
import { IGenericNSTemplateServer } from "../social-media/template-model";
import { ISocialNetworkConfigServer } from "../social-network/social-network-config";
import { ISocialNetworkSettingsServer } from '../social-network/social-network-user-settings.model';
import { IDataExtractionQueryServer } from '../tools/data-extraction-query.model';
import { IUserFunctionModelServer } from '../user-function/user-function-model';
import { IWhatsappBusinessAccountServer } from "../waba/waba.model";
import { IYouTasksServer } from '../you-control/solicitation-tasks-model';
import { ENonSerializableObjectType, INonSerializable } from "./non-serializable-id-interfaces";
import { IWAFSchemaServer, IWAFServer } from '../waf/waf.model';
import { ICopilotServer } from '../copilot/copilot.model';
import { ITransformerServer } from '../transformer/transformer.model';


export type NsTypeToInterface = Define<{ [key in ENonSerializableObjectType]: IntersectPartialExplicit<INonSerializable, {}> }, {
    [ENonSerializableObjectType.kbCorporateEntity]: INSKBCorporateEntityServer,
    [ENonSerializableObjectType.backupPackage]: IBackupPackage,
    [ENonSerializableObjectType.backupSnapshot]: IBackupSnapshot,
    [ENonSerializableObjectType.userApprovalProfile]: IUserApprovalProfileServer,
    [ENonSerializableObjectType.analyticsBiReportItem]: IAnalyticsBiReportItemServer,
    [ENonSerializableObjectType.patchHeader]: IProdPatchModelServer,
    [ENonSerializableObjectType.patchItem]: IPatchItemServer,
    [ENonSerializableObjectType.colmeiaTags]: IServerColmeiaTag,
    [ENonSerializableObjectType.clientGeneralAnswer]: IGeneralFormAnswerServer,
    [ENonSerializableObjectType.attendantAllowedAvatar]: IIslandAttendantServer,
    [ENonSerializableObjectType.canonical]: IServerLocalCanonical,
    [ENonSerializableObjectType.deployTransformer]: IDeployTransformationServer,
    [ENonSerializableObjectType.deployHeader]: IDeployedProductionServer,
    [ENonSerializableObjectType.deployProdItem]: IDeployedItemProductionServer,
    [ENonSerializableObjectType.faceCompany]: INSFaceCompanyServer,
    [ENonSerializableObjectType.presenceStatus]: IPresenceStatusServer,
    [ENonSerializableObjectType.callcenterAtt]: ICCAgentFileServer,
    [ENonSerializableObjectType.NLPActivity]: IRegisterNLPActivityServer,
    [ENonSerializableObjectType.socialNetworkConfig]: ISocialNetworkConfigServer,
    [ENonSerializableObjectType.socialNetworkUserSettings]: ISocialNetworkSettingsServer,
    [ENonSerializableObjectType.attendanceCalendar]: IAttendanceCalendarServer,
    [ENonSerializableObjectType.graphElement]: IBasicElementServer,
    [ENonSerializableObjectType.securityControl]: IAccessRoleServer,
    [ENonSerializableObjectType.knowledgeBase]: IKnowledgeDBServer,
    [ENonSerializableObjectType.serviceIsland]: IServiceIslandServer,
    [ENonSerializableObjectType.utterance]: IUtteranceServer,
    [ENonSerializableObjectType.connection]: IConnectionServer,
    [ENonSerializableObjectType.connectionRoute]: IConnectionRouteServer,
    [ENonSerializableObjectType.deployedServices]: IServiceDeployedServices,
    [ENonSerializableObjectType.contentGenerator]: ITransactionServer,
    [ENonSerializableObjectType.attendentServicePack]: IMacroPackageServer,
    [ENonSerializableObjectType.bot]: INextGenBotServer,
    [ENonSerializableObjectType.campaing]: ICampaignServer,
    [ENonSerializableObjectType.runCampaing]: ICampaignExecutionServer,
    [ENonSerializableObjectType.fileMetadata]: IGeneralFileMetadata,
    [ENonSerializableObjectType.socialMediaConnection]: ISocialMediaConnectionServer,
    [ENonSerializableObjectType.waba]: IWhatsappBusinessAccountServer,
    [ENonSerializableObjectType.formSchemma]: SchemaPropertyServer,
    [ENonSerializableObjectType.featureDeployedGroup]: IFeatureGroupServer,
    [ENonSerializableObjectType.realTimeFusion]: IRealtimeFusionModelServer,
    [ENonSerializableObjectType.customEvents]: ICustomEventsServer,
    [ENonSerializableObjectType.activeCall1x1]: INewAgentCallContactServer,
    [ENonSerializableObjectType.activeCallBatch]: INewAgentCallBatchServer,
    [ENonSerializableObjectType.userFunction]: IUserFunctionModelServer,
    [ENonSerializableObjectType.colmeiaAPIModel]: IColmeiaAPITokenConfigServer,
    [ENonSerializableObjectType.job]: IJobServer,
    [ENonSerializableObjectType.crmHeaderCloseState]: ICloseStateConfigHeaderServer;
    [ENonSerializableObjectType.crmItemCloseState]: ICloseStateConfigItemServer;
    [ENonSerializableObjectType.crmHeaderPhase]: IPhaseConfigHeaderServer;
    [ENonSerializableObjectType.crmItemPhase]: IPhaseConfigItemServer;
    [ENonSerializableObjectType.crmHeaderSeverity]: ISeverityConfigHeaderServer;
    [ENonSerializableObjectType.crmItemSeverity]: ISeverityConfigItemServer;
    [ENonSerializableObjectType.crmHeaderState]: IStateConfigHeaderServer;
    [ENonSerializableObjectType.crmItemState]: IStateConfigItemServer;
    [ENonSerializableObjectType.crmConfigPackage]: ICRMProjectServer;
    [ENonSerializableObjectType.crmHeaderSupportLevel]: ISupportLevelConfigHeaderServer;
    [ENonSerializableObjectType.crmItemSupportLevel]: ISupportLevelConfigItemServer;
    [ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel]: ISupportUrgencyHeaderServer;
    [ENonSerializableObjectType.crmItemUrgencyLevel]: ISupportUrgencyConfigItemServer;
    [ENonSerializableObjectType.corporateSearchHeader]: ICorporateSearchHeaderServer;
    [ENonSerializableObjectType.corporateSearchBody]: ICorporateSearchBodyServer;
    [ENonSerializableObjectType.smartFlowRTFParamHeader]: IFlowInjectionHeaderServer;
    [ENonSerializableObjectType.smartFlowRTFParamBody]: IFlowInjectionBodyServer;
    [ENonSerializableObjectType.channelTemplate]: IGenericNSTemplateServer;
    [ENonSerializableObjectType.crmServiceConfigRoot]: ICRMRootServer;
    [ENonSerializableObjectType.crmServiceConfigAgentEvent]: ICRMAgentEventServer;
    [ENonSerializableObjectType.crmServiceConfigProcessingNode]: ICRMProcessingNodeEventServer;
    [ENonSerializableObjectType.crmServiceConfigNotification]: ICRMNotificationServer;
    [ENonSerializableObjectType.crmExecution]: ICRMExecBasicInterfaceTyped;
    [ENonSerializableObjectType.configurationSet]: IConfigurationSetServer,
    [ENonSerializableObjectType.bpmMarketingAcknowledge]: IBPMMarketingAcknowledgeServer,
    [ENonSerializableObjectType.bpmMarketingAction]: IBPMMarketingActionServer,
    [ENonSerializableObjectType.bpmMarketingPause]: IBPMMarketingPauseServer,
    [ENonSerializableObjectType.bpmMarketingRoot]: IBPMMarketingRootServer,
    [ENonSerializableObjectType.bpmMarketingListener]: IBPMMarketingListenerServer,
    [ENonSerializableObjectType.bpmMarketingEnd]: IBPMMarketingEndServer,
    [ENonSerializableObjectType.catalog]: INSCatalogServer,
    [ENonSerializableObjectType.yourTasks]: IYouTasksServer,
    [ENonSerializableObjectType.metaBusinessPages]: IMetaBusinessPagesServer
    [ENonSerializableObjectType.productModule]: IProductModuleServer;
    [ENonSerializableObjectType.product]: IProductServer;
    [ENonSerializableObjectType.billingContract]: IColmeiaBillingContractServer;
    [ENonSerializableObjectType.productSKU]: IProductSKUServer;
    [ENonSerializableObjectType.billingClosing]: IBillingClosingServer;
    [ENonSerializableObjectType.billingItem]: IBillingItemServer;
    [ENonSerializableObjectType.analyticsBiReportItem]: IAnalyticsBiReportItemServer;
    [ENonSerializableObjectType.contentVector]: IGenerativeVectorServer;
    [ENonSerializableObjectType.contentVectorChunk]: IGenerativeVectorChunkServer;
    [ENonSerializableObjectType.generativePrompt]: IGenerativePromptServer;
    [ENonSerializableObjectType.contactList]: IContactListServer;
    [ENonSerializableObjectType.contactListItem]: IContactListItemServer;
    [ENonSerializableObjectType.dataExtractionQuery]: IDataExtractionQueryServer;
    [ENonSerializableObjectType.wafSchema]: IWAFSchemaServer;
    [ENonSerializableObjectType.waf]: IWAFServer;
    [ENonSerializableObjectType.attCopilot]: ICopilotServer;
    [ENonSerializableObjectType.transformer]: ITransformerServer;
}>;


export type NSOrId<Type extends ENonSerializableObjectType> =
    | IdDep<Type>
    | NsTypeToInterface[Type]
;




export type TNonSerializable = ValueOf<NsTypeToInterface>;



export function isNSType<Type extends ENonSerializableObjectType>(type: Type) {
    return (ns: INonSerializable): ns is NsTypeToInterface[Type] => ns.nsType === type;
}
//

import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ICRMTicketDataWithCustomerName, TICRMTicketItems } from '@colmeia/core/src/shared-business-rules/crm/crm-entities';
import { ICRMHeaderPackageBase, ICRMHeaderState, ICRMProject, ICRMPackagesMap, TCRMAllPackagesItems, TICRMProjectArray } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config-interfaces';
import { ECRMTimeWindowField, ECRMTimeWindows, ICRMTimeWindowConfig, defaultTimeWindows } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-time-window.model';
import { ICardFilter } from '@colmeia/core/src/shared-business-rules/kanban/kanban-shared-model';
import { isValidArray, isValidRef, uniqBy } from '@colmeia/core/src/tools/barrel-tools';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';
import { CRMTicketsService } from 'app/crm-service-tickets-view/crm-tickets.service';
import { DatePickerHandler, EDatePickerMode, EDatePickerType } from 'app/handlers/date-picker.handler';
import { EPageSizeOption } from 'app/handlers/generic-table/generic-table.handler';
import { SubscriptionGroup } from 'app/model/client-utility';
import { DatetimeConversions } from 'app/services/datetime-conversions';
import { EKanbanStandardCardSort } from 'app/services/kanban/kanban-filter-n-sort';
import { KanbanService } from 'app/services/kanban/kanban.base-service';
import { UserSettingsService } from 'app/services/user-settings.service';
import { values } from 'lodash';
import { ITicketKanbanCard, ITicketKanbanCardData, ITicketKanbanColumnData } from './crm-kanban-view/crm-kanban-shared.model';
import { ELocalCustomFilters } from './crm-ticket-client.types';
import { CRMTicketsDialogService } from './crm-tickets-dialog.service';
import { ILocalGroupFilter } from './crm-tickets-view-wrapper.component';
import { ILocalFilterConfig } from './crm-user-config/crm-user-config.model';
import { CRMUserConfigService } from './crm-user-config/crm-user-config.service';
import { MatSelectChange } from '@angular/material/select';

interface IErrorState {
    message: string;
}

@Component({
    selector: 'app-crm-service-tickets-view',
    templateUrl: './crm-service-tickets-view.component.html',
    styleUrls: ['./crm-service-tickets-view.component.scss'],
    host: {
        '[class.windowed]': 'isWindow',
        '[class.loading]': 'initialLoading',
        '[class.loading-tickets]': 'isLoading'
    },
})
export class CrmServiceTicketsViewComponent implements OnInit, OnDestroy {
    ticketItems: TICRMTicketItems;
    public initialLoading: boolean = true;
    public isLoading: boolean = true;
    public noProjects: boolean = false;
    public noTickets: boolean = false;
    public isWindow: boolean = false;
    public fetchingTicket: boolean = false;
    public currentView: 'kanban' | 'list' = 'kanban';
    currentPageIndex: number = 0;
    pagination = '10';
    paginationOptions = ['10', '30', '50', '100'];
    public crmPackages: TICRMProjectArray = [];
    public selectedPackage: ICRMProject;
    public cardFilters: ICardFilter[] = [];
    public crmHeaders: ICRMHeaderPackageBase[] = [];
    public selectedHeader: ICRMHeaderPackageBase;
    public allFilterGroups: ILocalGroupFilter[] = [];
    public currentFilterGroups: ILocalGroupFilter[] = [];
    private itemsLengthMap: Record<string, number> = {};
    public errorState?: IErrorState;
    private subscriptions = new SubscriptionGroup();
    public selectedTimeWindow!: ICRMTimeWindowConfig;


    constructor(
        private crmTicketsService: CRMTicketsService,
        private ticketDialogSvc: CRMTicketsDialogService,
        private kanbanSvc: KanbanService<ITicketKanbanCardData, ITicketKanbanColumnData>,
        private crmUserConfigSvc: CRMUserConfigService,
        @Optional()
        private windowRef: ColmeiaWindowRef<CrmServiceTicketsViewComponent>,
    ) {
        if (isValidRef(this.windowRef)) {
            this.isWindow = true;
        }

    }

    async ngOnInit() {
        this.initialLoading = true;
        await this.init();
        this.initialLoading = false;

        this.subscriptions.from(this.crmTicketsService.$ticketAdded).subscribe(() => {
            this.onTicketAdded();
        });

        this.subscriptions.from(this.crmTicketsService.$ticketUpdate).subscribe(() => {
            this.onTicketAdded();
        });

        this.selectedTimeWindow = this.crmUserConfigSvc.getTimeWindowConfig();
    }

    public async setTimeWindowConfig(timeWindow: ICRMTimeWindowConfig) {
        if (this.fetchingTicket) return;

        this.crmUserConfigSvc.setTimeWindwConfig(timeWindow);

        this.init();
    }

    ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    private onTicketAdded() {
        this.setPackages(this.crmTicketsService.packages);
        this.setTickets(this.crmTicketsService.tickets);

        this.kanbanSvc.loadColumnsAndCards(
            this.factoryColumns(),
            this.factoryCards(),
        );

        this.kanbanSvc.addCardsSort(EKanbanStandardCardSort.lastUpdate);
        // this.kanbanSvc.addCardsSort('userSortChoice');
        this.kanbanSvc.addCardsFilter(ELocalCustomFilters.cardsFilter);

    }

    hasOpenWindow(): boolean {
        return !!this.ticketDialogSvc.hasOpenWindow()
    }

    onClickOpenWindowed(event: PointerEvent) {
        event.stopPropagation();
        this.ticketDialogSvc.openOrRestore(this.kanbanSvc);
    }

    private factoryColumns(): ITicketKanbanColumnData[] {

        return this.crmPackages
            .map(crmPackage => {
                const columnsData: ITicketKanbanColumnData[] = [];

                Object.keys(crmPackage.packages).forEach((key: keyof ICRMPackagesMap) => {
                    columnsData.push(
                        ...this.factoryColumnsForItem(crmPackage, crmPackage.packages[key].items)
                    );
                });

                columnsData.sort((a, b) => a.data.sortIndex - b.data.sortIndex)

                return uniqBy(columnsData, 'columnId');
            }).flat()
    }

    private factoryColumnsForItem(crmPackage: ICRMProject, items: TCRMAllPackagesItems[]): ITicketKanbanColumnData[] {
        return items.map(item => {
            return {
                data: item,
                boardId: crmPackage.packageId,
                columnId: item.itemId,
                title: item.name
            };
        })
    }

    async init() {
        this.isLoading = true;
        const { packages, tickets } = await this.crmTicketsService.getAllTicketsAndPackages({ timeWindow: this.crmUserConfigSvc.getTimeWindowConfig() });

        this.setPackages(packages);
        this.setTickets(tickets);

        this.kanbanSvc.registryCustomCardFilter({
            filterId: ELocalCustomFilters.packageFilter,
            label: ELocalCustomFilters.packageFilter,
            filterFn: (card: ITicketKanbanCard) => {
                const ticketData = card.getData();

                return ticketData.ticketPackage.packageId === this.selectedPackage.packageId;
            }
        });

        this.kanbanSvc.setGlobalFilterColumns((column) => {
            return this.selectedHeader?.items.some(i => i.itemId === column.getData().data.itemId)
        });

        this.buildPackagesFilters();
        this.setSelectedPackage(this.crmPackages[0]);

        this.kanbanSvc.loadColumnsAndCards(
            this.factoryColumns(),
            this.factoryCards(),
        );

        this.kanbanSvc.addCardsSort(EKanbanStandardCardSort.lastUpdate);
        // this.kanbanSvc.addCardsSort('userSortChoice');
        this.kanbanSvc.addCardsFilter(ELocalCustomFilters.cardsFilter);

        this.isLoading = false;
        this.refreshWindowTitle();
    }

    private setPackages(packages: TICRMProjectArray) {
        this.crmPackages = isValidArray(packages) ? packages : this.crmPackages;
        this.noProjects = !this.crmPackages?.length;

    }

    private setTickets(tickets: TICRMTicketItems) {
        this.ticketItems = tickets;
        this.noTickets = !tickets?.length;
    }

    private factoryCards() {
        return this.ticketItems.map(ticket => {
            return {
                cardId: ticket.idTicket,
                createdAt: ticket.clockTick,
                updatedAt: ticket.lastClockTick || ticket.clockTick,
                ticketPackage: this.crmPackages.find(pkg => pkg.packageId === ticket.idPackage),
                ticketData: ticket,
            }
        });
    }

    private buildPackagesFilters() {
        this.allFilterGroups = [];

        for (const pkg of this.crmPackages) {

            for (const itemPkgId in pkg.packages) {
                const itemPkg: ICRMHeaderPackageBase = pkg.packages[itemPkgId];
                if (!itemPkg.headerPackageId) continue;

                const localFilter: ILocalGroupFilter = {
                    packageId: pkg.packageId,
                    itemPackageId: itemPkg.headerPackageId,

                    label: itemPkg.name,
                    filters: []
                }

                this.allFilterGroups.push(localFilter);

                itemPkg.items.forEach(item => {
                    const filter: ILocalFilterConfig = {
                        statusId: item.itemId,
                        label: item.name,
                    };

                    localFilter.filters.push(filter);
                });
            }
        }


        this.kanbanSvc.registryCustomCardFilter({
            filterId: ELocalCustomFilters.cardsFilter,
            label: ELocalCustomFilters.cardsFilter,
            filterFn: this.crmUserConfigSvc.cardFilterFn
        });


        this.kanbanSvc.registryCustomCardSort({
            sortId: 'userSortChoice',
            label: 'userSortChoice',
            sortFn: this.crmUserConfigSvc.cardSortFn,
        })

    }

    private setSelectedPackage(selectedPackage: ICRMProject) {
        const currentPkg = this.selectedPackage;
        this.selectedPackage = selectedPackage;

        if (!this.selectedPackage) {
            this.noProjects = true;
            return
        }

        this.crmUserConfigSvc.setSelectedPackageId(selectedPackage.packageId);

        this.crmHeaders = values(selectedPackage.packages) as ICRMHeaderState[];

        this.createMapCount();

        this.selectedHeader = selectedPackage.packages.ticketState;
        this.crmUserConfigSvc.setSelectedHeader(this.selectedHeader.headerPackageId);

        const activeFilters = this.kanbanSvc.getActiveCustomCardFilterList();

        for (const filter of activeFilters) {
            this.kanbanSvc.removeCardsFilter(filter.filterId);
        }

        this.currentFilterGroups = this.allFilterGroups.filter(filterGroup => {
            return filterGroup.packageId === selectedPackage.packageId
        })

        this.kanbanSvc.addCardsFilter(ELocalCustomFilters.packageFilter);
        this.kanbanSvc.updateColumnsListUI();
        this.kanbanSvc.updateColumnsUI();
    }

    private createMapCount() {
        this.itemsLengthMap = {};

        for (const ticket of this.ticketItems) {
            this.incrementMapCount(ticket.idPackage);
            this.incrementMapCount(this.getHeaderIdByItem(ticket, ticket.currentStatus.idCloseState));
            this.incrementMapCount(this.getHeaderIdByItem(ticket, ticket.currentStatus.idPhase));
            this.incrementMapCount(this.getHeaderIdByItem(ticket, ticket.currentStatus.idSeverity));
            this.incrementMapCount(this.getHeaderIdByItem(ticket, ticket.currentStatus.idSupportLevel));
            this.incrementMapCount(this.getHeaderIdByItem(ticket, ticket.currentStatus.idTicketState));
            this.incrementMapCount(this.getHeaderIdByItem(ticket, ticket.currentStatus.idUrgencyLevel));
        }
    }

    private getHeaderIdByItem(ticket: ICRMTicketDataWithCustomerName, itemId: string): string {
        return this.crmHeaders.find(ch => ticket.idPackage === this.selectedPackage.packageId && ch.items.some(i => i.itemId === itemId))?.headerPackageId
    }

    private incrementMapCount(key: string) {
        if (!key) return;

        this.itemsLengthMap[key] = (this.itemsLengthMap[key] || 0) + 1
    }

    getTicketsLength(key: string) {
        return this.itemsLengthMap[key];
    }

    refreshTickets(resetPage = true): void {
        // if (resetPage) {
        //     this.currentPageIndex = 0;
        // }

        // const ticketItems = [...this.ticketItems];

        // // this.filteredTicketItems = this.filterAgents();

        // this.filteredTicketItems = chain(ticketItems)
        //     .chunk(parseInt(this.pagination))
        //     .get(this.currentPageIndex)
        //     .defaultTo([])
        //     .value()
        //     ;
    }

    onChangePagination(option: PageEvent): void {
        const pageSize = String(option.pageSize) as EPageSizeOption;

        if (option.pageIndex !== this.currentPageIndex) {
            this.currentPageIndex = option.pageIndex;
        }


        if (this.pagination !== pageSize) {
            this.pagination = pageSize;
        }

        this.refreshTickets(false);
    }

    handlePackageChange(event: MatSelectChange) {
        const packageId = event.value;

        this.setSelectedPackage(this.findPackage(packageId));
        this.refreshWindowTitle();
    }

    handleItemPackageChange(event: MatSelectChange) {

        this.selectedHeader = event.value;
        this.crmUserConfigSvc.setSelectedHeader(this.selectedHeader.headerPackageId);
        this.kanbanSvc.updateColumnsListUI();

        this.refreshWindowTitle();
    }

    findPackage(idPackage: string) {
        return this.crmPackages.find(pkg => pkg.packageId === idPackage);
    }

    public getActiveFiltersLength(): number {
        return this.crmUserConfigSvc.getActiveFiltersLength();
    }

    public getActiveFiltersLengthForGroup(filterGroup: ILocalGroupFilter): number {
        return this.crmUserConfigSvc.getActiveFiltersLengthForGroup(filterGroup.itemPackageId);
    }

    public isActiveFilter(itemPackageId: string, statusId: string) {
        return this.crmUserConfigSvc.isActiveFilter(itemPackageId, statusId);
    }

    handleFilterClick(filterGroup: ILocalGroupFilter, filter: ILocalFilterConfig, event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.crmUserConfigSvc.toggleItemFilterActive(filterGroup.itemPackageId, filter.statusId);
        this.kanbanSvc.updateColumnsUI();
    }

    getWindowTitle(): string {
        if (!this.selectedPackage || !this.selectedHeader) {
            return "Tickets";
        }

        const packageName = this.selectedPackage.name;
        const headerName = this.selectedHeader.name;

        return `${packageName} — ${headerName}`;
    }

    private refreshWindowTitle(): void {
        if (!this.isWindow) return;

        this.windowRef.title = this.getWindowTitle();
    }

    public resetFilters() {
        this.crmUserConfigSvc.resetFilters();
        this.kanbanSvc.updateColumnsUI();
    }

}

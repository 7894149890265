import { deprecatedSuggestions } from "../../../tools/type-utils";
import { typedFields, typedScreenOptions } from "../../../tools/utility";
import { KBAssetType, KBAssetTypeClientOnly } from "../../bot/bot-asset-model";
import { EEvalulatorType } from "../../bot/bot-interfaces";
import { ENextGenBotElementType } from "../../bot/bot-model";
import { EBotActionType } from "../../bot/new-bot-action";
import { EBPMAction, ELGPDActionStyle } from "../../BPM/bpm-action-model";
import { EActionOnComplete } from "../../knowledge-base/bot-transaction/bot-transaction";
import { EMetadataEngagementType, EMetaEngagementConditionType } from "../../metadata/meta-engagement";
import { ESmartField } from "../../smart/smart-fields";
import { EAllSerializable, defineTranslations, getTranslationsFields } from "../all-serializables";
import { botEventEnum } from "../enums";

export const botViewScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.BotView1,
    fields: {
        [ENextGenBotElementType.formTransaction]: 1, //Formulário
        [ENextGenBotElementType.nlpTransaction]: 2, // NLP
        actionOnComplete: 3, // Ação ao completar 
        [EActionOnComplete.return]: 4, // Retornar
        [EActionOnComplete.keepUntilCommand]: 5, //Continuar até receber comando
        [KBAssetType.media]: 6, // Arquivo       
        [KBAssetType.content]: 7, // Conteúdo 
        [EBotActionType.contentGenerator]: 8, // Gerador de conteúdo
        [EBotActionType.goBot]: 9, // Bot    
        [KBAssetType.contentAPICall]: 10, // Chamada de API
        events: 11, // Eventos             
        event: 12, // Evento              
        selectPreConditionItem: 13, // Selecione o item
        addEvent: 14, // Adicionar Evento
        takeActionAfter: 15, // Tomar ação após
        minutes: 16, // Minutos      
        attemptsNumber: 17, // Número de tentativas
        command: 18, // Comando
        selectActionText: 19, // Texto para ação escolhida
        preCondition: 20, // Pré Condição:
        [EEvalulatorType.checkAcceceTree]: 21, // Ter passado por um item
        [EEvalulatorType.checkIfAuth]: 22, // Estar autenticado
        [KBAssetTypeClientOnly.sticker]: 23, // Figurinha
        sendExpireMessagesInOrder: 24, // Enviar mensagem diferente a cada intervalo seguindo a ordem
        [KBAssetType.extractEntity]: 25,
    }
} as const);

export const botView2ScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.BotView2,
    fields: {
        bots: 1, // Bots
        createBot: 2, // Criar bot
        incrementBy: 3, // Incrementar por
        letters: 4, // letras
        numbers: 5, // números
        primaryBot: 6, // bot primário
        configAction: 7, // Configurar Ação
        subject: 8, // Assunto
        optionAction: 9, // Qual ação desta opção?
        noBots: 10, // Não há bots para mostrar
        waitSeconds: 11, // Segundos de espera
        selectMetadata: 12, // Selecione um metadado:
        selectRoot: 13, // Selecione o Bot:
        selectNode: 14, // Selecione a Questão:
        loadError: 15, // Erro ao carregar os bots
        timeToReuseInformation: 16, // botNode 19
        knowledgeBaseAssetsName: 17, // knowledgeBase/assets 1
        knowledgeBase: 18,// SNManagement/knowledgeBase 1   
        greetingText: 19,// BotTransaction 42
        exitCommand: 20,// BotTransaction 41
        transaction: 21, // assetAdder 20
        botLevel: 22, // Nível do bot
    }
} as const);

export const generationCoordinatorScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.GenerationCoordinator,
    fields: {
        title: 1,// GenerationCoordinator 30
        ignoreAccuracyUntil: 2, // GenerationCoordinator 33 // Limite de porcentagem para acurácia insuficiente 
        ignoredMessage: 3, // GenerationCoordinator 34 16
        confirmAccuracyUntil: 4, // GenerationCoordinator 31 // Limite de porcentagem para acurácia inconclusiva 
        confirmMessage: 5, // GenerationCoordinator 32
    }
})

export const botTransactionScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.BotTransaction,
    fields: {
        availableInfoText: 1, // Colmeia Forms
        missingText: 2, // Texto para informações que faltam
        doYouConfirm: 3, // Texto de confirmação
        basicDataTitle: 5, // Dados básicos
        errorLoadingTransactions: 6, // Não foi possível carregar o gerador de conteúdo
        noTransactions: 7, //  Não há gerador de conteúdo
        confirmData: 8, //  Continuar até receber comando
        createTitle: 9, // Criar gerador de conteúdo
        editTitle: 10, // Editar gerador de conteúdo
        entityMap: 11, // Mapeamento de Entidades
        connection: 12, // Conexão
        submitOnComplete: 13, // Submeter ao completar
        lowPriority: 18, // low_priority
    }
});


const botActionTypeScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.BotActionView,
    fields: deprecatedSuggestions<{ [key in EBotActionType]: number }>()({
        [EBotActionType.goHuman]: 1, // Encaminha atendente
        [EBotActionType.isEnd]: 2, // fim do bot
        [EBotActionType.goBack]: 3, // Volta uma questão
        [EBotActionType.goFirstTree]: 4, // volta para primeira questao
        [EBotActionType.goActionTree]: 5, // vai para próxima pergunta
        [EBotActionType.goBot]: 6, // Vai para root de um bot
        [EBotActionType.continueFlow]: 7, // Continuar fluxo
        [EBotActionType.exitCommand]: 8, // Comando de saída
        [EBotActionType.yesOrNoAction]: 9, // Ação de sim ou não
        [EBotActionType.contentGenerator]: 10, // Vai para o gerador de conteúdo
        [EBotActionType.goIsland]: 11, // Vai para Ilha de Atendimento
        [EBotActionType.yesAnswer]: 12, // Sim
        [EBotActionType.noAnswer]: 13, // Não
        [EBotActionType.redoAnswer]: 14, // Refazer a pergunta
        [EBotActionType.fillForm]: 15, // Preencher formulário
        [EBotActionType.reuseForm]: 16, // Reusar formulário
        [EBotActionType.LGPD]: 17,
        [EBotActionType.quickContinue]: 18, // Fluxo padrão
        [EBotActionType.quickCallPhone]: 19, // Ligar
        [EBotActionType.quickVisitWebsite]: 20, // Visitar site
        [EBotActionType.goIslandOnTemplate]: 21, // Ir para a ilha de um item de menu
        [EBotActionType.gotoProperty]: 22, // Voltar para um campo
        [EBotActionType.otpCopyCode]: 23, // Copiar Código
        [EBotActionType.upsertTicket]: 24, // Atualizar ticket
    }).ok,
} as const);

export const botEngagementTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.BotEngagement,
    fields: {
        // [EMetadataEngagementType.conditionalForm]: 1, // Condicional
        [EMetadataEngagementType.entityMapper]: 2, // Mapear Entidade
        [EMetadataEngagementType.richFillForm]: 3, // Mensagens Avançadas
        [EMetadataEngagementType.smartMapper]: 4, // Atalhos Inteligentes
        // [EMetadataEngagementType.validator]: 5, // Validação
        [EMetaEngagementConditionType.greater]: 6, // Maior que
        [EMetaEngagementConditionType.interval]: 7, // Intervalo
        [EMetaEngagementConditionType.literal]: 8, // Literal
        [EMetaEngagementConditionType.regex]: 9, // Regex
        [EMetaEngagementConditionType.smaller]: 10, // Menor que
        [EMetadataEngagementType.CRMTransformation]: 11, // Tranformação CRM,
        [EMetaEngagementConditionType.list]: 12, // Lista
        [EMetadataEngagementType.NewCondition]: 1, // Novo sistema de condições (substituir dps)
        [EBPMAction.validator]: 14, // Validação
        // [EBPMAction.content]: 15, // Conteúdo
        [EBPMAction.action]: 16, // Ação do bot,
        [EMetadataEngagementType.realTimeFusion]: 17, // Fusão entre Colmeia e Sistemas.
        [EBPMAction.conditionalDisplay]: 18, // Exibição condicional
        [EMetadataEngagementType.integrationByChannel]: 19, // Integração por canal
        [EMetaEngagementConditionType.contains]: 20, // Contém
        [ESmartField.LGPD]: 21, // Lei Geral de Proteção de Dados Pessoais
        [EBPMAction.anomization]: 22, // Anonimização
        [EBPMAction.getTitularData]: 23, // Dados do titular
        [EBPMAction.optOut]: 24, // Opt Out
        [EBPMAction.optIn]: 26, // Opt In
        [EMetaEngagementConditionType.userFunction]: 27, // funcoes,
        [EMetaEngagementConditionType.metaNull]: 28, // É nulo
        [EMetaEngagementConditionType.notNull]: 29, // Não nulo
        [EBPMAction.mktFilter]: 30, // Filtro MKT
        [ESmartField.Address]: 32, // Smart Address
        [ESmartField.QRCode]: 33, // Smart QrCode
        [ESmartField.TwoFactorValidation]: 34, // Autenticação de dois fatores
        [ESmartField.NPS]: 35, // Net Promoter Score
        [ESmartField.DynamicMenu]: 36, // Busca corporativa
        [ESmartField.RTFValidator]: 37, // Validação RTF
        [ESmartField.FieldReuse]: 38, // Reuso de Campo
        [ESmartField.Multimedia]: 39, // Multimídia
        [EMetadataEngagementType.mktEvents]: 40, // Eventos de marketing
        [EMetadataEngagementType.integration]: 41, // Integração
        [EMetadataEngagementType.layout]: 42, // Layout
        [ESmartField.smartConfirmation]: 44, // Confirmação inteligente
        [EMetaEngagementConditionType.isChannel]: 45, // Canal
        [EMetaEngagementConditionType.isAllwaysTrue]: 46, // Sempre verdadeiro
        [EMetaEngagementConditionType.dateValidation]: 47, // Validação de data
        [EBPMAction.solicitation]: 48, // Adicionar caso
        [EMetadataEngagementType.solicitation]: 49, // Pendência
        [EMetadataEngagementType.whatsAppFlow]: 50, // whatsAppFlow
        // [ESmartField.CRM]: 51, // CRM
    }
} as const));

export const botEngagementTranslations2 = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.botEngagement2,
    fields: {
        [ESmartField.CRM]: 1, // CRM
        [ESmartField.docReader]: 2, // CRM
    },
} as const));

const lgpdActionStyleScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.LGPDActionStyle,
    fields: deprecatedSuggestions<{ [key in ELGPDActionStyle]: number }>()({
        [ELGPDActionStyle.actNow]: 1, // Proceder Imediatmente
        [ELGPDActionStyle.dwOnly]: 2, // Apenas Registrar no DW
        [ELGPDActionStyle.anonimizationAPI]: 3, // Chamada de API
    }).ok
});

const kbAssetTypeTranslations = defineTranslations({
    idSerializable: EAllSerializable.KBAssetTypeTranslations,
    fields: typedFields<KBAssetType, { IsLimitingFields: true }>()({
        [KBAssetType.media]: 1, 
        [KBAssetType.content]: 2, 
        [KBAssetType.contentAPICall]: 3, 
        [KBAssetType.generativo]: 4, 
        [KBAssetType.sendTemplate]: 5,
        [KBAssetType.extractEntity]: 7, 
        [KBAssetType.sendCTA]: 6, // Enviar URL interativa
    }),
});

const botTranslations = getTranslationsFields(botViewScreenOptions);
const botTranslations2 = getTranslationsFields(botView2ScreenOptions);
const generationTranslations = getTranslationsFields(generationCoordinatorScreenOptions);
const botTransaction = getTranslationsFields(botTransactionScreenOptions);
export const botActionTypeTranslations = getTranslationsFields(botActionTypeScreenOptions);
export const allBotTranslations = {
    ...botTranslations,
    ...botTranslations2,
    ...generationTranslations,
    ...botTransaction,
    ...botActionTypeTranslations,
    ...getTranslationsFields(botEventEnum),
    ...botEngagementTranslations,
    ...botEngagementTranslations2,
    ...getTranslationsFields(lgpdActionStyleScreenOptions),
    ...kbAssetTypeTranslations,
};

export const botEnumTranslations = {
    ...getTranslationsFields(botEventEnum)
}


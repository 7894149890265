import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { TServiceIslandArray } from "../../attendance-island/attendance-island";
import { ICRMCTicketStatusInteractionInsert, ICRMTicketDataWithCustomerName, TICRMTicketDataBasicArray, TICRMTicketItems, TTicketInteracitonArray } from "../crm-entities";
import { TICRMProjectArray } from "./crm-config-interfaces";
import { IGetAttendanceTicketsParams, TICRMTicketOrdination, TTicketStatusFilters } from "./crm-shared-types";
import { ICRMTimeWindowConfig } from "./crm-time-window.model";


export enum ECRMTicketRuntimeRequests {
    getAgentTickets = 'crm.getAllTickets',
    getAllTicketInteractions = 'crm.getAllTicketInteractions',
    getAgentIslands = 'crm.getAgentIslands',
    getAvatarPackages = 'crm.getAvatarPackages',
    insertTicketInteraction = 'crm.insertTicketInteraction',
    getAttendanceTickets = 'crm.getAttendanceTickets',
    getTicketData = 'crm.getTicketData',
    getPackages = 'crm.getPackages',
}

export interface ICRMGetAgentTicketsRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.getAgentTickets;
    timeWindow: ICRMTimeWindowConfig;
    fields?: {},
    filters: TTicketStatusFilters;
    order: TICRMTicketOrdination;
}


export interface ICRMGetAgentTicketsResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getAgentTickets;
    tickets: TICRMTicketItems;
    packages: TICRMProjectArray;
}

export interface ICRMGetAllTicketInteractionsRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.getAllTicketInteractions;
    idTicket: string;
}


export interface ICRMGetAllTicketInteractionsResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getAllTicketInteractions;
    ticketInteractions: TTicketInteracitonArray;
}


export interface ICRMGetAgentIslandsRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.getAgentIslands;
}

export interface ICRMGetAgentIslandsResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getAgentIslands;
    islands: TServiceIslandArray;
}

export interface ICRMGetAvatarPackagesRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.getAvatarPackages;
}

export interface ICRMGetAvatarPackagesResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getAvatarPackages;
    packages: TICRMProjectArray;
}


export interface ICRMInsertTicketInteractionRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.insertTicketInteraction;
    ticketId: TGlobalUID;
    statusInteraction?: ICRMCTicketStatusInteractionInsert;
    content?: {
        comment?: string;
        idMedias?: string[];
    }
    answer?: { idAnswer: TGlobalUID };
}

export interface ICRMInsertTicketInteractionResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.insertTicketInteraction;
}




export interface ICRMTicketGetAttendanceTicketsRequest extends IRequest, IGetAttendanceTicketsParams {
    requestType: ECRMTicketRuntimeRequests.getAttendanceTickets;
}


export interface ICRMTicketGetAttendanceTicketsResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getAttendanceTickets;
    tickets: TICRMTicketDataBasicArray;
}





export interface ICRMGetTicketDataRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.getTicketData;
    idTicket: TGlobalUID;
}


export interface ICRMGetTicketDataResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getTicketData;
    ticket: ICRMTicketDataWithCustomerName;
}

export interface ICRMGetPackagesRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.getPackages;
    idPackages: TGlobalUID[]
}


export interface ICRMGetPackagesResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getPackages;
    packages: TICRMProjectArray;
}

import { IBotElementWithRenderOptions, INaviTracker, ISmartAddressDynamicMenuRenderOptions } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { Miliseconds } from "@colmeia/core/src/time/time-utl";
import { values } from "lodash";
import { TArrayID } from "../../core-constants/types";
import { EIdMultimediaType } from "../../multi-media/multimedia-constant";
import { EValidFormatFinal, IDateInput } from "../../tools/date/date-utils.types";
import { getUniqueStringID, typedClone } from "../../tools/utility";
import { Explicit, IntersectPartialExplicit } from "../../tools/utility/types";
import { IBPMValidatorAction, IBPMValidatorActionOnError } from "../BPM/bpm-action-model";
import { TIBPConditionalEvaluatorArray } from "../BPM/bpm-model";
import { ERealTimeFusionType } from "../attendance-island/real-time-fusion-model";
import { IBotActionAsset, TBotActionArray } from '../bot/bot-action-model';
import { TContentAssetArray, TSingleContentAsset } from "../bot/bot-content-model";
import { EBotActionType } from "../bot/new-bot-action";
import { EConnectionType } from "../connections/endpoint-model";
import { ECSContentComparison, ECSFormatField } from "../corporate-search/corporate-search-model";
import { TICRMTicketOrdination } from "../crm/crm-services/crm-shared-types";
import { ENonSerializableObjectType, IdTransformer } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import { ESmartField } from "../smart/smart-fields";
import { I2FASettings } from "../two-factor-validation/two-factor-model";
import { TIEngagementFlowConfigurationArray, TIFlowAreaArray } from "../waf/waf.types";
import { IEventModelMKTConfig } from "./engag-mkt-event-model";
import { ENaturalCanonicals } from "./metadata-db";
import { ICRMTimeWindowConfig } from "../crm/crm-services/crm-time-window.model";
import { EDOCSegment } from "../doc-reader-v2/field-extractors/shared-types";

// INFO: esse e o modificador do colmeia form
export enum EMetadataEngagementType {
    // conditionalForm = 'cf',
    entityMapper = 'em',
    smartMapper = 'sm',
    // validator = 'vl',
    // -----------------------------------
    // mensagens avancadas
    richFillForm = 'rff',
    CRMTransformation = 'crmtrns',
    NewCondition = 'con',
    realTimeFusion = 'rtf',
    integrationByChannel = 'ibc',
    mktEvents = 'mktEvt',
    integration = 'integration',
    layout = 'layout',
    solicitation = 'metaSolic',
    whatsAppFlow = 'whatsAppFlow'
    // -----------------------------------
};

export const basicConfigurableEngagementType: EMetadataEngagementType[] = []


export type TIEngagementConfigArray = Array<IEngagementConfig>;

// INFO(MB): Engagement Config is the configuration of flow type when entities will be mapped
export interface IEngagementConfig { // @@Isso será importante.. por enquanto apenas deixar.
    engagementType: EMetadataEngagementType;
};

export interface IEngagementConfigPosCondition extends IConditionalValuatorContainer, IEngagementConfig {
    engagementType: EMetadataEngagementType.NewCondition;
}

export interface IEngagementConfigSolicitation extends IConditionalValuatorContainer, IEngagementConfig {
    engagementType: EMetadataEngagementType.solicitation;
}

export interface IEngagementConfigPosForm extends IEngagementConfig {
    engagementType: EMetadataEngagementType.integration;
    idUserFunction: IdDep<ENonSerializableObjectType.userFunction>;
}

export interface IEngagementAdditionalOptionsConfig {
    allowAdditionalOptions?: {
        enabled: boolean;
        allowedBotActions: readonly EBotActionType[];
    };
}

const defaultAdditionalOptionsConfig: IEngagementAdditionalOptionsConfig = {
    allowAdditionalOptions: {
        enabled: true,
        allowedBotActions: [
            EBotActionType.goActionTree,
            EBotActionType.contentGenerator,
            EBotActionType.goBack,
            EBotActionType.goBot,
            EBotActionType.goFirstTree,
            EBotActionType.goHuman,
            EBotActionType.isEnd,
            EBotActionType.gotoProperty,
        ]
    }
}

export interface IEngagementConfigDBItem extends IEngagementAdditionalOptionsConfig {
    maxItems?: number;
    onManyFieldIdentifier?: string;
};

export type TEngagementConfigDB = Record<EMetadataEngagementType, IEngagementConfigDBItem>;


export enum EMetaEngagementConfigContext {
    AgentRegistry = 'AgentRegistry',
    ColmeiaForm = 'ColmeiaForm',
    ConnectionRoute = 'ConnectionRoute',
    CrmProjectDimensionForm = 'CrmProjectDimensionForm',
}

export enum ESourceOfInfo {
    canonical = 'cnon',
    property = 'prop',
    mkt = 'mkt',
    crm = 'crm',
    bot = 'eBot',
    serviceScheduler = 'cSched',
    metrics = 'mtrcs',
    campaignAction = 'campaignAction',
    botTracker = 'btTrck',
    generativeChunks = 'chunks',
    transformer = 'trnsfrmr'
}

export interface ISmartFieldConfig extends IEngagementAdditionalOptionsConfig { // @@
    showFieldReturnOnError?: boolean;
    hasPreContent?: boolean;
    hasPosContent?: boolean;
    hasMenuVirtualTitleConfig?: boolean;
    preContentAdditionalConditionSourceType?: ESourceOfInfo[];
    posContentAdditionalConditionSourceType?: ESourceOfInfo[];

    contextConfig?: { [key in EMetaEngagementConfigContext]?: ISmartFieldConfig }
};

export type TSmartFieldConfigDB = Record<ESmartField, ISmartFieldConfig>;


export function emptyAsset(): IBotActionAsset {
    return {
        idAsset: getUniqueStringID(),
        type: null,
        idElement: null,
        contentArray: []
    };
}

export function emptyFinishEvent(): IConditionFinishEvent {
    return {
        onError: {
            execute: emptyAsset()
        },
        onSuccess: {
            execute: emptyAsset()
        }
    }
};

const emptyEngagements: { [key in EMetadataEngagementType]: IEngagementConfig & Explicit<IEngagementConfig, { engagementType: key }> } = {
    [EMetadataEngagementType.entityMapper]: {
        engagementType: EMetadataEngagementType.entityMapper,
    },
    [EMetadataEngagementType.smartMapper]: {
        engagementType: EMetadataEngagementType.smartMapper,
    },
    [EMetadataEngagementType.richFillForm]: {
        engagementType: EMetadataEngagementType.richFillForm,
    },
    [EMetadataEngagementType.CRMTransformation]: <ICRMTransformationConfig>{
        engagementType: EMetadataEngagementType.CRMTransformation,
        recordType: "",
    },
    [EMetadataEngagementType.integrationByChannel]: <ISendToChannelByAgentEngagementConfig>{
        engagementType: EMetadataEngagementType.integrationByChannel,
        idChannel: undefined,
        idTemplate: undefined,
        toTargetArray: [],
        contentArray: [],
    },
    [EMetadataEngagementType.NewCondition]: {
        engagementType: EMetadataEngagementType.NewCondition,
    },
    [EMetadataEngagementType.realTimeFusion]: {
        engagementType: EMetadataEngagementType.realTimeFusion,
    },
    [EMetadataEngagementType.mktEvents]: <IEventModelMKTConfig>{
        engagementType: EMetadataEngagementType.mktEvents,
        eventMktConfigList: []
    },
    [EMetadataEngagementType.integration]: {
        engagementType: EMetadataEngagementType.integration,
    },
    [EMetadataEngagementType.layout]: {
        engagementType: EMetadataEngagementType.layout,
    },
    [EMetadataEngagementType.solicitation]: {
        engagementType: EMetadataEngagementType.solicitation,
    },
    [EMetadataEngagementType.whatsAppFlow]: <IWAFEngagementConfig>{
        engagementType: EMetadataEngagementType.whatsAppFlow,
        flowConfigurations: []
    }
};

const engagementDefaultConfig: IEngagementConfigDBItem = {
    maxItems: 1
}

export const engagementConfigDB: TEngagementConfigDB = {
    [EMetadataEngagementType.entityMapper]: engagementDefaultConfig,
    [EMetadataEngagementType.smartMapper]: {
        maxItems: 2,
        onManyFieldIdentifier: "smart",
        allowAdditionalOptions: {
            enabled: true,
            allowedBotActions: []
        }
    },
    [EMetadataEngagementType.richFillForm]: {
        maxItems: 1,
        ...defaultAdditionalOptionsConfig
    },
    [EMetadataEngagementType.CRMTransformation]: {
        maxItems: 2,
        onManyFieldIdentifier: "fieldName"
    },
    [EMetadataEngagementType.integrationByChannel]: engagementDefaultConfig,
    [EMetadataEngagementType.NewCondition]: engagementDefaultConfig,
    [EMetadataEngagementType.realTimeFusion]: engagementDefaultConfig,
    [EMetadataEngagementType.mktEvents]: engagementDefaultConfig,
    [EMetadataEngagementType.integration]: engagementDefaultConfig,
    [EMetadataEngagementType.layout]: engagementDefaultConfig,
    [EMetadataEngagementType.solicitation]: engagementDefaultConfig,
    [EMetadataEngagementType.whatsAppFlow]: {
        maxItems: undefined,
    },
} as const;

export const smartFieldConfigDB: TSmartFieldConfigDB = {
    [ESmartField.Address]: {
        ...defaultAdditionalOptionsConfig
    },
    [ESmartField.QRCode]: {},
    [ESmartField.TwoFactorValidation]: {},
    [ESmartField.NPS]: {},
    [ESmartField.LGPD]: {},
    [ESmartField.Multimedia]: {},
    [ESmartField.DynamicMenu]: {
        showFieldReturnOnError: true,
        ...defaultAdditionalOptionsConfig
    },
    [ESmartField.RTFValidator]: {},
    [ESmartField.FieldReuse]: {
        ...defaultAdditionalOptionsConfig
    },
    [ESmartField.smartConfirmation]: {
        ...defaultAdditionalOptionsConfig
    },
    [ESmartField.CRM]: {
        hasMenuVirtualTitleConfig: true,
        hasPreContent: true,
        hasPosContent: true,
        contextConfig: {
            [EMetaEngagementConfigContext.AgentRegistry]: {
                hasMenuVirtualTitleConfig: false,
                hasPreContent: false,
                hasPosContent: false,
            }
        }
    },
    [ESmartField.docReader]: {},
};

interface IMetaEngagementContextConfig {
    availableSmartFields: ESmartField[];
}

const standardMetaEngegmentConfigContext: IMetaEngagementContextConfig = {
    availableSmartFields: values(ESmartField),
}

const engagementContextConfigDB: { [key in EMetaEngagementConfigContext]: IMetaEngagementContextConfig } = {
    [EMetaEngagementConfigContext.AgentRegistry]: {
        availableSmartFields: [ESmartField.CRM],
    },
    [EMetaEngagementConfigContext.ColmeiaForm]: standardMetaEngegmentConfigContext,
    [EMetaEngagementConfigContext.ConnectionRoute]: standardMetaEngegmentConfigContext,
    [EMetaEngagementConfigContext.CrmProjectDimensionForm]: standardMetaEngegmentConfigContext,
}

export function getEngegamentContextConfig(context: EMetaEngagementConfigContext): IMetaEngagementContextConfig {
    return engagementContextConfigDB[context];
}


export function getSmartFieldConfig(smartFieldType: ESmartField, engagementCtx: EMetaEngagementConfigContext): ISmartFieldConfig {
    let smartFieldTypeConfig = smartFieldConfigDB[smartFieldType];


    if (smartFieldTypeConfig?.contextConfig?.[engagementCtx]) {
        smartFieldTypeConfig = {
            ...smartFieldTypeConfig[engagementCtx],
        }
    }

    return smartFieldTypeConfig;
}

export function emptyEngagementConfigOfType(type: EMetadataEngagementType): IEngagementConfig {
    return typedClone(emptyEngagements[type]);
}

export interface IMetadataRegisterDynamicListConfig {
    isDynamicList?: boolean;
    idDatabase?: string
}

export interface IMetadataRegister extends IMetadataRegisterDynamicListConfig {
    idMetadata: IdDep<ENonSerializableObjectType.formSchemma>;
    engagement: IMetaEngagement;
    isMandatory?: boolean;
}
export type TIMetadataServiceRegisterArray = Array<IMetadataRegister>;

export type EngagementConfigHash = {
    [key in EMetadataEngagementType]?: IEngagementConfig;
}

export interface IMetaEngagement {
    idMetaEngagement: string;
    engagementConfig: EngagementConfigHash;
    descriptor: TEngagementDescriptor;
    elementsOrder: TArrayID;
}

export type TEngagementDescriptor = Array<IFieldMapper>;

export interface INewMetaEngagement extends IMetaEngagement {
    descriptor: TINewConditionFieldMapperArray;
}

export interface IFieldMapper extends IAditionalOptions {
    idFieldMapper: string;
    engagementType: EMetadataEngagementType;
    idFieldForm: string;
    idCanonico?: string;
    idFieldReturnWhenIncomplete?: string;
};

export type TINewConditionFieldMapperArray = Array<INewConditionFieldMapper>;


export interface INewConditionFieldMapper extends IFieldMapper, IConditionalValuatorContainer {
    engagementType: EMetadataEngagementType.NewCondition;
}

export function isNewConditionField(field: IFieldMapper): field is INewConditionFieldMapper {
    return field.engagementType === EMetadataEngagementType.NewCondition;
}

export function isRichFillField(field: IFieldMapper): field is IRichFormExperience {
    return field.engagementType === EMetadataEngagementType.richFillForm;
}

export function isSmartField(field: IFieldMapper): field is IFieldSmartMapper {
    return field.engagementType === EMetadataEngagementType.smartMapper;
}

export interface IFieldEntityMapper extends IFieldMapper {
    idEntity: string;
}

export type TIFieldSmartMapperArray = Array<IFieldSmartMapper>



export interface IAditionalOptions {
    isAdditionalOptOn?: boolean;
    additionalActions?: TBotActionArray; // Permite criar opções onde existem multipas escolhas como menuVirtual, confirmação inteligente, cep entre outros
}

export interface IFieldSmartMapper extends IFieldMapper {
    smart?: ESmartField;

    virtualMenuTitle?: TSingleContentAsset;

    preContent?: TContentAssetArray;
    posContent?: TContentAssetArray;



};



export type TIFieldSmartMapperWithActionOnError = IFieldSmartMapper & ISmartFieldWithErrorHandlerConfig;

export enum ELayoutElement {
    inputMask = 'inputMask',
    secret = 'secret',
    calendar = 'calendar'
}

export interface ILayoutFieldMapper extends IFieldEntityMapper {
    engagementType: EMetadataEngagementType.layout;
    type: ELayoutElement;
}

export interface ILayoutFieldMapperInputMask extends ILayoutFieldMapper {
    type: ELayoutElement.inputMask
    formatMask: string;
    removePontuation: boolean
}

export enum EAddressFullfillType {
    address = 'address',
    geo = 'geo',
    zip = 'zip'
};

export enum EAddressFields { // Os valores devem ser os mesmos dos campos retornados pela busca do endereço no Google
    description = "description",
    // zip = "zip",
    street = "street",
    neighborhood = "neighborhood",
    city = "city",
    // country = "country"
}

export type TAddressFieldKey = keyof typeof EAddressFields;

export const smartAddressAdvancedTitleFillOrder: EAddressFields[] = [
    EAddressFields.street,
    EAddressFields.neighborhood,
    EAddressFields.city
];

export interface ISmartAddressDisplayField {
    key: TAddressFieldKey;
    fieldFormatType?: ECSFormatField
};

export interface ISmartFieldWithErrorHandlerConfig {
    onError: {
        actionOnErrors?: IBPMValidatorActionOnError;
    }
}

export interface IFieldMultimediaSmartOnError {
    noMedia: TContentAssetArray;
    failToDecodeMedia: TContentAssetArray;
    notAllowedMedia: TContentAssetArray;

    minMSDurationExceeded: TContentAssetArray;
    maxMSDurationExceeded: TContentAssetArray;
    //@Fe o mesmo do validador newCondition
    actionOnErrors?: IBPMValidatorActionOnError;
}


export enum EMultimediaProcessType {
    OCR = 'typeOCR', qrcode = 'typeQR', function = 'typeFN'
};


export enum EMultimediaExtractionMethod {
    machineLearning = 'matchML',
    bruteForce = 'matchBrute',
    userFunction = "userFunction"
}

export interface IProcessMultimediaSmart {
    processType: EMultimediaProcessType; // Método de Extração
    entityIdentificationType: EMultimediaExtractionMethod; // Método de Identificação de Entidades
    maxElementsPerEntity: number; // 1 ..30  Máximo de Elementos por Entidade
    idRealTimeFusion: string; // Real Time Fusion
    idLocalCanoncialForMatch: string;
    idPropertyDestiny: string; // Propriedade de Destino
    idFunction?: string;
}

export interface IFieldMultimediaSmartData extends ISmartFieldWithErrorHandlerConfig {
    maxNumberOfFiles: number;
    maxSizePerFileMB: number;
    multimediaTypeAllowed: EIdMultimediaType[];
    onError: IFieldMultimediaSmartOnError;
    videoValidation?: {
        controlVideoDuration: boolean;
        minMS: Miliseconds;
        maxMS: Miliseconds;
    }
}

export interface IFieldMultimediaSmart extends IFieldSmartMapper, IFieldMultimediaSmartData {
    smart: ESmartField.Multimedia;
    processMediaContent: boolean; // Processamento de Mídia
    processMedia: IProcessMultimediaSmart;

}


export interface IFieldSmartDocReaderEngagement extends IFieldSmartMapper, IFieldMultimediaSmartData {
    smart: ESmartField.docReader;
    idTransformer: IdTransformer;
    docSegment: EDOCSegment;
}


export interface IConfirmationPropertyDisplay {
    idProperty: string;
    prompt: string;
    doNotShowing?: boolean;  // Não exibir
}

export type TIConfirmationPropertyDisplayArray = Array<IConfirmationPropertyDisplay>;

export interface IFieldUserConfirmationSmart extends IFieldEntityMapper, ISmartFieldWithErrorHandlerConfig {
    smart: ESmartField.smartConfirmation;
    toBeConfirmed: TIConfirmationPropertyDisplayArray
    title: TSingleContentAsset;
    yesQuestion: TSingleContentAsset;
    noQuestion: TSingleContentAsset;
    idPropertyToReturn: string;

}

export interface IFieldSmartAddress extends IFieldSmartMapper, ISmartAddressSettings, ISmartFieldWithErrorHandlerConfig {
    smart: ESmartField.Address;
    addressReturnType?: EAddressFullfillType;  // Preencher o campo com:
    removeSearchResultsWithInvalidCEP?: boolean;
    /**
     * SmartAddress passou a usar o componente cm-render-options e portanto, o header passou a ser definido pelo campo multipleChoiceAdvancedTitle da interface IBotElementWithRenderOptions.
     * @deprecated
     */
    header: TContentAssetArray; // habilitar apenas 1
    onAddressNotFound: TContentAssetArray;
    invalidSearchMethod: TContentAssetArray;
    onSearchResultsWithInvalidCEP?: TContentAssetArray; // Usado apenas se a flag removeSearchResultsWithInvalidCEP estiver ativa
    menuRenderOptions: ISmartAddressDynamicMenuRenderOptions;
}

/**
 * must = deve conter, equivalente a uma operacao AND
 * should = deveria conter, equivalente a uma operacao OR
 * must_not = nao deve conter
 */
export enum ECSPredicateType {
    must = 'must', should = 'should', mustNot = 'must_not'
}


export interface ISearchMapperToCS {
    isCanonical?: boolean
    idCanonical?: string;
    type: ECSPredicateType;  // Must / Should
    comparison: ECSContentComparison; // Considerar palavras: Completa, Parcial
    idFormProperty: string;// Formulário do Engagement
    idCorporateSearchProperty?: string;
}

export type TISearchMapperToCSArray = Array<ISearchMapperToCS>;

export enum ESmartCSFireOption {
    choseBefore = 'choseBefore',
    choseAfter = 'choseAfter'
}


export interface IDynamicMenuSearchPredicatesRules {
    matchWithAllSearchFields: boolean;  // Comparar com todos os campos disponívies na busca
    predicates: TISearchMapperToCSArray;
    shouldMinimumOcurrence: number;
}

export type TIFieldSmartCorporateSearchArray = Array<IFieldSmartCorporateSearch>;


export interface IFieldCRMEngagement extends IFieldSmartMapper {
    smart: ESmartField.CRM;
    idVisualizer: IdTransformer;

    showAllCustomerTicketsToAgent?: boolean;
    showProjectKey?: boolean;

    notFound?: TContentAssetArray;

    timeWindow: ICRMTimeWindowConfig;
    order: TICRMTicketOrdination;
    filter: TIBPConditionalEvaluatorArray;
    limit?: number;

    shouldContinue?: boolean;
}

export interface IFieldSmartCorporateSearch extends IFieldSmartMapper {
    smart: ESmartField.DynamicMenu;
    fireOption?: ESmartCSFireOption;  // Executa a busca: Antes do Prenchimento / Após o preenchimento
    // /** @deprecated */
    //idRTF?: string; // Isso vai deprecar
    idCorporateSearch?: string;
    dynamicMenuSearch: IDynamicMenuSearchPredicatesRules; // Qual propriedade fará a busca

    geoMapSortConfig?: ISearchMapperToCS; // Campo Geolocalizado
}


export interface IFielSmartFieldReuseHeader extends IBotElementWithRenderOptions {
    searchForProperty: boolean;  // Buscar pela propriedade ligada a este smartfield
    idComparingLocalCanonical: string; // Canonico Global para nome
    dontAskIfFullfilled?: boolean;  // Não pergunte caso preenchido preenchido
    useNaturalCanonicalAsDefault?: ENaturalCanonicals;  // Utilizar Canonico Natural caso nulo
    idPosFunction?: string; // Executar função após o reuso:
    title: TSingleContentAsset;
    yes: TSingleContentAsset;
    no: TSingleContentAsset;
}

export interface IFielSmartFieldReuse extends IFieldSmartMapper, IFielSmartFieldReuseHeader {
    smart: ESmartField.FieldReuse; // Reutilização de Campos
}


export interface IFieldSmartShakingHandsValidator extends IFieldSmartMapper {
    smart: ESmartField.RTFValidator;
    idRTF: string; // deprecado
    idCorporateSearch?: string;
    generateErrorWhenNotFound?: boolean;
    rtfType: ERealTimeFusionType; // deprecado
    idFieldSearchField?: string; // Apenas mostra isso se RTF for banco de dados
    isNotExistAnError?: boolean; // Apenas mostra isso se RTF for banco de dados
    onError: TContentAssetArray;
    onSuccess: TContentAssetArray;
}


export interface IFieldSmart2FV extends IFieldSmartMapper {
    smart: ESmartField.TwoFactorValidation;
    settings: I2FASettings;
}

export interface IConditionalValuatorContainer {
    evaluators: TIBPConditionalEvaluatorArray;
}

export interface ILGPDConditionalMapper extends IFieldSmartMapper, IConditionalValuatorContainer {
    smart: ESmartField.LGPD;
}

export interface IFieldSmartNPS extends IFieldSmartMapper {
    smart: ESmartField.NPS;
    npsName: string;
    customerSatisfationMetric: ECustomerSatisfationMetric;
    lowerRate: string;
    higherRate: string;
    action: IBPMValidatorAction;
    detractorLimit: string;
    passiveLimit: string;
}

export enum ECustomerSatisfationMetric {
    NPS = 'nps',
    CSAT = 'csat',
    custom = 'custom'
}

type TCustomerSatisfationHasConfigMetrics = Exclude<ECustomerSatisfationMetric, ECustomerSatisfationMetric.custom>;

type TSmartNPSCustomerMetricsConfig = Record<TCustomerSatisfationHasConfigMetrics, Partial<IFieldSmartNPS>>;

export const smartNPSCustomerMetricsConfig: TSmartNPSCustomerMetricsConfig = {
    [ECustomerSatisfationMetric.NPS]: {
        lowerRate: '0',
        higherRate: '10',
        detractorLimit: '6',
        passiveLimit: '8'
    },
    [ECustomerSatisfationMetric.CSAT]: {
        lowerRate: '1',
        higherRate: '5',
        detractorLimit: '2',
        passiveLimit: '3'
    }
};

export interface IFieldSmartQrCode extends IFieldSmartMapper, ISmartQrCodeSettings {
    smart: ESmartField.QRCode;
}


export type SmartFieldTypeToInterface = Explicit<{ [key in ESmartField]: IntersectPartialExplicit<IFieldSmartMapper, { smart: key }> }, {
    [ESmartField.Address]: IFieldSmartAddress,
    [ESmartField.Multimedia]: IFieldMultimediaSmart,
    [ESmartField.QRCode]: IFieldSmartQrCode,
    [ESmartField.TwoFactorValidation]: IFieldSmart2FV,
    [ESmartField.NPS]: IFieldSmartNPS,
    [ESmartField.LGPD]: ILGPDConditionalMapper,
    [ESmartField.DynamicMenu]: IFieldSmartCorporateSearch,
    [ESmartField.RTFValidator]: IFieldSmartShakingHandsValidator,
    [ESmartField.FieldReuse]: IFielSmartFieldReuse,
    [ESmartField.smartConfirmation]: IFieldUserConfirmationSmart,
    [ESmartField.CRM]: IFieldCRMEngagement,
    [ESmartField.docReader]: IFieldSmartDocReaderEngagement,
}>;

export type TAllFieldMappers =
    | INewConditionFieldMapper
    | IFieldEntityMapper
    | IFieldSmartMapper
    | IFieldSmartAddress
    | IFieldSmart2FV
    | ILGPDConditionalMapper
    | IFieldSmartNPS
    | IFieldSmartQrCode
    ;

export interface ISmartQrCodeSettings {
    failedToDecodeImage: TContentAssetArray;
    noImageUploaded: TContentAssetArray;
}

export interface ISmartAddressSettings {
    allowZip: boolean;
    allowGeo: boolean;
    allowAddress: boolean;
    zipCodeRegex: string;
}

/// Conditionals
export enum EMetaEngagementConditionType {
    interval = 'metaInterval',
    literal = 'metaLiteral',
    regex = 'metaRegex',
    greater = 'metaGreater',
    smaller = 'metaSmaller',
    list = 'metaList',
    contains = 'contains',
    metaNull = 'metaNull',
    userFunction = 'userFunction',
    notNull = 'notNull',
    isChannel = 'isChannel',
    isAllwaysTrue = 'isAllwaysTrue',
    dateValidation = 'dValidation',
    // api = 'metaApi',
}

export enum EMKTConditionInformationSource {
    iddleTime = 'conMktIddleTime', // Tempo de inatividade do último envio,
    lastStatus = 'conMktStts', // Status atual (último status do último envio),
    customerIdleTime = 'customerIdleTime',
}

export enum EBPMConditionInformationSource {
    event = '*bpmEvent',
}

export type TAllConditionalType = EMetaEngagementConditionType;

export const CONDITION_TOKEN_DELIMITER = ";";

export type TIBasicConditionArray = Array<IBasicCondition>;

export enum EDateOps {
    greaterThan = "greater",
    smallerThan = "smaller",
    equalls = "equalls"
}

export interface IMetaEngagementDateValidation {
    format: EValidFormatFinal
    validation?: TIDateValidationArray
}

export interface IDateValidation {
    operation: EDateOps;
    input: IDateInput
}

export type TIDateValidationArray = Array<IDateValidation>;

export enum EBotConditionInformationSource {
    lastAddressSearch = 'conBotLastAddSear', // Último resultado da busca de endereço
    currentChannel = 'currChannel',
}

export enum ECustomerVisitMetrics {
    botTot = 'btTot',
    botToday = 'bt0',
    bot7Days = 'bt7',
    bot30Days = 'bt30',
    bot90Days = 'bt90D',
    bot180Days = 'bt180',
    bot360Days = 'bt360',
    serviceTotal = 'srTot',
    serviceToday = 'sr0',
    service7Days = 'sr7',
    service30Days = 'sr30',
    service90Days = 'sr90',
    service180days = 'sr180',
    serviceYear = 'sr360',
}

export type TECustomerVisitMetrics = ECustomerVisitMetrics;


export enum ECalendarAvailable {
    available = 'available',
    unavailable = 'unavailable'
}

export type TAllConditionInformationSources = EMKTConditionInformationSource | EBotConditionInformationSource | ECustomerVisitMetrics | EBotTrackConditions;


export const infoSourceOfInfoToNserMapping: Partial<Record<ESourceOfInfo, ENonSerializableObjectType>> = {
    [ESourceOfInfo.canonical]: ENonSerializableObjectType.canonical,
}


export enum EBotTrackConditions {
    Name = '*btTrckNm',
    Parameter = '*btTrckPrm',
}

export interface IBasicCondition {
    //isCanonical?: boolean;
    sourceInfo: ESourceOfInfo;
    idInformationSource?: string; // Começamos com um Canonico ou idProperty
    idConditionalAsset?: string;
    metaConditionType: TAllConditionalType; //
    reverseCondition?: boolean;
    dateValidation?: IMetaEngagementDateValidation;
    trueIfFunctionFail?: boolean; // Continue a execução em caso de exceção
    condition: string; // interval seria 1;5// literal seria 5; lista seria 1;2;3;4;5; //api: seria a chamada;
    idUserFunction?: string;
}

export enum ECRMType {
    Salesforce = EConnectionType.SalesForce
}

export interface ICRMTransformationConfig extends IEngagementConfig {
    engagementType: EMetadataEngagementType.CRMTransformation,
    recordType: string;
    idRoute: string;
}


export interface IMultipleChoiceCRMMapper {
    toCRMValue: string;
    value: string;
}

export type TICRMTranformationArray = Array<ICRMTranformation>;

export interface ICRMTranformation extends IFieldMapper {
    fieldName: string;
    multipleAnswers?: IMultipleChoiceCRMMapper[];
}

export interface IRealTimeFusionConfig extends IEngagementConfig {
    idInformationGather: string;
}

export interface ISendToChannelByAgentEngagementConfig extends IEngagementConfig {
    engagementType: EMetadataEngagementType.integrationByChannel;
    idChannel?: string;
    idTemplate?: string;
    toTargetArray: TArrayID;
    contentArray: TContentAssetArray;
}

// Se eu colocar o campo A e B no searchGroup grp, vou procurar uma linha do no file
// que tenha o campo A e B com o valor atual.
// Posso criar mais do que um searchGroup e buscar
export interface IRealTimeFusionField extends IFieldMapper {
    searchGroupName: string;
}

// DEPRECAR
export interface IConditionFinishEvent {
    onError: IOnCondition;
    onSuccess: IOnCondition;
}

export interface IOnCondition {
    execute: IBotActionAsset;
}

export function emptyIOnCondition(): IOnCondition {
    return {
        execute: {
            idAsset: undefined,
            type: undefined,
            idElement: undefined,
            contentArray: []
        }
    };
}

export type TIConditionalContentValidatorArray = Array<IConditionalContentValidator>;

export interface IConditionalContentValidator extends IBasicCondition {
    finishEvent: IConditionFinishEvent;
}

export interface IChoiceConfigure {
    optionText: string;
    returningID: string;
    sectionName?: string;
    description?: string;
    trackerRule?: INaviTracker;
    wafDescription?: string; // usado apenas pelo whatsapp flows
    wafAsideInfo?: string; // usado apenas pelo whatsapp flows
}

export type TIChoiceConfigureArray = Array<IChoiceConfigure>;

export interface IRichFormExperience extends IFieldMapper, IBotElementWithRenderOptions {
    engagementType: EMetadataEngagementType.richFillForm,
    contentArray: TContentAssetArray;
    hasConfigMultipleChoice?: boolean;
    multipleChoice?: IChoiceConfigure[];
}

// #region WAF =================================================================

export interface IWAFEngagementConfig extends IEngagementConfig {
    engagementType: EMetadataEngagementType.whatsAppFlow;
    flowConfigurations: TIEngagementFlowConfigurationArray;
    flowAreas?: TIFlowAreaArray; // DEPRECATED: using for old flows
}

// #endregion =================================================================

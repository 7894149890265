<header>
  <mat-form-field appearance="fill" class="select-content-type">
    <mat-label>Selecione o tipo de conteúdo</mat-label>
    <mat-select [(ngModel)]="instanceTypeClient" (selectionChange)="onChangeInstanceTypeClient($event)">
      <mat-select-trigger>
        {{ translations[instanceTypeClient].value | captalizeFirstLetter }}
        <span
          *ngIf="instanceTypeClient === KBAssetTypeClientOnly.sticker"
          [helperInfo]="ETooltipInfoKey.assetAdderAssetTypeSticker"
          [hiPopover]="true"
          hiHorizontalAlign="left"
          style="margin-left: 5px"></span>
      </mat-select-trigger>

      <mat-option *ngFor="let type of instance.assetTypes" [value]="type">
        {{ translations[type].value | captalizeFirstLetter }}
        <span
          *ngIf="type === KBAssetTypeClientOnly.sticker"
          [helperInfo]="ETooltipInfoKey.assetAdderAssetTypeSticker"
          [hiPopover]="true"
          hiHorizontalAlign="left"
          style="margin-left: 5px"></span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-radio-group *ngIf="hasBotEventContentType()" class="subtype-selection" aria-label="Escolha o tipo de mensagem"
    [(ngModel)]="instance.newAsset.botContentType">
    <mat-radio-button *ngFor="let item of instance.handler.botContentEventTypesSelectionPerMessage"
      [disabled]="!canAddSubType(item)" [value]="item">
      {{ localeTranslation[item] }}
    </mat-radio-button>
  </mat-radio-group>
</header>

<main mat-dialog-content style="padding: 0 2px 4px;">
  <div class="type-content">
    <ng-container [ngSwitch]="instanceTypeClient">
      <ng-container *ngIf="instance.showInputTextVariables">
        
        <app-input-text-variables
          *appSwitchCases="instance.contentTextTypes"
          [asset]="instance.newAsset"
          [enableContextVariables]="true"
          [schemaVariables]="instance.schemaVariables"
          [nsVariables]="getNSVariables()"
          [limitCharacters]="limitCharacters"
          [disableFallback]="disableFallback"
          [varEditorMode]="varEditorMode"
          [handler]="inputTextVariablesHandler"
          (keyup)="saveIfRequested($event)"
        ></app-input-text-variables>

      </ng-container>

      <ng-container *ngSwitchCase="instance.botActionType.goBot">
        <app-ns-picker *ngIf="botPicker" [handler]="botPicker"></app-ns-picker>
      </ng-container>

      <ng-container *ngSwitchCase="instance.botActionType.contentGenerator">
        <app-ns-picker *ngIf="generatorPicker" [handler]="generatorPicker"></app-ns-picker>
      </ng-container>

      <ng-container *ngSwitchCase="instance.botActionType.goHuman">
        <app-ns-picker *ngIf="goHumanPicker" [handler]="goHumanPicker"></app-ns-picker>
      </ng-container>

      <ng-container *ngSwitchCase="instance.botActionType.goActionTree">
        <app-ns-picker *ngIf="goActionTreeBotPicker" [handler]="goActionTreeBotPicker"></app-ns-picker>
        <app-ns-picker *ngIf="goActionTreePicker" [handler]="goActionTreePicker"></app-ns-picker>
      </ng-container>

      <div *ngSwitchCase="instance.assetTypeEnum.contentAPICall" class="boxed">
        <app-connection-route-picker [handler]="instance.connRouteHandler"></app-connection-route-picker>
      </div>

      <ng-container *ngSwitchCase="instance.assetTypeEnum.media">
        <div class="image-uploader">
          <app-hexagon-uploader #hexagonUploader [handler]="instance.newAssetUploader" [forceMimeType]="forceMimeType"></app-hexagon-uploader>
          <div *ngIf="hasMedia && shouldAskDisplayName" class="file-name-wrapper">
            <strong>Nome de exibição do arquivo (opcional)</strong>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'Nome' }}</mat-label>
              <input matInput type="text" [value]="getDisplayName()" (input)="handleCustomDisplayName($event)">
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="KBAssetTypeClientOnly.sticker">
        <div class="image-uploader">
          <app-hexagon-uploader #hexagonUploader [handler]="instance.newAssetUploader" [forceMimeType]="forceMimeType"></app-hexagon-uploader>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="instance.assetTypeEnum.generativo">
        <div class="generativo">
          <div>
            <h4>Prompt</h4>
            <app-ns-picker *ngIf="generativePromptPicker" [handler]="generativePromptPicker"></app-ns-picker>
            <app-code-editor *ngIf="prompt" language="plain-text" label="{{ prompt.nName }}" [content]="prompt.text"
              theme="vs" readonly></app-code-editor>
          </div>
          <div *ngIf="hasGenerativeConfiguration && generativeVectorPicker">
            <h4>Vetor e Chunks</h4>
            <div class="row">
              <app-ns-picker
                [handler]="generativeVectorPicker"
                [disabled]="mirrorKBIntent"
              ></app-ns-picker>
              <app-ns-picker
                *ngIf="generativeChunksPicker"
                [handler]="generativeChunksPicker"
                [disabled]="mirrorKBIntent"
              ></app-ns-picker>
            </div>
          </div>

          <div class="fine-tunning" *ngIf="hasGenerativeConfiguration">

            <mat-slide-toggle
              [(ngModel)]="mirrorKBIntent"
            >
              Espelhe chunks da base de conhecimento
            </mat-slide-toggle>

            <!-- Toda a estratégia de fineTuning está sendo questionado, até as motivaçõs. Por agora, deprecado. -->
            <!-- <br>

            <mat-slide-toggle
              [(ngModel)]="fineTuning"
            >
              Usar fine tunning
            </mat-slide-toggle> -->

          </div>

        </div>
        <mat-divider></mat-divider>
      </ng-container>


      <ng-container *ngSwitchCase="KBAssetType.sendTemplate">

        <app-campaign-action-picker *ngIf="campaignActionPicker" class="item" [handler]="campaignActionPicker"></app-campaign-action-picker>

      </ng-container>


      <ng-container *ngSwitchCase="KBAssetType.sendCTA">
        <div class="cta__container">
          <h4>Mensagem</h4>
        
          <div class="cta__item" *ngIf="cta.header">
            <app-conditional-expansion-panel
              label="Exibir cabeçalho"
              [(active)]="cta.header.shouldShow"
            >
              <div *ngIf="shouldShowHeader()" class="cta__item">
                <app-enum-picker *ngIf="headerPicker" [handler]="headerPicker"></app-enum-picker>
                <asset-adder *ngIf="headerHandler" [handler]="headerHandler"></asset-adder>
              </div>
            </app-conditional-expansion-panel>
          </div>

          <div class="cta__item">
            <asset-adder *ngIf="bodyHandler" [handler]="bodyHandler"></asset-adder>
          </div>

          <div class="cta__item" *ngIf="cta.footer">
            <app-conditional-expansion-panel
              label="Exibir rodapé"
              [(active)]="cta.footer.shouldShow"
            >
              <asset-adder *ngIf="footerHandler" [handler]="footerHandler"></asset-adder>
            </app-conditional-expansion-panel>
          </div>

          <ng-container *ngIf="cta.buttons[0] as quickReply">
            <h4>Botão URL</h4>

            <button mat-stroked-button class="quick-reply button-w-icon" (click)="editQuickReply(quickReply)" [class.opaque]="!quickReply.buttonText">
              <mat-icon>create</mat-icon>&nbsp;
              {{ quickReply.buttonText || "Configurar botão" }}
            </button>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="exibition-container" *ngIf="!instance.isOnlyText() && !shouldHideBottomOptions">
    <ng-container *ngIf="instance.enumRecencyPickerHandler">
      <div class="enum-number-pickers">
        <app-enum-picker [handler]="instance.enumRecencyPickerHandler"></app-enum-picker>
        <app-input-number *ngIf="
            instance.inputNumberHandler && instance.newAsset.recency === 'asTimed'
          " [handler]="instance.inputNumberHandler"></app-input-number>
        <app-input-number [handler]="instance.waitTimeSecondsNumberHandler"></app-input-number>
      </div>

      <mat-accordion style="width: 100%; margin-bottom: 8px;">
        <app-conditional-expansion-panel label="Mashup" *ngIf="hasMedia" [(active)]="instance.newAsset.hasTextOnImage"
          (activeChange)="initMashup()">
          <app-enum-picker *ngIf="mashupSizeHandler" [handler]="mashupSizeHandler"></app-enum-picker>
          <app-enum-picker *ngIf="mashupColorHandler" [handler]="mashupColorHandler"></app-enum-picker>
        </app-conditional-expansion-panel>
      </mat-accordion>

      <mat-accordion style="width: 100%; margin-bottom: 8px;">
        <app-conditional-expansion-panel label="Exibição condicional"
          [(active)]="instance.newAsset.isConditionalContent" (activeChange)="onConditionalDisplayToggleChange()">
          <app-conditions-editor *ngIf="conditionsHandler" [handler]="conditionsHandler">
          </app-conditions-editor>
        </app-conditional-expansion-panel>
      </mat-accordion>
    </ng-container>
  </div>
</main>
<mat-dialog-actions align="end">
  <button mat-button (click)="handleCancelClick()">Cancelar</button>
  <button mat-flat-button (click)="handleSaveClick()" color="primary">
    Salvar
  </button>
</mat-dialog-actions>

import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { typedScreenOptions } from "../../../tools/utility";
import { EConnectionType, EHTTPMethod } from "../../connections/endpoint-model";
import { genericTypedSuggestions } from "../../../tools/type-utils";



export const connectionsTypeTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.ConnectionsType,
    fields: genericTypedSuggestions<Record<EConnectionType, number>>()({
        [EConnectionType.Custom]: 1, // dashboardConnectionsListNew 19
        [EConnectionType.Luis]: 2, // dashboardConnectionsListNew 20
        [EConnectionType.SalesForce]: 3, // dashboardConnectionsListNew 21
        [EConnectionType.Colmeia]: 4, // landing.constants 1
        [EConnectionType.Google]: 5, // Google
        [EConnectionType.GoogleAnalytics]: 6,
        [EConnectionType.FacebookAnalytics]: 7,
        [EConnectionType.CLU]: 8,
        [EConnectionType.OpenAI]: 9,
        [EConnectionType.Gemini]: 10,
        [EConnectionType.CloudComputing]: 11,
    } as const)
} as const));

//

/*
* applicationId: string;
    tenantId: string;
    applicationSecret: string;
    subscriptionId: string;*/

const connectionsScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.Connections,
    fields: {
        typeOfConnection: 1, // Tipo de conexão
        insertANewConnection: 2, // dashboardConnectionsListNew 23
        integrationType: 3, // providerAndIntegration 3
        providerType: 4, // providerAndIntegration 4
        subscriptionId: 5, // ID de Subscription,
        applicationId: 6, // ID da Aplicação
        tenantId: 7, // ID do diretório
        applicationSecret: 8, // Segredo da aplicação
        subscriptionKey: 9, // Chave de subscription
        resourceGroup: 10, // Grupo de recursos
        errorInLuisData: 11, // Verifique os dados inseridos do Luis, ocorreu um erro
    }
} as const);

const connectionOptions = typedScreenOptions({
    idSerializable: EAllSerializable.ConnectionOptions,
    fields: {
        delete: 1,
        get: 2,
        patch: 3,
        post: 4,
        put: 5,
        credentials: 6,
        domains: 7,
        routes: 8,
        authentication: 9,
        development: 10,
        beta: 11,
        production: 12,
        secretID: 13,
        clientID: 14,
        user: 15,
        password: 16,
        once: 17,
        everyCall: 18,
        expiresAfter: 19,
        newDomain: 20,
        chooseRoute: 21,
        changeRoute: 22,
        qa: 23,
        // domain: 23, // Domínio
    }
} as const);

const connectionOptions2 = typedScreenOptions({
    idSerializable: EAllSerializable.ConnectionOptionsTwo,
    fields: {
        pass: 1, // Senha
        routine: 2, // Rotina
        httpMethod: 3, // Método HTTP
        allowedDomain: 4, // Domínio autorizado
        jsonTokenProperty: 5, // Propriedade do token do json
        authorKey: 6, // Chave de autoria
        endpoint: 7, // Endpoint
        predictEndpoint: 8, // Endpoint de predição
        culture: 9, // Cultura
        predictionKey: 10, // Chave de predição
        authoringEndpoint: 11, // Endpoint de autoria
    }
} as const);

const connectionEditEnumOptions = typedScreenOptions({
    idSerializable: EAllSerializable.ConnectionEditEnumOptions,
    fields: {
        [EHTTPMethod.delete]: 1, // DELETE
        [EHTTPMethod.get]: 2, // GET
        [EHTTPMethod.patch]: 3, // PATCH
        [EHTTPMethod.post]: 4, // POST
        [EHTTPMethod.put]: 5, // PUT
    }
})

const salesforceCredentialsScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.SalesforceCredentials,
    fields: {
        secretID: 1, // SecretID
        clientID: 2,// ClientID
        domainHttps: 3, // Domínio
    }
} as const);

export const allConnections = {
    ...connectionsTypeTranslations,
    ...getTranslationsFields(connectionsScreenOptions),
    ...getTranslationsFields(connectionOptions),
    ...getTranslationsFields(connectionOptions2),
    ...getTranslationsFields(connectionEditEnumOptions),
    ...getTranslationsFields(salesforceCredentialsScreenOptions)
};



import { Injectable } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { IGeneralFormAnswer, IGeneralFormAnswerServer, TGeneralFormServerAnswerArray } from '@colmeia/core/src/general-form/general-form-answer';
import { IFormSchema, TFormSchemmaArray } from '@colmeia/core/src/general-form/general-form-interface';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { ourTags } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { isInvalid } from '@colmeia/core/src/tools/utility';
import { AnnotationPreviewModalComponent } from 'app/components/annotation/annotation-preview-modal/annotation-preview-modal.component';
import { AnnotationsModalComponent } from 'app/components/annotation/annotations-modal/annotations-modal.component';
import { AnnotationsModalHandler } from 'app/handlers/annotations-modal-handler';
import { EHexagonSizes, HandlerHexagonon } from 'app/handlers/hexagono.handler';
import { MessageHandlerCallbak } from 'app/handlers/message-instance-handler/message-handler-callback';
import { IAvatarHandlerHash } from 'app/model/chat-filter.model';
import { IAnnotationPreviewData, IGeneralAnswerListItem, IGeneralAnswerListItemServer } from 'app/model/general-form.model';
import { DashBoardService } from './dashboard/dashboard.service';
import { DateService } from './date.service';
import { ColmeiaDialogService } from './dialog/dialog.service';
import { GeneralFormService } from './general-form.service';
import { ProfileAvatarStoreService } from './profile-avatar-store.service';

@Injectable({
    providedIn: 'root'
})
export class AnnotationsService {

    constructor(
        private dialogSvc: ColmeiaDialogService,
        private generalFormSvc: GeneralFormService,
        private dateSvc: DateService,
        private avatarSvc: ProfileAvatarStoreService,
        private dashboardSvc: DashBoardService,
    ) { }

    openFor(handler: AnnotationsModalHandler): MatDialogRef<AnnotationsModalComponent, IGeneralFormAnswerServer> {
        return this.dialogSvc.open<AnnotationsModalComponent, AnnotationsModalHandler>({
            componentRef: AnnotationsModalComponent,
            panelClass: "medium-size",
            dataToComponent: { data: handler, width: "50vw" },
            hideHeader: true
        });
    }

    previewAnnotation(annotation: IGeneralFormAnswerServer, template: IFormSchema, options = {}, dialogConfig: Partial<MatDialogConfig<any>> = {
        minWidth: "50vw"
    }) {
        const data: IAnnotationPreviewData = { annotation, template, ...options };

        this.dialogSvc.open({
            componentRef: AnnotationPreviewModalComponent,
            dataToComponent: { ...dialogConfig, data: { ...data, ...options } },
            hideHeader: true
        })
    }


    messageInstanceChangeDetection(interaction: Interaction, groupID: TGlobalUID): void {
        if (!interaction.hasAnnotation()) {
            const containerController: MessageHandlerCallbak = MessageHandlerCallbak.getMessageHandlerCallback(
                MessageHandlerCallbak.composeKey(groupID, interaction.getPrimaryID()));

            if (containerController) {
                interaction.setAnnotation(true);
                containerController.changeAllSiblingsDetection();
            };
        }
    }


    currentTemplateFilter: IFormSchema = undefined;

    annotations: IGeneralAnswerListItemServer[] = [];
    allAnnotations: IGeneralAnswerListItemServer[] = [];

    schemas: TFormSchemmaArray;
    hexagonHandlers: IAvatarHandlerHash = {};

    filterAnnotations() {
        if (this.currentTemplateFilter === undefined) {
            this.annotations = this.allAnnotations;
        } else {
            this.annotations = this.allAnnotations.filter(annotation => annotation.idSchemma === this.currentTemplateFilter.idSchemma);
        }
    }

    public async getMyAnnotations(sourceId : TGlobalUID): Promise<IGeneralAnswerListItemServer[]> {
        if (!this.schemas) this.schemas = await this.generalFormSvc.getSchemas(this.dashboardSvc.safeDefaultTag || ourTags.colmeiaTag.markers.serviceAttendent.idNS);

        const result = await this.generalFormSvc.getAnswersForUniqueId(sourceId);

        if (!result) return

        const annotations = result.sort((a, b) => b.clockTick - a.clockTick);

        this.allAnnotations = await this.prepareAnnotations(annotations);

        this.filterAnnotations();

        return this.annotations
    }


    async prepareAnnotations(annotations: TGeneralFormServerAnswerArray): Promise<IGeneralAnswerListItemServer[]> {
        const promises = [];
        annotations.map(ann => {
            promises.push(this.loadAnnotationData(ann));
        });
        return Promise.all(promises);
    }


    getTemplate(annotation: IGeneralFormAnswer): IFormSchema {
        return this.schemas
            ? this.schemas.find(s => s.idSchemma === annotation.idSchemma)
            : undefined;
    }

    async setHexagonHandlerByAvatarAndAnnotationIdAvatar(annotation: IGeneralFormAnswerServer, avatar: Avatar) {
        this.hexagonHandlers[annotation.ident.idAvatar] = HandlerHexagonon.newHandler({
            serializable: avatar,
            size: EHexagonSizes.xss
        });
    }

    async loadAnnotationData(ann: IGeneralFormAnswerServer): Promise<IGeneralAnswerListItem> {
        const avatar = await this.avatarSvc.getAvatarFromServerIfNotOurs(ann.ident.idAvatar);

        if (isInvalid(this.hexagonHandlers[ann.ident.idAvatar]))
            this.setHexagonHandlerByAvatarAndAnnotationIdAvatar(ann, avatar)

        const associatedTemplated = this.getTemplate(ann);
        const templateName: string = associatedTemplated ? associatedTemplated.name : '';
        return {
            ...ann,
            userName: avatar ? avatar.getNickName() : '',
            templateName,
            date: this.dateSvc.getBestDate(new Date(ann.clockTick))
        }
    }
}

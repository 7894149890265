import {
    IWAFSchemaDeleteRequest, IWAFSchemaDeprecateRequest, IWAFSchemaGetRequest,
    IWAFSchemaPublishRequest, IWAFSchemaSendMessageRequest, IWAFSchemaSubmitRequest, IWAFSchemaUpsertRequest
} from "./waf.req-res";
import * as BasicTypes from "./waf.types";
import * as FieldTypes from "./waf.model";
import { WAFUtils } from "./waf.functions";
import { FriendlyMessage } from "../../error-control/friendly-message";
import { isInEnum, isValidRef, isInvalid, isInvalidArray, isInvalidString, isValidArray, isValidString } from "../../tools/barrel-tools";
import { EMetadataEngagementType, IFieldSmartCorporateSearch, IMetaEngagement, IRichFormExperience, IWAFEngagementConfig } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { isValidContent } from "@colmeia/core/src/shared-business-rules/bot/asset-functions";
import { WhatsApp } from "@colmeia/core/src/shared-business-rules/social-media/whatsapp-interface";
import { IInputFieldDynamicConfiguration } from "@colmeia/core/src/shared-business-rules/waf/waf.dynamic";
import { getEngangementOfTypeForIDField, getSmartFieldOfType } from "@colmeia/core/src/shared-business-rules/bot/engagement-function";
import { botTransactionErrors } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/error-fields";
import { screenOptionToError } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/error-messages";
import { IFriendlyExtraMessage } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { ESmartField } from "../smart/smart-fields";

export interface IError {
    id: string;
    message: string;
}
export type Errors = Array<IError>;

type Mapperfn = (
    component: FieldTypes.TIWAFFieldConfigurationALL,
    id: string,
) => Errors;
type ComponentMapperTypeFnRecord = Record<BasicTypes.EWAFComponents, Mapperfn>;

export class WAFValidator {

    private static isValidDynamicField<T>(
        field: BasicTypes.IDynamicField<T> | undefined,
        id: string,
        errors: Errors
    ): boolean {
        if (field) {
            if (WAFUtils.isDynamicField(field)) {
                const f = field as BasicTypes.IDynamicField<T>;
                if (isInvalidString(f.id, 1)) {
                    errors.push({
                        id,
                        message: "Id é obrigatório",
                    })
                    return true;
                }

                if (isInvalid(f.default)) {
                    errors.push({
                        id,
                        message: "O valor é obrigatório",
                    })
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    static async upsert(request: IWAFSchemaUpsertRequest): Promise<FriendlyMessage> {
        const model = request.data;

        const fields: Errors = [];
        const msg = new FriendlyMessage("WAFValidator.upsert", true, []);

        model.providers.forEach((p: BasicTypes.IWAFProvider, index: number) => {
            if (isInvalidString(p.idProvider)) {
                fields.push({
                    id: `interactions[${index}].idProvider`,
                    message: "idProvider is required",
                })
            }
        });

        if (isInvalidString(model.idParent)) {
            fields.push({
                id: "idParent",
                message: "idParent is required",
            })
        }

        for (const screen of model.screens) {
            WAFValidator.isValidScreen(screen, screen.screenId, fields);
        }

        msg.setOk(fields.length === 0);
        fields.map(f => msg.add(f.id, f.message));
        return msg;
    }

    private static isValidScreen(
        screen: FieldTypes.IWAFScreen,
        id: string,
        errors: Errors
    ) {
        WAFValidator.isValidDynamicField(screen.header.title, "header.title", errors);
        WAFValidator.isValidDynamicField(screen.footer.cta, "footer.cta", errors);
        
        //footer
        if (screen.footer.caption) {
            const caption = screen.footer.caption;
            // if exist centerCaption, leftCaption and rightCaption should not
            // exist
            if (caption.centerCaption) {
                WAFValidator.isValidDynamicField(caption.centerCaption!, "footer.caption.centerCaption", errors);
                if (caption.leftCaption) {
                    errors.push({
                        id: "footer.caption.leftCaption",
                        message: "leftCaption should not exist with center",
                    })
                }
                if (caption.rightCaption) {
                    errors.push({
                        id: "footer.caption.rightCaption",
                        message: "rightCaption should not exist with center",
                    })
                }
            }
            else {
                if (caption.rightCaption) {
                    WAFValidator.isValidDynamicField(caption.rightCaption!, "footer.caption.rightCaption", errors);
                }
                if (caption.leftCaption) {
                    WAFValidator.isValidDynamicField(caption.leftCaption!, "footer.caption.leftCaption", errors);
                }
            }
        }
        if (isInvalidArray(screen.fields)) {
            errors.push({
                id: "fields",
                message: "fields is required",
            })
        }
        screen.fields.forEach((f, index) => {
            const errors = WAFValidator.validateComponent(f, index);
            errors.push(...errors);
        })
    }

    static validateComponent(field: FieldTypes.TIWAFFieldConfigurationALL, index: number): Errors {
        const componentValidator = WAFValidator.ComponentTypeHandlerMap[field.type];

        if (componentValidator) {
            return componentValidator(field, index.toString());
        } else {
            throw new Error(`Componente do tipo ${field.type} não é suportado.`);
        }
    }

    static validateFlowHeader(schemaHeader: FieldTypes.IWAFContentHeadderConfiguration): Errors {
        const errors: Errors = [];

        if (isInvalidString(schemaHeader.title.default)) {
            errors.push({
                id: "0",
                message: "O título da página é obrigatório."
            });
        }

        return errors;
    }

    static validateFlowFooter(schemaFooter: FieldTypes.IWAFFooterConfiguration): Errors {
        const errors: Errors = [];

        if (isInvalidString(schemaFooter.cta.default)) {
            errors.push({
                id: "0",
                message: "O texto do botão CTA é obrigatório."
            });
        }

        if (schemaFooter.caption) {
            const { centerCaption, leftCaption, rightCaption } = schemaFooter.caption;
            const validCenter = isValidString(centerCaption?.default);
            const validLeft = isValidString(leftCaption?.default);
            const validRight = isValidString(rightCaption?.default);

            if (!(validCenter || (validLeft && validRight))) {
                errors.push({
                    id: "0",
                    message: "Preencha todos os campos da legenda"
                });
            } else if (validCenter && (validLeft || validRight) || (validLeft || validRight) && validCenter) {
                errors.push({
                    id: "0",
                    message: "A legenda é inválida"
                });
            }
        }

        return errors;
    }

    static async get(request: IWAFSchemaGetRequest): Promise<FriendlyMessage> {
        const fields: Array<IError> = [];
        const msg = new FriendlyMessage("WAFValidator.get", true, []);

        if (isInvalidString(request.idNS)) {
            fields.push({
                id: "idNS",
                message: "idNS is required",
            })
        }

        msg.setOk(fields.length === 0);
        fields.map(f => msg.add(f.id, f.message));
        return msg;
    }

    static async publish(request: IWAFSchemaPublishRequest): Promise<FriendlyMessage> {
        const fields: Array<IError> = [];
        const msg = new FriendlyMessage("WAFValidator.publish", true, []);

        if (isInvalidString(request.idNS)) {
            fields.push({
                id: "idNS",
                message: "idNS is required",
            })
        }

        msg.setOk(fields.length === 0);
        fields.map(f => msg.add(f.id, f.message));
        return msg;
    }

    static async delete(request: IWAFSchemaDeleteRequest): Promise<FriendlyMessage> {
        const fields: Array<IError> = [];
        const msg = new FriendlyMessage("WAFValidator.delete", true, []);

        if (isInvalidString(request.idNS)) {
            fields.push({
                id: "idNS",
                message: "idNS is required",
            })
        }

        msg.setOk(fields.length === 0);
        fields.map(f => msg.add(f.id, f.message));
        return msg;
    }

    static async deprecate(request: IWAFSchemaDeprecateRequest): Promise<FriendlyMessage> {
        const fields: Array<IError> = [];
        const msg = new FriendlyMessage("WAFValidator.deprecate", true, []);

        if (isInvalidString(request.idNS)) {
            fields.push({
                id: "idNS",
                message: "idNS is required",
            })
        }

        msg.setOk(fields.length === 0);
        fields.map(f => msg.add(f.id, f.message));
        return msg;
    }

    static async send(_request: IWAFSchemaSendMessageRequest): Promise<FriendlyMessage> {
        const fields: Array<IError> = [];
        const msg = new FriendlyMessage("WAFValidator.send", true, []);

        msg.setOk(fields.length === 0);
        fields.map(f => msg.add(f.id, f.message));
        return msg;
    }

    static async submitToMeta(request: IWAFSchemaSubmitRequest): Promise<FriendlyMessage> {
        const fields: Array<IError> = [];
        const msg = new FriendlyMessage("WAFValidator.send", true, []);

        msg.setOk(fields.length === 0);
        fields.map(f => msg.add(f.id, f.message));
        return msg;
    }

    private static ComponentTypeHandlerMap: ComponentMapperTypeFnRecord = {
        [BasicTypes.EWAFComponents.TEXT_HEADING]: WAFValidator.validateText,
        [BasicTypes.EWAFComponents.TEXT_SUBHEADING]: WAFValidator.validateText,
        [BasicTypes.EWAFComponents.TEXT_BODY]: WAFValidator.validateText,
        [BasicTypes.EWAFComponents.TEXT_CAPTION]: WAFValidator.validateText,
        [BasicTypes.EWAFComponents.TEXT_INPUT]: WAFValidator.validateInput,
        [BasicTypes.EWAFComponents.TEXT_AREA]: WAFValidator.validateTextArea,
        [BasicTypes.EWAFComponents.CHECKBOX_GROUP]: WAFValidator.validateCheckboxGroup,
        [BasicTypes.EWAFComponents.RADIO_BUTTONS_GROUP]: WAFValidator.validateRadionButtonGroup,
        [BasicTypes.EWAFComponents.OPT_IN]: WAFValidator.validateOptIn,
        [BasicTypes.EWAFComponents.DROPDOWN]: WAFValidator.validateDropdown,
        [BasicTypes.EWAFComponents.DATE_PICKER]: WAFValidator.validateDatePicker,
        [BasicTypes.EWAFComponents.IMAGE]: WAFValidator.validateImage,
        [BasicTypes.EWAFComponents.EMBEDDED_LINK]: (_) => {
            throw new Error("This component is not used in the current version");
        },
        [BasicTypes.EWAFComponents.FORM]: (_) => {
            throw new Error("This type of component shouldn't be mapped.");
        },
        [BasicTypes.EWAFComponents.FOOTER]: function(_): Errors {
            throw new Error("This type of component shouldn't be mapped.");
        }
    };

    private static validateBase(
        component: FieldTypes.IWAFOutputComponent,
        id: string,
        errors: Errors
    ) {
        if (!isInEnum(BasicTypes.EWAFComponents, component.type)) {
            const fieldId = `${id}.type`;

            errors.push({
                id: fieldId,
                message: "O tipo do componente é inválido"
            });
        }

        if (isValidRef(component.idFlowField) && isInvalidString(component.idFlowField)) {
            const fieldId = `${id}.idFlowField`;

            errors.push({
                id: fieldId,
                message: "idFlowField inválido"
            });
        }

        if (isInvalidString(component.friendlyName)) {
            const fieldId = `${id}.idFlowField`;

            errors.push({
                id: fieldId,
                message: "O identificador é obrigatório"
            });
        }
    }

    private static validateInputFieldConfig(
        configuration: IInputFieldDynamicConfiguration,
        id: string,
        errors: Errors
    ) {
        const fieldId = `${id}.configuration.label`;

        if (isInvalidString(configuration.label.default)) {
            errors.push({
                id: fieldId,
                message: "O nome do campo é obrigatório"
            });
        }
    }


    private static validateMultipleOptions(
        options: BasicTypes.IWAFMultipleOptions,
        id: string,
        errors: Errors
    ) {
        const fieldId = `${id}.configuration.options.default`;

        if (!isValidArray(options)) {
            errors.push({
                id: fieldId,
                message: "Adicione pelo menos 1 item"
            });
        }

        const idArray: string[] = [];

        for (const option of options) {
            if (!isValidString(option.id)) {
                errors.push({
                    id: fieldId,
                    message: "O ID de retorno é obrigatório"
                });
            }

            if (!isValidString(option.title)) {
                errors.push({
                    id: fieldId,
                    message: "O texto da escolha é obrigatório"
                });
            }

            // verifica se existe algum id repetido
            if (idArray.includes(option.id)) {
                errors.push({
                    id: fieldId,
                    message: "Não deve existir IDs de retorno com o mesmo valor"
                });

                break;
            }

            idArray.push(option.id);
        }
    }


    private static validateText(
        component: FieldTypes.IWAFTextConfiguration,
        id: string,
    ): Errors {
        const errors: Errors = [];
        const fieldId = `${id}.text`;

        WAFValidator.isValidDynamicField(component.configuration.text, fieldId, errors);

        if (isInvalidString(component.configuration.text?.default)) {
            errors.push({
                id: fieldId,
                message: "O texto é obrigatório"
            });
        }

        return errors;
    }

    private static validateInput(
        component: FieldTypes.IWAFTextInputConfiguration,
        id: string,
    ): Errors {
        const errors: Errors = [];
        WAFValidator.validateBase(component, id, errors);
        WAFValidator.validateInputFieldConfig(component.configuration, id, errors);

        if (!(
            isValidRef(component.configuration.minChars.default)
            && component.configuration.minChars.default >= 0
        )) {
            const fieldId = `${id}.configuration.minChars`;

            errors.push({
                id: fieldId,
                message: "Mínimo de caracteres inválido"
            });
        }

        if (!(
            isValidRef(component.configuration.maxChars.default)
            && component.configuration.maxChars.default >= 0
        )) {
            const fieldId = `${id}.configuration.maxChars`;

            errors.push({
                id: fieldId,
                message: "Máximo de caracteres inválido"
            });
        }

        return errors
    }

    private static validateTextArea(
        component: FieldTypes.IWAFTextAreaConfiguration,
        id: string,
    ): Errors {
        const errors: Errors = [];
        WAFValidator.validateBase(component, id, errors);
        WAFValidator.validateInputFieldConfig(component.configuration, id, errors);

        if (!(
            isValidRef(component.configuration.maxLength.default)
            && component.configuration.maxLength.default > 0
        )) {
            const fieldId = `${id}.configuration.maxLength`;

            errors.push({
                id: fieldId,
                message: "Máximo de caracteres inválido"
            });
        }

        return errors
    }

    private static validateCheckboxGroup(
        component: FieldTypes.IWAFCheckboxGroupConfiguration,
        id: string,
    ): Errors {
        const errors: Errors = [];
        WAFValidator.validateBase(component, id, errors);
        WAFValidator.validateInputFieldConfig(component.configuration, id, errors);
        WAFValidator.validateMultipleOptions(component.configuration.options.default, id, errors);

        if (!(
            isValidRef(component.configuration.minSelectItems.default)
            && component.configuration.minSelectItems.default >= 0
        )) {
            const fieldId = `${id}.configuration.minSelectItems`;

            errors.push({
                id: fieldId,
                message: "Limite mínimo de itens inválido"
            });
        }

        if (!(
            isValidRef(component.configuration.maxSelectItems.default)
            && component.configuration.maxSelectItems.default > 0
        )) {
            const fieldId = `${id}.configuration.maxSelectItems`;

            errors.push({
                id: fieldId,
                message: "Limite máximo de itens inválido"
            });
        }

        return errors
    }

    private static validateRadionButtonGroup(
        component: FieldTypes.IWAFRadioButtonsGroupConfiguration,
        id: string,
    ): Errors {
        const errors: Errors = [];
        WAFValidator.validateBase(component, id, errors);
        WAFValidator.validateInputFieldConfig(component.configuration, id, errors);
        WAFValidator.validateMultipleOptions(component.configuration.options.default, id, errors);
        return errors
    }

    private static validateOptIn(
        component: FieldTypes.IWAFOptInConfiguration,
        id: string,
    ): Errors {
        const errors: Errors = [];
        WAFValidator.validateBase(component, id, errors);
        WAFValidator.validateInputFieldConfig(component.configuration, id, errors);
        return errors
    }

    private static validateDropdown(
        component: FieldTypes.IWAFDropdownConfiguration,
        id: string,
    ): Errors {
        const errors: Errors = [];
        WAFValidator.validateBase(component, id, errors);
        WAFValidator.validateInputFieldConfig(component.configuration, id, errors);
        WAFValidator.validateMultipleOptions(component.configuration.options.default, id, errors);
        return errors
    }

    private static validateDatePicker(
        component: FieldTypes.IWAFDatePickerConfiguration,
        id: string,
    ): Errors {
        const errors: Errors = [];
        WAFValidator.validateBase(component, id, errors);
        WAFValidator.validateInputFieldConfig(component.configuration, id, errors);
        return errors
    }

    // DEPRECATED: NOT IMPLEMENTED
    // private static validateEmbeddedLink(
    //     component: FieldTypes.IWAFEmbeddedLinkConfiguration,
    //     id: string,
    // ): Errors {
    //     const errors: Errors = [];
    //     WAFValidator.isValidField(component.text, `${id}.embedded_link.src`, errors);
    //
    //     // if (!isValidURL(component.src)) {
    //     //     const fieldId = `${id}.embedded_link.src`;
    //
    //     //     errors.push({
    //     //         id: fieldId,
    //     //         message: `field: ${id} has a invalid URL`
    //     //     });
    //     // }
    //
    //     return errors
    // }

    private static validateImage(
        component: FieldTypes.IWAFImageConfiguration,
        id: string,
    ): Errors {
        const errors: Errors = [];

        if (WAFValidator.isValidDynamicField(component.configuration.imageSource, id, errors)) {
            const fieldId = `${id}.image.src`;

            WAFValidator.isValidDynamicField(component.configuration.width, id, errors);

            errors.push({
                id: fieldId,
                message: `field: ${id} has a invalid src`
            });
        }

        if (isInvalidString(component.configuration.imageSource.default)) {
            const fieldId = `${id}.image.configuration.imageSource`;

            errors.push({
                id: fieldId,
                message: "Selecione uma imagem"
            });
        }

        if (component.configuration.width.default < 0) {
            const fieldId = `${id}.image.configuration.width`;

            errors.push({
                id: fieldId,
                message: "A largura deve ser maior ou igual a 0"
            });
        }

        if (component.configuration.height.default < 0) {
            const fieldId = `${id}.image.configuration.height`;

            errors.push({
                id: fieldId,
                message: "A altura deve ser maior ou igual a 0"
            });
        }

        return errors
    }

    public static validateFlowEngagement(engagement: IMetaEngagement): boolean {
        const error = this.getFlowEngagementErrors(engagement);

        return isInvalid(error);
    }

    public static getFlowEngagementErrors(engagement: IMetaEngagement): IFriendlyExtraMessage | undefined {
        const errors = screenOptionToError(botTransactionErrors);

        const flowEngagement = engagement?.engagementConfig[EMetadataEngagementType.whatsAppFlow] as IWAFEngagementConfig;

        if (isInvalid(flowEngagement)) {
            return errors.invalidWppFlows;
        };

        for (const config of flowEngagement.flowConfigurations) {
            if (isInvalidString(config.idFlowSchema)) {
                return errors.invalidWppFlows;
            }
    
            if (!config.formFlowOn) {
                return undefined;
            }

            for (const area of config.flowAreas) {
                if (!isValidArray(area.fields)) {
                    return errors.invalidFlowAreaField;
                }

                if (!WAFValidator.validateFlowAreaMultipleChoiceComponents(area.fields, engagement)) {
                    return errors.invalidMultipleChoiceComponent;
                }
            }
    
            if (!this.validateMessages(config.message)) {
                return errors.invalidWppFlows;
            }
    
            if (isValidRef(config.errorMessage) && !this.validateMessages(config.errorMessage)) {
                return errors.invalidWppFlows;
            }
        }

        return undefined;
    }

    public static validateMessages(messageConfig: BasicTypes.IWAFMessageComponents): boolean {
        if (!isValidContent(messageConfig.body)) {
            return false;
        }

        if (messageConfig.header) {
            if (!isInEnum(WhatsApp.Message.Template.Structure.Format, messageConfig.header.format)) {
                return false;
            }
            if (!isValidContent(messageConfig.header.content)) {
                return false;
            }
        }

        if (messageConfig.footer) {
            if (!isValidContent(messageConfig.footer.content)) {
                return false;
            }
        }

        if (!isValidContent(messageConfig.cta)) {
            return false;
        }

        return true;
    }

    public static validateFlowAreaMultipleChoiceComponents(fields: BasicTypes.TIFlowFieldArray, engagement: IMetaEngagement): boolean {
        for (let field of fields) {
            if (WAFUtils.isMultipleChoiceComponent(field.metadata.type)) {
                const idFieldForm = field.idField;

                let dynamicMenu = getSmartFieldOfType<IFieldSmartCorporateSearch>(
                    idFieldForm,
                    engagement,
                    ESmartField.DynamicMenu
                );

                let richFormEngagement = getEngangementOfTypeForIDField<IRichFormExperience>(
                    idFieldForm,
                    engagement,
                    EMetadataEngagementType.richFillForm
                );

                if (
                    !(
                        isValidRef(dynamicMenu) ||
                        (
                            isValidRef(richFormEngagement) &&
                            richFormEngagement.hasConfigMultipleChoice &&
                            richFormEngagement.multipleChoice.length > 0
                        )
                    )
                ) {
                    return false;
                }
            }
        }

        return true;
    }
}
